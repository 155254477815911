import React, { useState, useContext, useEffect, lazy, Suspense } from 'react';
import { Content, Top, Scrollable, Header } from 'components';
import { Tab } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { AuthDataContext } from 'contexts';

const CreateReport = lazy(() => import('pages/Analytics/CreateReport/CreateReport'));
const Scheduled = lazy(() => import('pages/Analytics/Scheduled/Scheduled'));
const LostSales = lazy(() => import('pages/Analytics/LostSales/LostSales'));

const Analytics = () => {
  const { routeTab } = useParams();
  const routeTabMapping = {
    lostsales: '1',
    createreport: '2',
    scheduled: '3',
  };
  const [tab, setTab] = useState(routeTabMapping[routeTab] ?? routeTabMapping.lostsales);
  const history = useHistory();
  const location = useLocation();
  const { account } = useContext(AuthDataContext);
  const [subtitle, setSubtitle] = useState('');

  const handleTabChange = (newValue) => {
    setTab(newValue);
    const mappings = Object.entries(routeTabMapping);
    for (let i = 0; i < mappings.length; i += 1) {
      const [key, value] = mappings[i];
      if (value === newValue) {
        const newPath = `/analytics/${key}`;
        if (newPath !== location.pathname) {
          history.push(newPath);
        }
      }
    }
  };

  const GET_TIERS_FOR_ACCOUNT_QUERY = gql`
    query GetTiersForAccount($accountId: Int) {
      getTiersForAccount(accountId: $accountId) {
        id
        tier
        description
        slots
        asinCount
      }
    }
  `;

  const { data: tiersData } = useQuery(GET_TIERS_FOR_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { accountId: account.id },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    const routePart = location.pathname.split('/').pop();
    const mapTab = routeTabMapping[routePart];
    if (mapTab !== undefined && mapTab !== tab) {
      handleTabChange(routeTabMapping[routePart]);
    }
  }, [location]);

  useEffect(() => {
    if (tiersData) {
      const hasLegacy = tiersData.getTiersForAccount.some((a) => a.tier === 'legacy');
      const hasTiers = tiersData.getTiersForAccount.some((a) => a.tier === 'hourly');
      if (hasLegacy && hasTiers) {
        setSubtitle('Premium & Standard Tiers');
      } else if (hasLegacy && !hasTiers) {
        setSubtitle('');
      } else if (!hasLegacy && hasTiers) {
        setSubtitle('Critial Tier');
      } else {
        setSubtitle('');
      }
    }
  }, [tiersData]);

  return (
    <Content>
      <TabContext value={tab}>
        <Top>
          <Header title="Analytics" subtitle={subtitle}>
            <TabList color="primary" onChange={(_event, value) => handleTabChange(value)}>
              <Tab
                label="Estimated Lost Sales"
                value={routeTabMapping.lostsales}
                data-cy="estimated_lost_sales_tab"
              />
              <Tab
                label="Create Report"
                value={routeTabMapping.createreport}
                data-cy="create_report_tab"
              />
              <Tab
                label="Scheduled Reports"
                value={routeTabMapping.scheduled}
                data-cy="schedule_reports_tab"
              />
            </TabList>
          </Header>
        </Top>
        <Scrollable>
          <Suspense fallback={<div />}>
            <TabPanel sx={{ m: 0, p: 0, height: '100%' }} value={routeTabMapping.createreport}>
              <CreateReport />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.scheduled}>
              <Scheduled />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.lostsales}>
              <LostSales />
            </TabPanel>
          </Suspense>
        </Scrollable>
      </TabContext>
    </Content>
  );
};

export default Analytics;
