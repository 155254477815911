import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function Chip({ label, badge, backgroundColor, color, margin, sx }) {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.borderRadius,
        display: 'inline-flex',
        p: '2px 8px',
        alignItems: 'center',
        backgroundColor,
        color,
        margin,
        ...sx,
      }}
    >
      <Box>{label}</Box>
      {badge && (
        <Box
          sx={{
            margin: '0px 0px 0px 4px',
            color: 'white',
            height: '16px',
            minWidth: '16px',
            borderRadius: '12px',
            justifyContent: 'center',
            display: 'flex',
            fontWeight: '600',
            fontSize: '11.5px',
            bgcolor: color,
          }}
        >
          <Typography>{badge}</Typography>
        </Box>
      )}
    </Box>
  );
}

Chip.defaultProps = {
  margin: null,
  badge: null,
  sx: null,
};

Chip.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  margin: PropTypes.string,
  label: PropTypes.any.isRequired,
  badge: PropTypes.number,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default Chip;
