import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Box,
  Grid,
  Checkbox,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import { CountryDropdown, RegionDropdown } from 'components';
import ClearIcon from '@mui/icons-material/CancelRounded';
import { ToastDataContext } from 'contexts';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

const AddressEditorForm = ({
  address,
  onSaving,
  editMode,
  onDone,
  mailingMode,
  returnMode,
  fieldMode,
  setFieldAddress,
}) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('choose');
  const { setToast } = useContext(ToastDataContext);
  const [addressTypes, setAddressTypes] = useState([]);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [addressTypeId, setAddressTypeId] = useState('');
  const [addMultipleTypes, setAddMultipleTypes] = useState([]);

  const theme = useTheme();

  const GET_ADDRESSES_QUERY = gql`
    query GetAddresses {
      getAddresses {
        id
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
      }
    }
  `;

  useQuery(GET_ADDRESSES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !returnMode,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: (d) => {
      const filtered = d.getAddresses?.filter(
        (a) => a.addressTypeId === 1 || a.addressTypeId === 3,
      );
      setAddresses(filtered);
      for (let i = 0; i < filtered.length; i += 1) {
        const a = filtered[i];
        if (a.id === address.id) {
          setSelectedAddress(a);
          break;
        }
      }
    },
  });

  const GET_ADDRESS_TYPES_QUERY = gql`
    query GetAddressTypes {
      getAddressTypes {
        id
        name
      }
    }
  `;

  const { data } = useQuery(GET_ADDRESS_TYPES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (data && !mailingMode) {
      setAddressTypes(cloneDeep(data.getAddressTypes).filter((t) => t.id !== 3));
    }
  }, [data]);

  const UPSERT_ADDRESS_MUTATION = gql`
    mutation UpsertAddress($address: AddressInput!) {
      upsertAddress(address: $address) {
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
      }
    }
  `;

  const [upsertAddressMutation] = useMutation(UPSERT_ADDRESS_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      if (onDone) {
        onDone();
      }
    },
  });

  useEffect(() => {
    setDescription(address?.description || '');
    setName(address?.name || '');
    setAddressLine1(address?.addressLine1 || '');
    setAddressLine2(address?.addressLine2 || '');
    setCity(address?.city || '');
    setRegion(address?.state || '');
    setZip(address?.zip || '');
    setCountry(address?.country || 'US');
    setAddressTypeId(address?.addressTypeId || '');
  }, []);

  const saveAddress = (e) => {
    e.preventDefault();
    if (!mailingMode) {
      if (onSaving) {
        onSaving();
      }
      upsertAddressMutation({
        variables: {
          address: {
            description,
            name,
            addressLine1,
            addressLine2,
            city,
            state: region,
            zip,
            country,
            addressTypeId: editMode
              ? [].concat(parseInt(addressTypeId, 10))
              : addMultipleTypes.map((a) => parseInt(a, 10)),
            id: address?.id,
          },
        },
        onCompleted: () => {
          if (onDone) {
            onDone();
          }
        },
      });
    } else if (onDone) {
      onDone({
        name,
        addressLine1,
        addressLine2,
        city,
        state: region,
        zip,
        country,
        id: selectedAddress !== 'choose' ? selectedAddress.id : address.id,
      });
    }
  };

  // Handle changes in field mode
  useEffect(() => {
    if (fieldMode && setFieldAddress) {
      const addr = {
        addressLine1,
        addressLine2,
        city,
        state: region,
        zip,
        country,
      };
      if (addressLine1?.length === 0 && addressLine2?.length === 0 && city?.length === 0 && region?.length === 0 && zip?.length === 0) {
        setFieldAddress(null);
      } else {
        setFieldAddress(addr);
      }
    }
  }, [addressLine1, addressLine2, city, region, zip, country]);

  const handleAddressChange = (event) => {
    const a = event.target.value;
    setSelectedAddress(a);
    setDescription(a?.description || '');
    setName(a?.name || '');
    setAddressLine1(a?.addressLine1 || '');
    setAddressLine2(a?.addressLine2 || '');
    setCity(a?.city || '');
    setRegion(a?.state || '');
    setZip(a?.zip || '');
    setCountry(a?.country || 'US');
    setAddressTypeId(a?.addressTypeId || '');
  };

  const clearAddress = () => {
    setDescription('');
    setName('');
    setAddressLine1('');
    setAddressLine2('');
    setCity('');
    setRegion('');
    setZip('');
    setCountry('');
    setAddressTypeId('');
  };

  const MainForm = (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: fieldMode ? 1 : 2 }}>
        {returnMode && (
          <Select value={selectedAddress} onChange={handleAddressChange} sx={{ minHeight: '53px' }}>
            <MenuItem value={'choose'} disabled selected hidden>
              <Box sx={{ color: 'greys.grey' }}>Choose Preset Address</Box>
            </MenuItem>
            {addresses.map((a) => (
              <MenuItem key={a.id} value={a}>
                <Grid container>
                  <Grid container item xs={11}>
                    <Grid item xs={12}>
                      <Box sx={{ color: 'greys.black', fontWeight: 'bold' }}>
                        {a.description ?? 'Account'}
                      </Box>
                      <Box sx={{ color: 'greys.grey' }}>
                        {a.name ?? ''}
                        {a.name ? ', ' : ''} {a.addressLine1}
                        {a.addressLine2 && ', '}
                        {a.addressLine2 ?? ''},&nbsp;
                        {a.city}, {a.state} {a.zip}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        )}
        {!mailingMode && !fieldMode && (
          <TextField
            id="address-description"
            placeholder="Description"
            type="text"
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        )}
        {!fieldMode && (
          <TextField
            id="address-recipient-name"
            placeholder="Recipient Name"
            type="text"
            required={!fieldMode}
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        )}
        <TextField
          id="user-profile-address1"
          placeholder="Address Line 1"
          type="text"
          required={!fieldMode}
          value={addressLine1 || ''}
          onChange={(e) => setAddressLine1(e.target.value)}
          fullWidth
        />
        <TextField
          id="user-profile-address2"
          placeholder="Address Line 2"
          type="text"
          value={addressLine2 || ''}
          onChange={(e) => setAddressLine2(e.target.value)}
          fullWidth
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            id="user-profile-city"
            placeholder="City/Town"
            required={!fieldMode}
            type="text"
            value={city || ''}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
          />
          <RegionDropdown
            country={country}
            sx={{ width: '200px' }}
            onChange={setRegion}
            value={region}
          />
          <TextField
            sx={{ minWidth: '140px' }}
            required={!fieldMode}
            id="user-profile-zip"
            placeholder="Zip/Postal Code"
            type="text"
            value={zip || ''}
            onChange={(e) => setZip(e.target.value)}
          />
          {!mailingMode && (
            <CountryDropdown sx={{ width: '200px' }} onChange={setCountry} value={country} />
          )}
        </Box>
        {!mailingMode && !editMode && addressTypes.length > 0 && (
          <Box>
            <Select
              data-cy="address_select_shipping"
              labelId="address-type-label"
              id="address-type"
              value={addMultipleTypes}
              required
              displayEmpty
              multiple
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography sx={{ color: theme.palette.greys.grey }}>
                      Select Address Type
                    </Typography>
                  );
                }

                return addressTypes
                  .filter((a) => selected.includes(a.id))
                  .map((i) => i.name)
                  .join(', ');
              }}
              onChange={(e) => {
                const { value } = e.target;
                setAddMultipleTypes(
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value,
                );
              }}
            >
              {addressTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  <Checkbox checked={addMultipleTypes.indexOf(type.id) >= 0} />
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {!mailingMode && !fieldMode && editMode && addressTypes.length > 0 && (
          <Box>
            <Select
              data-cy="address_select_shipping"
              labelId="address-type-label"
              id="address-type"
              value={addressTypeId || ''}
              required
              onChange={(e) => {
                setAddressTypeId(e.target.value);
              }}
            >
              <MenuItem disabled hidden key={''} value={''}>
                Select Address Type
              </MenuItem>
              {addressTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {!mailingMode && !fieldMode && editMode && addressTypes.length === 0 && (
          <Box sx={{ height: '40px' }} />
        )}
      </Box>
      {fieldMode && (
        <Box sx={{ width: '44px' }}>
          <IconButton onClick={clearAddress} size="small">
            <ClearIcon style={{ fill: 'greys.silver' }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  if (fieldMode) {
    return MainForm;
  }

  return (
    <form id="addresses-form" onSubmit={saveAddress}>
      {MainForm}
    </form>
  )
};

export default AddressEditorForm;
