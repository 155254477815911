import React, { useContext } from 'react';
import { AuthDataContext } from 'contexts';
import { Box } from '@mui/material';
import getCountryISO2 from 'country-iso-3-to-2';

const AmazonRegionIcon = ({ size }) => {
  const iconSize = size ?? 16;
  const { region } = useContext(AuthDataContext);

  return (
    <Box mx={1}>
      <img
        alt={`${region} flag`}
        src={`https://flagsapi.com/${getCountryISO2(region)}/flat/${iconSize}.png`}
      />
    </Box>
  );
};

export default AmazonRegionIcon;
