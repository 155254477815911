import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Content({ children, transparent }) {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.largeBorderRadius,
        margin: (theme) => `0px 0px 0px ${theme.spacing(1)}`,
        height: (theme) => `calc(100vh - ${theme.spacing(2)})`,
        width: '100%',
        bgcolor: transparent ? null : 'greys.white',
      }}
    >
      <Box sx={{ height: '100%' }}>
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

Content.propTypes = {
  transparent: PropTypes.bool,
};

Content.defaultProps = {
  transparent: false,
};

export default Content;
