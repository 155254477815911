import React from 'react';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import { Button, Grid, Typography } from '@mui/material';

const SuccessDialog = ({ onClose, open }) => (
  <Grid container alignContent="center" justifyContent="center" alignItems="center">
    <CustomDialog
      open={open}
      onClose={onClose}
      title={'Order placed successfully!'}
      subtitle={
        <Grid container justifyContent="center" alignItems="center" spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Typography>Congratulations! Your order has been placed.</Typography>
            <Typography>You&apos;ll be able to track your order once it has shipped.</Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => onClose(true)}>OK</Button>
          </Grid>
        </Grid>
      }
    />
  </Grid>
);

export default SuccessDialog;
