import React, { useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Grid, LinearProgress, Typography, Button, Box, useTheme } from '@mui/material';
import { Content, Top, Scrollable, Header } from 'components';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import EmptyState from 'pages/Common/EmptyState';
import SellersMap from './SellersMap/SellersMap';
import OffersRatio from './OffersRatio';
import CustomerRiskWidget from './CustomerRiskWidget';
import SellerHealthIndexWidget from './SellerHealthIndexWidget';
import Widget from './Widget';
import BuyBoxWinRateChart from './BuyBoxWinRateChart';
import Insights from './Insights/Insights';
import AuthWinRateWidget from './AuthWinRateWidget';
import OffersSellersAnalyzedWidget from './OffersSellersAnalyzedWidget';
import LostSalesWidget from './LostSalesWidget';

const Dashboard = () => {
  const { user, hasAbility } = useContext(AuthDataContext);
  const [harvesting, setHarvesting] = useState(false);
  const [asinCount, setAsinCount] = useState(null);
  const [asinHarvestedCount, setAsinHarvestedCount] = useState(null);
  const { setToast } = useContext(ToastDataContext);

  const theme = useTheme();

  const ASIN_COUNT_QUERY = gql`
    query GetAsinCountForAccount {
      getAsinCountForAccount
      getAsinHarvestedCountForAccount
    }
  `;
  useQuery(ASIN_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    onCompleted: (data) => {
      if (data) {
        setAsinCount(data.getAsinCountForAccount || 0);
        setAsinHarvestedCount(data.getAsinHarvestedCountForAccount || 0);
      }
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const HARVESING_QUERY = gql`
    query GetHarvesting {
      getHarvestingAsins
    }
  `;
  useQuery(HARVESING_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    onCompleted: (data) => {
      if (data) {
        setHarvesting(data.getHarvestingAsins);
      }
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  return (
    <Content transparent>
      <Top>
        <Header
          title={user?.profile?.firstName ? `Hello ${user?.profile?.firstName}!` : 'Hello!'}
          showCredits
        />
      </Top>
      {asinCount === 0 && (
        <EmptyState
          content={
            <Typography variant="h4">
              Nothing&apos;s here!
              <br />
              {hasAbility('asinManagement') && 'Add some ASINs!'}
            </Typography>
          }
          button={
            <Box>
              {hasAbility('asinManagement') && (
                <NavLink to="/asins">
                  <Button startIcon={<AddIcon fill={theme.palette.greys.white} data-cy="go_asin_manager_button" />}>
                    Go To Asin Manager
                  </Button>
                </NavLink>
              )}
            </Box>
          }
        />
      )}
      <Scrollable transparent>
        <Box sx={{ mx: 1, mt: 1.5, mb: 1 }}>
          {harvesting && (
            <Grid>
              <Widget>
                <Box sx={{ p: 2, display: 'flex', fontSize: '13px' }}>
                  Retrieving newly added product information...
                  <LinearProgress sx={{ ml: '20px', mt: '8px', width: '100px' }} aria-busy />
                </Box>
              </Widget>
            </Grid>
          )}
          {asinCount > 0 && (
            <>
              {asinCount !== null && (
                <Grid container direction="column" spacing={0}>
                  <Grid container item direction="row">
                    <Grid container item xs={12}>
                      <Grid container item xs={7} sx={{ height: 'fit-content', width: '100%' }}>
                        <Grid
                          item
                          xs={6}
                          sx={{ width: '100%' }}
                          data-cy="dash_sellers_health_index"
                        >
                          <SellerHealthIndexWidget asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item xs={6} sx={{ width: '100%' }} data-cy="dash_customer_risk">
                          <CustomerRiskWidget asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }} data-cy="dash_insights">
                          <Insights asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item xs={6} sx={{ width: '100%' }} data-cy="dash_bbwr">
                          <AuthWinRateWidget asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item xs={6} sx={{ width: '100%' }} data-cy="dash_offers_sellers">
                          <OffersSellersAnalyzedWidget asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }} data-cy="dash_win_rate_history">
                          <BuyBoxWinRateChart asinCount={asinHarvestedCount} />
                        </Grid>
                      </Grid>
                      <Grid container item direction="column" xs={5} sx={{ height: 'fit-content' }}>
                        <Grid item sx={{ width: '100%' }} data-cy="dash_lost_sales">
                          <LostSalesWidget asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item sx={{ width: '100%' }} data-cy="dash_offer_ratios">
                          <OffersRatio asinCount={asinHarvestedCount} />
                        </Grid>
                        <Grid item sx={{ width: '100%' }} data-cy="dash_seller_map">
                          <SellersMap asinCount={asinHarvestedCount} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
      </Scrollable>
    </Content>
  );
};

export default Dashboard;
