import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BuyBoxWinnerIcon } from 'assets/buy-box-winner.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as EnforcementLicenseViolationIcon } from 'assets/enforcement-license-violation.svg';
import { ReactComponent as PolicyViolationIcon } from 'assets/enforcement-policy-violation.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import { ReactComponent as TestBuyIcon } from 'assets/shopping-cart.svg';
import { formatDate } from 'utils/dates';
import { Chip } from 'components';
import { Tooltip, Box, useTheme, Typography } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { isAmazonSeller } from 'utils/misc';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';

const OfferIcons = ({ offer, hideAuthorized }) => {
  const [fullOffer, setFullOffer] = useState(offer);
  const [globalAuth, setGlobalAuth] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const theme = useTheme();

  const GET_OFFERS_FOR_ASIN_QUERY = gql`
    query GetOffersForAsin($asin: String!, $sellerId: String!) {
      getOffersForAsin(asin: $asin) {
        id
        isBuyBoxWinner
        fulfilledByAmazon
        offerId
        sellerId
        actionHistory {
          actionTaken
          description
          createdDate
        }
      }
      getSellerGlobalAuth(sellerId: $sellerId) {
        authorized
        globalAuth
        uniqueId
      }
    }
  `;

  const { data, refetch } = useQuery(GET_OFFERS_FOR_ASIN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      console.log(e);
    },
    skip: !offer,
    variables: {
      asin: offer.asin,
      sellerId: offer.sellerId,
    },
  });

  const loadOffer = (offerData) => {
    try {
      const filteredResult = offerData.filter((r) => r.sellerId === offer.sellerId);
      const newOffer = { ...fullOffer, ...filteredResult[0] };
      if (filteredResult[0] && filteredResult[0].actionHistory) {
        newOffer.actionHistory = filteredResult[0].actionHistory;
      }
      setFullOffer(newOffer);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data) {
      loadOffer(data.getOffersForAsin);
      setAuthorized(data.getSellerGlobalAuth.authorized);
      setGlobalAuth(data.getSellerGlobalAuth.globalAuth);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [offer]);

  const isAmazon = isAmazonSeller({ sellerId: fullOffer.sellerId, sellerName: fullOffer.sellerName });

  const getActionIcon = (description) => {
    if (description.includes('IP')) {
      return (
        <EnforcementLicenseViolationIcon
          fill={theme.palette.chips.red.color}
          style={{ marginLeft: '2px' }}
        />
      );
    }
    if (description.includes('Seller')) {
      return (
        <PolicyViolationIcon fill={theme.palette.chips.red.color} style={{ marginLeft: '2px' }} />
      );
    }
    if (description.includes('Email')) {
      return <MailIcon fill={theme.palette.chips.red.color} style={{ marginLeft: '2px' }} />;
    }
    if (description.includes('Buy')) {
      return <TestBuyIcon fill={theme.palette.chips.red.color} style={{ marginLeft: '2px' }} />;
    }

    return <SendLetterIcon fill={theme.palette.chips.red.color} style={{ marginLeft: '2px' }} />;
  };

  const getActionDescription = (description, date) => {
    const formattedDate = formatDate(date);
    if (description.includes('Letter')) {
      return `${formattedDate} Letter`;
    }
    if (description.includes('Email')) {
      return `${formattedDate} Email`;
    }
    return `${formattedDate} ${description}`;
  };

  const getMultipleActionDescription = (descriptions) => descriptions.map((a) => (
    <Typography key={a.createdDate} sx={{ fontSize: '12px' }}>{getActionDescription(a.description, a.createdDate)}</Typography>
  ));

  return (
    <>
      {!hideAuthorized && (
        <SellerAuthorizationState globalAuth={globalAuth} partialAuth={authorized} />
      )}
      {fullOffer.isBuyBoxWinner && (
        <Tooltip title="Buy Box Winner" arrow placement="bottom" sx={{ cursor: 'default' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <BuyBoxWinnerIcon style={{ paddingLeft: '2px' }} />
          </Box>
        </Tooltip>
      )}
      {!fullOffer.parent && !isAmazon && fullOffer.fulfilledByAmazon && (
        <Chip
          sx={{ ml: '5px' }}
          label={
            <Tooltip
              title="2nd Party - Fulfilled By Amazon"
              arrow
              placement="bottom"
              sx={{ cursor: 'default' }}
            >
              <Box sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}>FBA</Box>
            </Tooltip>
          }
          backgroundColor="#FF990020"
          color="#FF9900"
        />
      )}
      {!fullOffer.parent && !isAmazon && !fullOffer.fulfilledByAmazon && (
        <Chip
          sx={{ ml: '5px' }}
          label={
            <Tooltip
              title="3rd Party - Merchant Fulfilled Network"
              arrow
              placement="bottom"
              sx={{ cursor: 'default', fontFamily: theme.typography.fontFamily }}
            >
              <Box sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}>FBM</Box>
            </Tooltip>
          }
          backgroundColor={theme.palette.chips.purple.backgroundColor}
          color={theme.palette.chips.purple.color}
        />
      )}
      {!fullOffer.parent && fullOffer.actionHistory && fullOffer.actionHistory.length > 1 && (
        <Chip
          sx={{ ml: '5px' }}
          label={
            <Tooltip
              title={getMultipleActionDescription(fullOffer.actionHistory)}
              arrow
              placement="bottom"
              sx={{ cursor: 'default', fontFamily: theme.typography.fontFamily }}
            >
              <Box sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}>
                {fullOffer.actionHistory.length}
              </Box>
            </Tooltip>
          }
          backgroundColor={theme.palette.chips.red.backgroundColor}
          color={theme.palette.chips.red.color}
        />
      )}
      {!fullOffer.parent && fullOffer.actionHistory && fullOffer.actionHistory.length === 1 && (
        <Tooltip
          title={getActionDescription(
            fullOffer.actionHistory[0].description,
            fullOffer.actionHistory[0].createdDate,
          )}
          arrow
          placement="bottom"
          sx={{ cursor: 'default' }}
        >
          <Box
            sx={{
              display: 'flex',
              fontSize: '12px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {getActionIcon(fullOffer.actionHistory[0].description)}
          </Box>
        </Tooltip>
      )}
    </>
  );
};

OfferIcons.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hideAuthorized: PropTypes.bool
};

OfferIcons.defaultProps = {
  hideAuthorized: false
}

export default OfferIcons;
