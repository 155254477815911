import React, { useState } from 'react';
import { LoadingButton } from 'components';
import { Box, useTheme } from '@mui/material';
import UpdateTenantForm from './UpdateTenantForm';

const TenantProfile = ({ tenant, onUpdated }) => {

  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const theme = useTheme();

  return (
    <>
      <UpdateTenantForm
        tenant={tenant}
        formId="update-tenant"
        onSaving={(val) => setLoading(val)}
        onDone={() => {
          setLoading(false);
          setSaved(true);
          setUnsavedChanges(false);
          onUpdated();
        }}
        onChangesMade={() => {
          setUnsavedChanges(true);
          setSaved(false);
        }}
      />
      <Box
        sx={{
          mt: 6,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LoadingButton
            form="update-tenant"
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            data-cy="profile_update"
          >
            Save Tenant Profile
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            form="update-tenant"
            disabled={loading}
            type="reset"
            onClick={() => {
              setSaved(false);
              setUnsavedChanges(false);
            }}
          >
            Reset
          </LoadingButton>
        </Box>
        {saved && <Box sx={{mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.green}}>Client Profile Saved</Box>}
        {unsavedChanges && <Box sx={{mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.red}}>Unsaved Changes</Box>}
      </Box>
    </>
  );
};

export default TenantProfile;
