import { graphQLFetch } from './fetch-service';

const acceptInvite = async ({ email, oldPassword, newPassword }) => {
  const query = `mutation {
    acceptInviteUser(email:${JSON.stringify(email)},oldPassword:${JSON.stringify(
    oldPassword,
  )},newPassword:${JSON.stringify(newPassword)})
  }`;
  return graphQLFetch({ query });
};

export default { acceptInvite };
