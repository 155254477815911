import React from 'react';
import { ProductName } from 'components';
import { TableRow, TableCell, Box, Checkbox } from '@mui/material';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { formatDate } from 'utils/dates';

const HistoricalAsinRow = ({
  asin,
  onCheckboxClicked,
  selected,
}) => (
  <TableRow>
    <TableCell sx={{ pr: 0, pl: '12px', minWidth: '20px' }}>
      <Checkbox
        onClick={(event) => onCheckboxClicked(event, asin)}
        data-cy="asin_checkbox"
        checked={selected}
      />
    </TableCell>
    <TableCell align="left">
      <Box>{asin.id}</Box>
    </TableCell>
    <TableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            height: '40px',
            width: '40px',
            minHeight: '40px',
            minWidth: '40px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {asin.image && (
            <Box
              component="img"
              src={`${asin.image}`}
              alt="product"
              sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
            />
          )}
          {!asin.image && (
            <BlankImage
              style={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
            />
          )}
        </Box>
        <ProductName
          asin={asin.id}
          productName={asin.name}
          sx={{
            display: 'block',
            fontWeight: '500',
            fontSize: '14px',
            color: '#0071DA',
            textDecoration: 'underline',
            lineHeight: '18px',
            maxHeight: '36px',
            overflow: 'hidden',
          }}
        />
      </Box>
    </TableCell>
    <TableCell align="left">{<Box>{formatDate(asin.harvestDate)}</Box>}</TableCell>
    <TableCell align="right">{<Box>{asin.isActive ? 'Yes' : ''}</Box>}</TableCell>
  </TableRow>
);

export default HistoricalAsinRow;
