import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, useTheme } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { Loading } from 'components';
import { useQuery, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import EnforcementActionsList from 'pages/Common/EnforcementActionsList';

const ActionContent = ({ sellerId }) => {
  const scrollPageLength = 3;
  const [limit, setLimit] = useState(scrollPageLength);
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const theme = useTheme();

  const GET_ACTIONS_QUERY = gql`
    query GetSellerDecidedActions($sellerId: String!) {
      getActions {
        id
        description
      }
      getDecidedEnforcementItemsForSeller(sellerId: $sellerId) {
        id
        createdDate
        offers {
          id
          sellerId
          asin
          imageUrl
          offerId
          sellerName
          sellerAuthorized
          name
          regionId
          category {
            name
          }
          currentScore {
            score
            harvestDate
          }
        }
        actions {
          id
          letterType
          actionTaken
          status
          statusExtra
          tracking
          description
          createdDate
          updatedDate
          userName
          userEmail
          fields {
            id
            idString
            customFieldId
            customValueId
            name
            description
            type
            area
            stringOptions
            valueInt
            valueString
            valueDate
            valueStrings
            valueAddress {
              addressLine1
              addressLine2
              city
              state
              zip
            }
            multiple
            timestamp
            extraTextLabel
            extraText
            userEmail
            userFirstName
            userLastName
            updatedDate
            createdDate
            s3filename
          }
        }
      }
    }
  `;
  const { data, refetch } = useQuery(GET_ACTIONS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      sellerId
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processData = (dataToProcess) => {
    setActionTypes(dataToProcess.getActions);
    let allActions = [];
    for (let i = 0; i < dataToProcess.getDecidedEnforcementItemsForSeller?.length; i += 1) {
      const enforcement = dataToProcess.getDecidedEnforcementItemsForSeller[i];
      const modifiedActions = enforcement.actions.map(a => ({ ...a, enforcement }));
      allActions = allActions.concat(modifiedActions);
    }
    setActions(allActions);
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const loadMore = () => {
    if (limit < actions.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > actions.length) {
        newLimit = actions.length;
      }
      setLimit(newLimit);
    }
  };

  return (
    <Box
      sx={{ overflow: 'scroll' }}
    >
      {loading && (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      )}
      {!loading && (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore
          loader={limit < actions.length && <Loading key={0} />}
          useWindow={false}
          style={{ width: '100%' }}
        >
          {actions.length > 0 && (
            <EnforcementActionsList actions={actions.slice(0, limit)} actionTypes={actionTypes} showOffers onRemoved={() => refetch()} onUpdated={() => refetch()} />
          )}
          {actions.length === 0 && (
            <Box sx={{ mt: 2, fontFamily: theme.typography.fontFamily, fontSize: '14px', color: 'greys.grey' }}>No actions have been taken against this seller.</Box>
          )}
        </InfiniteScroll>
      )}
    </Box>
  );
};

ActionContent.propTypes = {
  sellerId: PropTypes.string.isRequired,
};

export default ActionContent;
