const currencyAtEnd = (r) => {
  if (
    r === 'FRA' ||
    r === 'DEU' ||
    r === 'BEL' ||
    r === 'ITA' ||
    r === 'POL' ||
    r === 'ESP' ||
    r === 'SWE' ||
    r === 'TUR'
  ) {
    return true;
  }
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export { currencyAtEnd };
