import React from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';

function WidgetHeader({ title, showLine, right, bottom, sx, center, tip = null }) {
  const theme = useTheme();
  return (
    <Box sx={{ minHeight: '40px' }}>
      <Box
        sx={[
          {
            py: 1.5,
            px: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          showLine && {
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          },
          {
            ...sx,
          },
        ]}
      >
        <Box
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'greys.white',
            },
            center && {
              justifyContent: 'center',
            },
            !center && {
              justifyContent: 'space-between',
            },
          ]}
        >
          <Box>
            <Box
              sx={{
                fontSize: '13px',
                fontWeight: '700',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
                color: 'greys.black',
                textAlign: 'center',
              }}
            >
              <Tooltip title={tip}>
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Box>{title}</Box>
                  {tip && (
                    <InfoIcon sx={{ ml: '4px', width: '13px', height: '13px' }} />
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
          {!center && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {right && <>{right}</>}
            </Box>
          )}
        </Box>
        {bottom && <Box sx={{ mt: 1, bgcolor: 'greys.white' }}>{bottom}</Box>}
      </Box>
    </Box>
  );
}

WidgetHeader.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.oneOfType([PropTypes.object]),
  bottom: PropTypes.oneOfType([PropTypes.object]),
  showLine: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object]),
  center: PropTypes.bool,
};

WidgetHeader.defaultProps = {
  showLine: false,
  sx: null,
  center: false,
  right: null,
  bottom: null,
};

export default WidgetHeader;
