import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Tooltip } from '@mui/material';
import { RegionDataContext } from 'contexts';

const ProductName = ({ asin, productName, sx, onClick }) => {
  const length = 9999;
  const { regionUrl } = useContext(RegionDataContext);

  const truncatedName = (name) =>
    name?.length > length ? `${name?.substr(0, length - 1)}...` : name;

  return (
    <Tooltip
      title={
        <Box>
          {productName}
          {!productName && 'No longer listed on Amazon'}
          {asin && (
            <>
              <br />
              <br />
              ASIN: {asin}
            </>
          )}
        </Box>
      }
      arrow
      placement="bottom"
      enterDelay={500}
      enterNextDelay={500}
      sx={{ cursor: 'default' }}
    >
      <Box>
        <Link
          sx={sx}
          href={`${regionUrl}/dp/${asin}`}
          target="_blank"
          onClick={onClick}
          underline="hover"
          data-cy="asin_product_name"
        >
          {!productName && `${asin} (Unlisted)`}
          {truncatedName(productName)}
        </Link>
      </Box>
    </Tooltip>
  );
};

ProductName.defaultProps = {
  asin: null,
  productName: null,
  sx: undefined,
  onClick: undefined,
};

ProductName.propTypes = {
  asin: PropTypes.string,
  productName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sx: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};

export default ProductName;
