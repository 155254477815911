import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Box, FormHelperText, Typography } from '@mui/material';
import { allCountries } from 'country-region-data';

const CountryDropdown = ({ value, onChange, sx, label, required, helperText, disabled = false }) => {

  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (value) {
      const foundValues = allCountries.filter(c => c[1].toUpperCase() === value?.toUpperCase() || c[0].toUpperCase() === value?.toUpperCase());
      if (foundValues && foundValues.length > 0) {
        setCountry(foundValues[0]);
      } 
    } else {
      const foundValues = allCountries.filter(c => c[1].toUpperCase() === 'US');
      if (foundValues && foundValues.length > 0) {
        setCountry(foundValues[0]);
        if (onChange) {
          onChange(foundValues[0][1]);
        }
      } 
    }
  }, [value]);

  const handleChange = (e) => {
    setCountry(e.target.value);
    if (onChange) {
      onChange(e.target.value[0]);
    }
  }

  return (
    <Box>
      {label && (
        <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: '8px', lineHeight: '16px', opacity: disabled ? 0.42 : 1.0 }}>{label}{required ? ' *' : ''}</Typography>
      )}
      <Select
        disabled={disabled}
        sx={sx}
        data-cy="country_dropdown"
        onChange={handleChange}
        value={country ?? ''}
        renderValue={(selected) => selected[0]}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: 'rgba(0,0,0,0.05)'
            }
          }
        }}
      >
        {allCountries.map(c => (
          <MenuItem key={c[1]} value={c} data-cy={`country_dropdown_${c[1].toLowerCase()}`}>
            {c[0]}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </Box>
  );
}

export default CountryDropdown;
