import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const CustomDialogContent = ({ children, sx, bottom, ...rest }) => (
  <Box
    sx={{
      ...sx,
      padding: (theme) => theme.customDialogPadding,
      fontFamily: (theme) => theme.typography.fontFamily,
      pb: bottom ? 0 : null,
    }}
    {...rest}
  >
    {children}
  </Box>
);

CustomDialogContent.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  bottom: PropTypes.bool,
};

CustomDialogContent.defaultProps = {
  bottom: false,
  sx: null,
};

export default CustomDialogContent;
