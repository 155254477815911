/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Grid, Button, Box, useTheme, Tooltip, Select, MenuItem } from '@mui/material';
import { SellerName, Loading, ProductName } from 'components';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import AttachedIcon from '@mui/icons-material/AttachmentRounded';
import InfiniteScroll from 'react-infinite-scroller';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import EnforcementActionsList from 'pages/Common/EnforcementActionsList';
import { AuthDataContext } from 'contexts';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';

const CustomSelect = styled(Select)(() => ({
  '& .MuiInputBase-input': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

const EnforcementDetail = ({ enforcement, actionTypes, onActionTaken, onRefetch, onUpdateFollowUpDate }) => {
  const [sellerProfileOpen, setSellerProfileOpen] = useState(false);
  const [sellerProfile, setSellerProfile] = useState(null);
  const [offers, setOffers] = useState([]);
  const [prevId, setPrevId] = useState(null);
  const [followUp, setFollowUp] = useState(0);
  const scrollPageLength = 4;
  const [limit, setLimit] = useState(scrollPageLength);
  const theme = useTheme();
  const { hasAbility } = useContext(AuthDataContext);

  const followUpArray = (() => {
    const arr = [];
    for (let i = 0; i <= 45; i += 1) {
      arr.push(i);
    }
    return arr;
  })();

  const daysUntilDate = (d) => {
    if (d) {
      const date = dayjs(d).startOf('day');
      const now = dayjs().startOf('day');
      const diff = date.diff(now, 'day')
      return diff;
    }
    return null;
  }

  const loadMore = () => {
    if (limit < enforcement.actions.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > enforcement.actions.length) {
        newLimit = enforcement.actions.length;
      }
      setLimit(newLimit);
    }
  };

  if (enforcement && prevId !== enforcement?.id) {
    setPrevId(enforcement.id);
    setLimit(scrollPageLength);
  }

  useEffect(() => {
    if (enforcement) {
      setOffers(enforcement.offers);
      const daysUntil = daysUntilDate(enforcement.followUpDate);
      if (daysUntil <= 0) {
        setFollowUp(-1);
      } else {
        setFollowUp(daysUntilDate(enforcement.followUpDate));
      }
    }
  }, [enforcement]);

  const handleFollowUpChange = (f) => {
    const getFollowUpDate = (d) => {
      if (d > 0) {
        const dateNow = dayjs();
        return dateNow.add(d, 'day').startOf('day').toISOString();
      }
      return null;
    }
    setFollowUp(f);
    onUpdateFollowUpDate({ enforcement, followUpDate: getFollowUpDate(f) })
  }

  return (
    <Box
      sx={{
        bgcolor: 'greys.white',
        borderRadius: theme.borderRadius,
        height: '100%',
      }}
    >
      {offers.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box>
            <Grid
              item
              container
              sx={{ borderBottom: `1px solid ${theme.palette.greys.lightGrey}`, mb: 1, p: 1 }}
            >
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                item
                sx={{
                  mb: '6px',
                  pl: '15px',
                  pr: '6px',
                  py: '6px',
                }}
              >
                <Grid item xs={4} sx={{ mb: 2 }}>
                  <Box
                    tabIndex={0}
                    role="button"
                    type="button"
                    className="boldText"
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onKeyDown={() => {
                      setSellerProfile({
                        sellerId: offers[0].sellerId,
                        sellerName: offers[0].sellerName,
                      });
                      setSellerProfileOpen(true);
                    }}
                    onClick={() => {
                      setSellerProfile({
                        sellerId: offers[0].sellerId,
                        sellerName: offers[0].sellerName,
                      });
                      setSellerProfileOpen(true);
                    }}
                  >
                    <SellerName
                      sellerName={offers[0].sellerName}
                      sx={{
                        display: 'block',
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#0071DA',
                        textDecoration: 'underline',
                        lineHeight: '18px',
                        maxHeight: '36px',
                        overflow: 'hidden',
                      }}
                    />
                    <SellerAuthorizationState globalAuth={offers[0].globalAuth} partialAuth={offers[0].sellerAuthorized} />
                  </Box>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-end" sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <CustomSelect
                      sx={{
                        py: 0,
                        mt: '0.5px',
                        minHeight: '29px',
                        maxHeight: '29px',
                        maxWidth: '160px',
                        minWidth: '160px'
                      }}
                      value={followUp <= 0 ? 0 : followUp}
                      renderValue={(selected) => {
                        const days = daysUntilDate(enforcement.followUpDate);
                        if (days < 0) {
                          return (
                            <Box sx={{ fontSize: '12px' }}>
                              <Box>Follow-up: <b>Overdue</b></Box>
                            </Box>
                          )
                        }
                        if (days === 0) {
                          return (
                            <Box sx={{ fontSize: '12px' }}>
                              <Box>Follow-up: <b>Today</b></Box>
                            </Box>
                          )
                        }
                        return (
                          <Box sx={{ fontSize: '12px' }}>
                            {selected > 0 && (
                              <Box>
                                Follow-up: <b>{selected} day{selected > 1 ? 's' : ''}</b>
                              </Box>
                            )}
                            {selected === 0 && (
                              <Box>Follow-up: <b>None</b></Box>
                            )}
                          </Box>
                        )
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 386,
                        },
                      }}
                    >
                      {followUpArray.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                          onClick={() => {
                            handleFollowUpChange(item);
                          }}
                          sx={{ fontWeight: item === followUp ? 700 : 500 }}
                        >
                          {item > 0 && (
                            <Box>
                              {item} day{item > 1 ? 's' : ''}
                            </Box>
                          )}
                          {item === 0 && (
                            <Box>None</Box>
                          )}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <SellerAndOfferMenu
                      button={
                        <Button
                          data-cy="enforcement_new"
                          endIcon={<CaretDown style={{ fill: theme.palette.greys.black }} />}
                          sx={{
                            bgcolor: 'greys.white',
                            color: 'greys.black',
                            border: `1px solid ${theme.palette.primary.main}`,
                            '&:hover': {
                              color: 'greys.white',
                            },
                            '&:hover $caretDown': {
                              fill: 'greys.white',
                            },
                            fontSize: '12px',
                            fontWeight: '600',
                            minHeight: '28px',
                            maxHeight: '28px',
                          }}
                        >
                          {hasAbility('sendLetter') || hasAbility('sendEmail') || hasAbility('createIpViolation') || hasAbility('makeTestBuy')
                            ? 'New Enforcement'
                            : 'Seller Options'}
                        </Button>
                      }
                      enforcement={enforcement}
                      offers={offers}
                      onActionTaken={onActionTaken}
                    />
                  </Box>
                </Grid>
                <Box sx={{ maxHeight: '190px', overflow: 'scroll', width: '100%' }}>
                  {offers.map((o) => (
                    <Grid key={o.id} container item sx={{ fontSize: '12px' }}>
                      <Grid item xs={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box data-cy="asin_id">{o.asin}</Box>
                          {o.attached && (
                            <Tooltip title="ASIN is still attached to the Seller">
                              <AttachedIcon sx={{ height: '14px', color: 'error.main' }} />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={7}>
                        <ProductName
                          asin={o.asin}
                          productName={o.name}
                          sx={{
                            display: 'block',
                            fontWeight: '500',
                            color: '#0071DA',
                            textDecoration: 'underline',
                            textOverflow: 'ellipsis',
                            lineHeight: '18px',
                            maxHeight: '18px',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Box sx={{ textAlign: 'right' }}>
                          <NavLink
                            target="_blank"
                            rel="noopener noreferrer"
                            to={`/enforcements/snapshot/${o.regionId}/${o.asin}/${encodeURIComponent(o.offerId)}`}
                            style={{
                              textDecoration: 'underline',
                              color: theme.palette.greys.silver,
                            }}
                          >
                            Amazon Snapshot
                          </NavLink>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflow: 'scroll', mb: 2 }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore
              loader={limit < enforcement.actions.length && <Loading key={0} />}
              useWindow={false}
              style={{
                width: '100%',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingTop: theme.spacing(1),
              }}
            >
              <EnforcementActionsList
                onActionTaken={onActionTaken}
                actionTypes={actionTypes}
                enforcement={enforcement}
                actions={enforcement.actions.slice(0, limit)}
                onRemoved={onRefetch}
                onUpdated={onRefetch}
              />
            </InfiniteScroll>
          </Box>
        </Box>
      )}
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfileOpen}
        onClose={() => {
          setSellerProfileOpen(false);
          onRefetch();
        }}
      />
    </Box>
  );
};

EnforcementDetail.propTypes = {
  enforcement: PropTypes.oneOfType([PropTypes.object]),
  actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onActionTaken: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
};

EnforcementDetail.defaultProps = {
  enforcement: null,
};

export default EnforcementDetail;
