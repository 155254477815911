import React, { useEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Box, useTheme } from '@mui/material';
import WidgetHeader from 'pages/Dashboard/WidgetHeader'
import Widget from 'pages/Dashboard//Widget';
import Color from 'color';

am5.addLicense('AM5C329389658');

const HalfPieChart = ({ title, data }) => {

  const seriesRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const root = am5.Root.new(`HalfPieChart-${title}`);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: -180,
        endAngle: 0
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "amount",
        categoryField: "label",
        startAngle: -180,
        endAngle: 0,
        innerRadius: am5.percent(50),
        radius: am5.percent(100),
        alignLabels: false
      })
    );

    series.slices.template.setAll({
      templateField: "sliceSettings",
      cornerRadius: 1,
      tooltipText: `[fontSize: 12px fontFamliy: ${theme.typography.fontFamily}]{category}: {valuePercentTotal.formatNumber('0.')}% ({value})[/]`,
    });

    series.slices.template.setup = (item) => {
      item.events.disableType("click")
    };

    series.labels.template.setAll({
      text: "{amount}",
      textType: "radial",
      radius: 17,
      inside: true,
      fontSize: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      opacity: 0.75,
    });

    // Disabling labels and ticks
    series.ticks.template.set("visible", false);

    series.labels.template.adapters.add("y", (y, target) => {
      const dataItem = target.dataItem;
      if (dataItem) {
        const tick = dataItem.get("tick");
        if (tick) {
          if (dataItem.get("valuePercentTotal") < 7) {
            target.set("forceHidden", true);
            tick.set("forceHidden", true);
          }
          else {
            target.set("forceHidden", false);
            tick.set("forceHidden", false);
          }
        }
        return y;
      }
      return null;
    });

    seriesRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    const modifiedData = data.map(d => {
      const color = Color(d.color);
      return {
        ...d,
        sliceSettings: {
          fill: am5.color(d.color),
          stroke: am5.color(color.darken(0.35).hex())
        }
      }
    });
    seriesRef.current.data.setAll(modifiedData);

  }, [data]);

  return (
    <Widget>
      <WidgetHeader title={title} sx={{ pb: 0, minHeight: 0 }} center />
      <Box sx={{ display: 'grid', justifyContent: 'center', minHeight: '92px' }}>
        <Box
          sx={{
            gridRowStart: 1,
            gridColumnStart: 1,
            height: '92px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            id={`HalfPieChart-${title}`}
            sx={{
              pt: 0,
              px: 0.5,
              mb: 0,
              width: '218px',
              height: '86px',
              display: 'inline-block',
            }}
          />
        </Box>
      </Box>
    </Widget>
  );
}

export default HalfPieChart;
