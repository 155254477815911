import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

function Widget({ children, sx }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        m: 1,
        display: 'flex',
        backgroundColor: 'greys.white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: theme.largeBorderRadius,
        flexDirection: 'column',
        overflow: 'hidden',
        height: `calc(100% - ${theme.spacing(2)})`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

Widget.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
};

Widget.defaultProps = {
  sx: null,
};

export default Widget;
