import React, { useState, useContext, useEffect } from 'react';
import {
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  styled,
  tableCellClasses,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, SellerName } from 'components';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { formatDate } from 'utils/dates';

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7FA'
  },
}));

const DetachedSellers = ({ asin }) => {
  const [detachedSellers, setDetachedSellers] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const theme = useTheme();
  const { tenant } = useContext(AuthDataContext);
  const tenantPath = tenant.tenant !== 'ipsecure' ? `/${tenant.tenant}` : '';


  const GET_DETACHED_SELLERS_QUERY = gql`
    query GetDetachedSellers($asin: String!) {
      getDetachedSellersForAsin(asin: $asin) {
        actionsTaken
        harvestDate
        name
        sellerId
      }
    }
  `;

  // Using lazy query here, because of what looks to be a bug in Apollo, where the query keeps getting re-executed
  // with the cache-and-network setting even though query variables aren't changing, and using a lazy query
  // works around this bug
  const { loading, data: detachedData } = useQuery(GET_DETACHED_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !asin,
    variables: {
      asin,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (detachedData) {
      const sellers = detachedData.getDetachedSellersForAsin || [];
      const sortedSellers = sellers.concat().sort((a, b) => new Date(b.harvestDate) - new Date(a.harvestDate));
      setDetachedSellers(sortedSellers);
    }
  }, [detachedData]);

  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Box>
          {detachedSellers.length > 0 && (
            <TableContainer sx={{ overflowX: 'initial' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      borderTop: `1px solid ${theme.palette.greys.backgroundGrey}`,
                      borderLeft: `1px solid ${theme.palette.greys.backgroundGrey}`,
                      borderRight: `1px solid ${theme.palette.greys.backgroundGrey}`,
                    }}
                  >
                    <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell width="20%" align="right">Actions taken</StyledTableHeaderCell>
                    <StyledTableHeaderCell width="20%" align="right">Last attached date</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody data-cy="asin_offers">
                  {detachedSellers.map((seller) => (
                    <TableRow sx={{ bgcolor: 'greys.white' }} key={seller.sellerId}>
                      <TableCell>
                        <Box
                          tabIndex={0}
                          role="button"
                          type="button"
                          className="boldText"
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onKeyDown={() => {
                            window.open(`${tenantPath}/sellers/${seller.sellerId}`, '_blank');
                          }}
                          onClick={() => {
                            window.open(`${tenantPath}/sellers/${seller.sellerId}`, '_blank');
                          }}
                        >
                          <SellerName
                            sellerName={seller.name}
                            sx={{
                              display: 'block',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#0071DA',
                              textDecoration: 'underline',
                              lineHeight: '18px',
                              maxHeight: '36px',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {seller.actionsTaken}
                      </TableCell>
                      <TableCell align="right">
                        {formatDate(new Date(seller.harvestDate))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};

DetachedSellers.propTypes = {
  asin: PropTypes.string.isRequired,
};

export default DetachedSellers;
