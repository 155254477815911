import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { CustomDialog, CustomDialogContent } from 'components';
import { ReactComponent as Clippy } from 'assets/giraffe-letter-sent.svg';

const LetterSuccessDialog = ({ open, onClose, email }) => (
  <CustomDialog
    open={open}
    onClose={() => onClose()}
    hideClose
    content={
      <CustomDialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          <Clippy />
          {email && <Typography>Your email has been sent!</Typography>}
          {!email && <Typography>Your letter is on its way!</Typography>}
          <Button onClick={() => onClose(true)} sx={{ msTransitionDuration: '30px' }}>
            OK
          </Button>
        </Box>
      </CustomDialogContent>
    }
  />
);

export default LetterSuccessDialog;
