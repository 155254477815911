import React from 'react';
import { Button } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import CustomDialogContent from 'components/CustomDialog/CustomDialogContent';
import feedbackSvc from 'services/feedback-service';

const FeatureTestDialog = ({ featureDescription, featureTitle, featureTestOpen, handleClose }) => {
  const handleConfirm = () => {
    feedbackSvc.sendFeedback({
      comment: `User confirmed interest in ${featureTitle}`,
      url: document.URL,
    });
    handleClose();
  };

  return (
    <CustomDialog
      title="Beta Feature Confirmation"
      open={featureTestOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      content={<CustomDialogContent>{featureDescription}</CustomDialogContent>}
      actions={
        <>
          <Button onClick={handleClose} variant="outlined" data-cy="seller_beta_cancel">
            Cancel
          </Button>
          <Button onClick={handleConfirm}>I&apos;m Interested</Button>
        </>
      }
    />
  );
};

export default FeatureTestDialog;
