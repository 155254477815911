import React, { useState } from 'react';
import { Button } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import CustomFieldForm from 'pages/Common/CustomFieldForm';

const EditActionFieldsDialog = ({ onClose, actionId, areas, actionIds }) => {
  const [saving, setSaving] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const isString = () => {
    if (actionIds) {
      if (typeof actionIds[0] === 'string' || actionIds[0] instanceof String) {
        return true;
      }
      return false;
    }
    if (typeof actionId === 'string' || actionId instanceof String) {
      return true;
    }
    return false;
  }

  return (
    <CustomDialog
      title="Edit Information"
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px', minHeight: '80vh' },
      }}
      content={
        <CustomDialogContent>
          <CustomFieldForm
            formId="edit-notes-photos"
            areas={areas}
            attachedIdString={isString() ? actionId : null}
            attachedIdInt={isString() ? null : actionId}
            attachedIdInts={isString() ? null : actionIds}
            attachedIdStrings={isString() ? actionIds : null}
            onBusy={(v) => setSaving(v)}
            onSaved={() => onClose(true)}
            onError={(v) => setDisableSave(v)}
          />
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={saving} variant="outlined" onClick={() => onClose(false)} data-cy="edit_cancel">
            Cancel
          </Button>
          <LoadingButton form="edit-notes-photos" loading={saving} disabled={disableSave} variant="contained" type="submit" data-cy="edit_save">
            Save
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditActionFieldsDialog;
