import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Top = ({ children, transparent, sx }) => (
  <Box
    sx={{
      borderRadius: (theme) => theme.largeBorderRadius,
      paddingTop: (theme) => theme.spacing(theme.contentPaddingSpacing - 1),
      paddingRight: (theme) => theme.spacing(theme.contentPaddingSpacing),
      paddingLeft: (theme) => theme.spacing(theme.contentPaddingSpacing),
      paddingBottom: (theme) => theme.spacing(theme.contentPaddingSpacing - 1),
      bgcolor: transparent ? 'transparent' : 'greys.white',
      ...sx,
    }}
  >
    {children}
  </Box>
);

Top.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  transparent: PropTypes.bool,
};

Top.defaultProps = {
  sx: null,
  transparent: false,
};

export default Top;
