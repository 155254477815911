import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
} from '@mui/material';
import { ConfirmationDialog, Loading, LinkButton } from 'components';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ToastDataContext } from 'contexts';
import { useMutation, gql } from '@apollo/client';
import { formatDate } from 'utils/dates';
import { ALL_REGIONS } from 'utils/constants';
import EditUser from './EditUser';
import EditUserAlerts from './EditUserAlerts';

const ActionsMenu = ({ loggedInUser, onEdit, onEditAlerts, onDisable, onEnable, onDelete, onRemove, onResentInvite, user, selfId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  const userHasPermission = (permission) => { 
    let permissions = [];
    for (let i = 0; i < loggedInUser?.roles?.length; i += 1) {
      permissions = permissions.concat(loggedInUser.roles[i].permissions);
    }
    return permissions.map(p => p.name).includes(permission);
  }

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreHorizIcon fill={theme.palette.greys.silver} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onEdit(user);
            }}
            data-cy="edit_user_button"
          >
            <EditIcon style={{ width: '15px', height: '15px' }} />
            Edit User
          </MenuItem>
        )}
        {userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onEditAlerts(user);
            }}
          >
            <EditIcon style={{ width: '15px', height: '15px' }} />
            Edit Email Alerts
          </MenuItem>
        )}
        <Box
          sx={{
            width: '100%',
            m: 0,
            p: 0,
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          }}
        />
        {!user.lastLoggedIn && !user.disabled && user.userId !== selfId && (
          <MenuItem
            onClick={() => {
              handleClose();
              onResentInvite(user);
            }}
            data-cy="user_resend_invite"
          >
            Resend Invite
          </MenuItem>
        )}
        {!user.disabled && user.userId !== selfId && userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDisable(user);
            }}
            data-cy="user_disable"
            sx={{ color: theme.colors.red }}
          >
            Disable User
          </MenuItem>
        )}
        {user.userId !== selfId && userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onRemove(user);
            }}
            data-cy="user_remove"
            sx={{ color: theme.colors.red }}
          >
            Remove User From Account
          </MenuItem>
        )}
        {user.disabled && user.userId !== selfId && userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onEnable(user);
            }}
            data-cy="user_enable"
          >
            Enable User
          </MenuItem>
        )}
        {user.deletable && user.userId !== selfId && userHasPermission('accountManagement') && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDelete(user);
            }}
            data-cy="user_delete"
            sx={{ color: theme.colors.red }}
          >
            Delete User
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const UserRow = ({ accountId, loggedInUser, user, selfId, index, admin, tenant, showTemporaryPassword, onAlertsUpdated }) => {

  const [deleteConfirmationUser, setDeleteConfirmationUser] = useState(null);
  const [removeConfirmationUser, setRemoveConfirmationUser] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [editUserAlerts, setEditUserAlerts] = useState(null);
  const [changingUser, setChangingUser] = useState(false);
  const [busy, setBusy] = useState(false);
  const { setToast } = useContext(ToastDataContext);

  const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($id: ID!, $accountId: ID!, $disabled: Boolean) {
      updateUserInAccount(id: $id, accountId: $accountId, disabled: $disabled) {
        id
      }
    }
  `;

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onError: (e) => {
      setBusy(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const DELETE_USER_MUTATION = gql`
    mutation DeleteUser($id: ID!, $accountId: ID!) {
      deleteUser(id: $id, accountId: $accountId) 
    }
  `;

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    onError: (e) => {
      setBusy(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const REMOVE_USER_MUTATION = gql`
    mutation RemoveUser($id: ID!, $accountId: ID!) {
      removeUser(id: $id, accountId: $accountId) 
    }
  `;

  const [removeUser] = useMutation(REMOVE_USER_MUTATION, {
    onError: (e) => {
      setBusy(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const INVITE_USER_MUTATION = gql`
    mutation InviteUser($inviteEmail: String!, $accountId: ID!) {
      inviteUser(inviteEmail: $inviteEmail, accountId: $accountId, resend: true) 
    }
  `;

  const [inviteUser] = useMutation(INVITE_USER_MUTATION, {
    onError: (e) => {
      setBusy(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleEnable = (u) => {
    setBusy(true);
    updateUser({
      variables: {
        id: u.userId,
        accountId,
        disabled: false,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "User enabled." });
        setBusy(false);
      },
      update(cache) {
        cache.modify({
          id: cache.identify(u),
          fields: {
            disabled() {
              return false;
            },
          },
        });
      },
    });
  }

  const handleDisable = (u) => {
    setChangingUser(true);
    updateUser({
      variables: {
        id: u.userId,
        accountId,
        disabled: true,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "User disabled." });
        setChangingUser(false);
      },
      update(cache) {
        cache.modify({
          id: cache.identify(u),
          fields: {
            disabled() {
              return true;
            },
          },
        });
      },
    });
  }

  const handleDelete = (u) => {
    setChangingUser(true);
    deleteUser({
      variables: {
        id: u.userId,
        accountId,
        disabled: true,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "User deleted." });
        setChangingUser(false);
        setDeleteConfirmationUser(null);
      },
      update(cache) {
        const normalizedId = cache.identify({ id: u.userId, __typename: 'User' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }

  const handleRemove = (u) => {
    setChangingUser(true);
    removeUser({
      variables: {
        id: u.userId,
        accountId
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "User removed." });
        setChangingUser(false);
        setRemoveConfirmationUser(null);
      },
      update(cache) {
        const normalizedId = cache.identify({ id: u.userId, __typename: 'User' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }

  const handleEdit = (u) => {
    setEditUser(u);
  }

  const handleEditAlerts = (u) => {
    setEditUserAlerts(u);
  }


  const handleResendInvite = async (u) => {
    inviteUser({
      variables: {
        accountId: u.accountId,
        inviteEmail: u.email,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: `Sent invite to: ${u.email}` });
        setBusy(false);
      },
      update(cache) {
        cache.modify({
          id: cache.identify(u),
          fields: {
            invitedDate() {
              return new Date();
            },
          },
        });
      },
    });
  }

  const getLoginUrl = () => {
    const suffix = tenant.tenant === 'ipsecure' ? '' : `${tenant.tenant}/`;
    const href = `${window.location.origin}/${suffix}invite/${user?.temporaryPassword}?username=${encodeURIComponent(user?.email)}`;
    return href;
  }

  const getAbilities = () => { 
    let abilities = [];
    abilities = abilities.concat(user?.configuredAbilities);
    abilities.sort((a, b) => a.name.localeCompare(b.name));
    return abilities;
  }

  const getRegionList = () => {
    const isAllRegionsSelected = (list) => {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === ALL_REGIONS) {
          return true;
        }
      }
      return false;
    }
    if (isAllRegionsSelected(user.configuredRegions)) {
      return 'All Available';
    }
    const regions = user.configuredRegions.map(r => r.id);
    return regions.join(', ');
  }

  const showUserTemporaryPassword = () => {
    let found = false;
    if (user?.temporaryPassword?.length > 0) {
      const count = user.availableAccounts.length;
      let tempCount = 0;
      for (let j = 0; j < user.availableAccounts.length; j += 1) {
        if (!user.availableAccounts[j].loggedIn) {
          tempCount += 1;
        }
      }
      if (tempCount === count) {
        found = true;
      }
    }
    return found;
  }

  return (
    <TableRow sx={{ bgcolor: index % 2 === 1 ? 'greys.backgroundGrey' : '', }}>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            {user?.firstName} {user?.lastName}
            {user.disabled ? (<Box sx={{ fontWeight: 500 }}>(disabled)</Box>) : ''}
            {user?.userId === selfId && (<Box sx={{ fontWeight: 500 }}>(you)</Box>)}
          </Box>
          <Box>
            <LinkButton
              hyperStyle
              onClick={() => {
                handleEdit(user);
              }}
            >
              {user?.email}
            </LinkButton>
          </Box>
        </Box>
      </TableCell>
      {showTemporaryPassword && (
        <TableCell>
          {showUserTemporaryPassword() && (
            <a href={getLoginUrl()}>
              {user?.temporaryPassword}
            </a>
          )}
        </TableCell>
      )}
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, fontSize: '12px' }}>
          {user?.roles?.length > 0 && (
            <Box>
              <b>
                {user?.roles ? [...user.roles].sort((a, b) => a.description.localeCompare(b.description)).map(r => r.description).join(', ') : ''}
              </b>
            </Box>
          )}
          {user?.roles?.length === 0 && (
            <Box>
              <b>
                Default Role
              </b>
            </Box>
          )}
          {user?.configuredAbilities?.length > 0 && (
            <Box>
              <i>
                {getAbilities().map(r => r.name).join(', ')}
              </i>
            </Box>
          )}
          {user?.configuredAbilities?.length === 0 && (
            <Box>
              <i>
                Default Abilities
              </i>
            </Box>
          )}
          <Box>
            Regions: {getRegionList()}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ fontSize: '12px' }}>
          <ul style={{margin: 0, padding: 0}}>
            {user.alertRules?.filter(a => a.emailEnabled).map(a => (<li key={a.id}>{a.alertRuleType} {a.thresholdEnabled ? `(${a.thresholdValue})` : ''}</li>))}
          </ul>
        </Box>
      </TableCell>
      <TableCell>
        {user?.lastLoggedIn && (
          <Box>
            Logged in {formatDate(new Date(user?.lastLoggedIn))}
          </Box>
        )}
        {!user?.lastLoggedIn && (
          <Box>
            Invited on {formatDate(new Date(user?.invitedDate))}
          </Box>
        )}
      </TableCell>
      <TableCell align="center" data-cy="users_actions">
        <Box sx={{ minHeight: '30px', maxHeight: '30px' }}>
          {busy && <Loading small />}
          {!busy && (
            <ActionsMenu
              loggedInUser={loggedInUser}
              admin={admin}
              user={user}
              selfId={selfId}
              onEdit={handleEdit}
              onEditAlerts={handleEditAlerts}
              onEnable={handleEnable}
              onDisable={handleDisable}
              onDelete={setDeleteConfirmationUser}
              onRemove={setRemoveConfirmationUser}
              onResentInvite={handleResendInvite}
            />
          )}
        </Box>
      </TableCell>
      {deleteConfirmationUser && (
        <ConfirmationDialog
          open
          title="Delete User"
          message={`Are you sure you wish to delete ${deleteConfirmationUser?.email}?`}
          busy={changingUser}
          okTitle="Delete"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              setChangingUser(true);
              handleDelete(deleteConfirmationUser);
            } else {
              setDeleteConfirmationUser(null);
            }
          }}
        />
      )}
      {removeConfirmationUser && (
        <ConfirmationDialog
          open
          title="Remove User From Account"
          message={`Are you sure you wish to remove ${deleteConfirmationUser?.email} from this account?`}
          busy={changingUser}
          okTitle="Remove"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              setChangingUser(true);
              handleRemove(removeConfirmationUser);
            } else {
              setRemoveConfirmationUser(null);
            }
          }}
        />
      )}
      {editUser && (
        <EditUser
          loggedInUser={loggedInUser}
          user={editUser}
          accountId={accountId}
          isOpen
          onClose={() => setEditUser(null)}
        />
      )}
      {editUserAlerts && (
        <EditUserAlerts
          loggedInUser={loggedInUser}
          user={editUserAlerts}
          accountId={accountId}
          isOpen
          onClose={() => setEditUserAlerts(null)}
          onAlertsUpdated={onAlertsUpdated}
        />
      )}
    </TableRow>
  )
};

UserRow.propTypes = {
  tenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loggedInUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
  accountId: PropTypes.number.isRequired,
  selfId: PropTypes.number,
  index: PropTypes.number.isRequired,
  showTemporaryPassword: PropTypes.bool.isRequired,
};

UserRow.defaultProps = {
  selfId: null,
}

export default UserRow;
