import React from 'react';
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Box,
  useTheme,
} from '@mui/material';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import violationTerms from './violationTerms.html';

const ViolationTerms = ({ termsAgreed, setTermsAgreed, termsAndConditionsViolations }) => {
  const theme = useTheme();
  const template = { __html: termsAndConditionsViolations ?? violationTerms };

  const onCheck = (e) => {
    setTermsAgreed(e.target.checked);
  };

  return (
    <Box sx={{ mb: 2, width: '50vw' }}>
      <Typography variant="h4">License and Violation Terms and Conditions</Typography>
      <Accordion disableGutters expanded={!termsAgreed}>
        <AccordionSummary
          expandIcon={
            // <Box sx={{ transform: 'rotate(-90deg)' }}>
            <CaretDown style={{ transform: 'rotate(-90deg)' }} fill={theme.palette.primary.main} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
              ml: 1,
            },
            '&.MuiAccordionSummary-content.Mui-expanded': {
              ml: 2,
            },
            '& .MuiAccordionSummary-expandIcon': {
              ml: '-12px',
              mr: '-12px',
              p: '5px',
            },
          }}
        >
          <FormControlLabel
            sx={{ color: 'greys.darkGrey', mr: '5px' }}
            control={<Checkbox checked={termsAgreed} onChange={onCheck} />}
            label="I have read and agree with the statements below:"
            data-cy="terms_agreed_checkbox"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={template} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ViolationTerms;
