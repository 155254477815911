import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Tooltip, Box, useTheme } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as BuyBoxWinnerIcon } from 'assets/buy-box-winner.svg';
import { Chip, SellerName } from 'components';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';
import { formatDate } from 'utils/dates';

const InsightsSellerRow = ({ seller, onShowSeller, insight, compact }) => {
  const theme = useTheme();

  const insightDisplayValue = () => {
    switch (insight?.type) {
      case 'negativeReviews':
      case 'buyBoxWinRate':
        return `${parseInt(seller.tag, 10)}%`;
      default:
        if (insight?.tagName) {
          return seller.tag;
        }
        return '';
    }
  };

  const backgroundColor = () => {
    if (seller.authorized === null) {
      return theme.palette.chips.silver.backgroundColor;
    }
    return seller.authorized === true ? theme.palette.chips.blue.backgroundColor : theme.palette.chips.red.backgroundColor;
  }

  const color = () => {
    if (seller.authorized === null) {
      return theme.palette.chips.silver.color;
    }
    return seller.authorized === true ? theme.palette.chips.blue.color : theme.palette.chips.red.color;
  }

  return (
    <TableRow
      key={seller.id}
      sx={{
        bgcolor: seller?.score < 0 ? '#F9EEF1' : 'greys.backgroundGrey',
      }}
    >
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center' }}>
          {seller.buyBoxWinRate > 0 && seller?.score < 0 && (
            <Tooltip title="Seller has at least one item that is a buy box winner.">
              <Box>
                <BuyBoxWinnerIcon
                  fill={theme.palette.error.main}
                  style={{
                    color: theme.palette.error.main,
                    minWidth: '22px',
                    minHeight: '22px',
                    maxWidth: '22px',
                    maxHeight: '22px',
                  }}
                />
              </Box>
            </Tooltip>
          )}
          {seller.buyBoxWinRate > 0 && seller?.score >= 0 && (
            <Tooltip title="Seller has at least one item that is a buy box winner.">
              <Box>
                <BuyBoxWinnerIcon
                  fill={theme.palette.chips.turquoise.color}
                  style={{
                    color: theme.palette.error.main,
                    minWidth: '22px',
                    minHeight: '22px',
                    maxWidth: '22px',
                    maxHeight: '22px',
                  }}
                />
              </Box>
            </Tooltip>
          )}
          {seller?.score < 0 && seller.buyBoxWinRate === 0 && (
            <ErrorRoundedIcon
              sx={{
                color: 'error.main',
                minWidth: '22px',
                minHeight: '22px',
                maxWidth: '22px',
                maxHeight: '22px',
              }}
            />
          )}
          {seller?.score >= 0 && seller.buyBoxWinRate === 0 && (
            <Box
              sx={{
                color: 'error.main',
                minWidth: '22px',
                minHeight: '22px',
                maxWidth: '22px',
                maxHeight: '22px',
              }}
            />
          )}
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
              textDecoration: 'underline',
            }}
            onClick={() => {
              onShowSeller(seller.id);
            }}
          >
            <SellerName
              sellerName={seller.name || ''}
              sx={{
                display: 'block',
                fontWeight: '600',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
          </Box>
          <SellerAuthorizationState globalAuth={seller.globalAuth} partialAuth={seller.authorized} />
        </Box>
      </TableCell>
      <TableCell>{insightDisplayValue()}</TableCell>
      <TableCell>{seller?.score}</TableCell>
      {!compact && (
        <>
          <TableCell>{seller.asins}</TableCell>
          <TableCell>
            <Tooltip
              title={seller.actionsTaken > 0 ? (
                <Box>
                  {seller.actionsTaken > 0 && (
                    <Box>
                      Last action taken on {formatDate(seller.lastActionTakenDate)}
                    </Box>
                  )}
                </Box>
              ) : null
              }
              arrow
              placement="bottom"
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <MailIcon fill={theme.palette.greys.grey} />
                {seller.actionsTaken}
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Chip
              label={seller.authorized ? 'Authorized' : 'Unauthorized'}
              backgroundColor={backgroundColor()}
              color={color()}
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <SellerAndOfferMenu seller={seller} />
      </TableCell>
    </TableRow>
  );
};

InsightsSellerRow.propTypes = {
  seller: PropTypes.oneOfType([PropTypes.object]).isRequired,
  insight: PropTypes.oneOfType([PropTypes.object]),
  onShowSeller: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

InsightsSellerRow.defaultProps = {
  insight: null,
  compact: false,
};

export default InsightsSellerRow;
