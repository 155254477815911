/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import {
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  useTheme,
} from '@mui/material';
import { ConfirmationDialog, Loading, LoadingButton } from 'components';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import EmptyState from 'pages/Common/EmptyState';
import AddPermittedDomain from './AddPermittedDomain';

const ActionsMenu = ({ onDelete, domain }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreHorizIcon fill={theme.palette.greys.silver} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="domain_delete"
          onClick={() => {
            handleClose();
            onDelete(domain);
          }}
        >
          <DeleteIcon />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const PermittedDomains = ({ tenant }) => {
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState(null);
  const [removeConfirmationDomain, setRemoveConfirmationDomain] = useState(null);
  const [removingDomain, setRemovingDomain] = useState(false);
  const [verifyingDomain, setVerifyingDomain] = useState(false);
  const { hasPermission } = useContext(AuthDataContext);
  const [showAdd, setShowAdd] = useState(false);

  const theme = useTheme();

  if (!hasPermission('accountManagement')) {
    return <Box />;
  }

  const GET_DOMAINS_QUERY = gql`
    query GetPermittedDomains($tenantId: Int!) {
      getPermittedDomains(tenantId: $tenantId) {
        id
        domain
        enabled
        sendGridValidated
        sendGridValid
        sendGridJson
        sendGridValidationJson
      }
    }
  `;

  const REMOVE_PERMITTED_DOMAIN_MUTATION = gql`
    mutation RemovePermittedDomain($id: ID!) {
      removePermittedDomain(id: $id)
    }
  `;

  const [removePermittedDomain] = useMutation(REMOVE_PERMITTED_DOMAIN_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setLoading(false);
      setRemovingDomain(false);
    },
  });

  const VERIFY_PERMITTED_DOMAIN_MUTATION = gql`
    mutation VerifyPermittedDomain($id: ID!) {
      verifyPermittedDomain(id: $id)
    }
  `;

  const [verifyPermittedDomain] = useMutation(VERIFY_PERMITTED_DOMAIN_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setVerifyingDomain(false);
    },
  });


  const processData = (data) => {
    if (data) {
      let copiedData = cloneDeep(data.getPermittedDomains);
      copiedData = copiedData.map(d => ({...d, json: JSON.parse(d.sendGridJson), jsonVerify: JSON.parse(d.sendGridValidationJson)}));
      setDomains(copiedData);
      setLoading(false);
    }
  };

  const { refetch } = useQuery(GET_DOMAINS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { tenantId: tenant.id },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleRemove = (domain) => {
    setRemoveConfirmationDomain(domain);
  };

  const removeDomain = (domain) => {
    setRemovingDomain(true);
    removePermittedDomain({
      variables: {
        id: domain.id,
      },
      onCompleted: () => {
        setLoading(true);
        refetch();
        setLoading(false);
        setRemovingDomain(false);
        setRemoveConfirmationDomain(null);
      },
    });
  };

  const verifyDomain = (id) => {
    setVerifyingDomain(id);
    verifyPermittedDomain({
      variables: {
        id,
      },
      onCompleted: () => {
        refetch();
        setVerifyingDomain(false);
      },
    });
  };

  const getDnsRecordText = (domain) => {
    let dnsText = ``;
    const keys = Object.keys(domain.json.dns);
    keys.sort((a,b) => a.localeCompare(b));
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const item = domain.json.dns[key];
      dnsText += `${key.toUpperCase()}\n${'-'.repeat(key.length)}\n`;
      dnsText += `TYPE: ${item.type.toUpperCase()}\n`;
      dnsText += `HOST: ${item.host}\n`;
      dnsText += `DATA: ${item.data}\n\n`;
    }
    dnsText += `DMARC_RECORD\n------------\n`;
    dnsText += `TYPE: TEXT\n`;
    dnsText += `HOST: _dmarc.${domain.domain}\n`;
    dnsText += `DATA: v=DMARC1; p=none;\n`;
    return dnsText;
  }

  const copyDnsToClipboard = (domain) => {
    navigator.clipboard.writeText(getDnsRecordText(domain));
    setToast({ type: 'success', message: "Copied to the clipboard" });
  }

  const getDnsVerifyText = (domain) => {
    let verifyText = ``;
    const keys = Object.keys(domain.jsonVerify.validation_results);
    keys.sort((a,b) => a.localeCompare(b));
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const item = domain.jsonVerify.validation_results[key];
      verifyText += `${key.toUpperCase()}\n${'-'.repeat(key.length)}\n`;
      verifyText += `VALID: ${item.valid ? 'Yes' : 'No'}\n`;
      if (!item.valid && item.reason) {
        verifyText += `REASON: ${item.reason}\n`;
      }
      verifyText += '\n';
    }
    return verifyText;
  }

  const copyVerifyToClipboard = (domain) => {
    navigator.clipboard.writeText(getDnsVerifyText(domain));
    setToast({ type: 'success', message: "Copied to the clipboard" });
  }

  return (
    <Box sx={{ overflow: 'hidden', height: 'calc(100vh - 150px)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="body2">
          Manage domains to be used for sending enforcement emails.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="outlined" disabled={loading} onClick={() => setShowAdd(true)} data-cy="add_domain_button">
            Add Domain
          </Button>
        </Box>
      </Box>
      <Box sx={{ overflow: 'scroll', height: 'calc(100vh - 210px)' }}>
        {loading && <Loading />}
        {!loading && domains && domains.length === 0 && (
          <EmptyState
            content="No domains have been added yet."
            button={
              <Button
                disabled={loading}
                onClick={() => setShowAdd(true)}
                startIcon={<AddIcon style={{ fill: theme.palette.greys.white }} />}
              >
                Add Domain
              </Button>
            }
          />
        )}
        {!loading && domains && domains.length > 0 && (
          <TableContainer sx={{ overflowX: 'initial', pointerEvents: verifyingDomain ? 'none' : 'all' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="15%">
                    Domain
                  </TableCell>
                  <TableCell align="left" width="40%">
                    DNS Records Needed
                  </TableCell>
                  <TableCell align="left" width="40%">
                    Status
                  </TableCell>
                  <TableCell align="right" width="5%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {domains?.map((domain) => (
                  <TableRow key={domain.id.toString()}>
                    <TableCell align="left">
                      <b>{domain.domain}</b>
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '10px', fontFamily: 'monospace', overflowWrap: 'break-word', maxWidth: '30vw', whiteSpace: 'pre-line', mt: 2 }}>
                        {!domain.enabled && getDnsRecordText(domain)}
                        <Box sx={{ mt: domain.enabled ? 0 : 2, mb: 2 }}>
                          <Button variant="outlined" onClick={() => copyDnsToClipboard(domain)}>Copy to Clipboard</Button>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <LoadingButton loading={verifyingDomain === domain.id} variant="outlined" onClick={() => verifyDomain(domain.id)}>Verify Domain</LoadingButton>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 2 }}>
                        <Box>
                          DNS Records: {domain.sendGridValidated ? 'Yes' : 'No'}
                        </Box>
                        <Box>
                          Valid: {domain.sendGridValid ? 'Yes' : 'No'}
                        </Box>
                        <Box>
                          Enabled: {domain.enabled ? 'Yes' : 'No'}
                        </Box>
                        {domain.jsonVerify && domain.jsonVerify.valid === false && verifyingDomain !== domain.id && (
                          <Box sx={{ mt: 2 }}>
                            <Box><b>Verification Results:</b></Box>
                            <Box sx={{ fontSize: '10px', fontFamily: 'monospace', overflowWrap: 'break-word', maxWidth: '20vw', whiteSpace: 'pre-line', mt: 2 }}>
                              {getDnsVerifyText(domain)}
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <Button variant="outlined" onClick={() => copyVerifyToClipboard(domain)}>Copy to Clipboard</Button>
                            </Box>
                            {domain.jsonVerify.valid && !domain.enabled && (
                              <Box sx={{ my: 2 }}>
                                It looks like everything is in the DNS records, but it may take up to 60 minutes from when an account is verified to when it is fully registered with SendGrid. Please try verifying again soon.
                              </Box>
                            )}
                          </Box>
                        )}
                        {verifyingDomain === domain.id && (
                          <Box sx={{ mt: 2 }}>
                            <Box><b>Verification Results:</b></Box>
                            <Box sx={{ mt: 2, display: 'flex' }}>
                              <Loading small />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell data-cy="settings_addresses_menu">
                      <ActionsMenu onDelete={handleRemove} domain={domain} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {showAdd && (
        <AddPermittedDomain
          tenantId={tenant.id}
          onCancel={() => setShowAdd(false)}
          onAdded={() => {
            setShowAdd(false);
            refetch();
          }}
        />
      )}
      {removeConfirmationDomain && (
        <ConfirmationDialog
          open
          title="Delete Domain"
          message={
            <Box sx={{ mt: 3 }}>
              Are you sure you wish to delete the <b>{removeConfirmationDomain.domain}</b> email domain?<br /><br /><b>This will also remove all email addresses associated with this domain.</b>
            </Box>
          }
          busy={removingDomain}
          okTitle="Delete"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              removeDomain(removeConfirmationDomain);
            } else {
              setRemoveConfirmationDomain(null);
            }
          }}
        />
      )}
    </Box>
  );
};

export default PermittedDomains;
