import React from 'react';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';
import { Box, useTheme } from '@mui/material';

const Loading = ({ small, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: small ? 0 : 4,
        ...sx,
      }}
    >
      <PulseLoader
        size={small ? 5 : 8}
        color={small ? theme.palette.greys.silver : theme.palette.greys.grey}
        margin={small ? '1px' : '2px'}
      />
    </Box>
  );
};

Loading.propTypes = {
  small: PropTypes.bool,
};

Loading.defaultProps = {
  small: false,
};

export default Loading;
