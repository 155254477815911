/* eslint-disable import/prefer-default-export */
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTheme } from '@mui/material';

const getItemStyle = (isDragging, draggableStyle) => {
  const theme = useTheme();
  return {
    userSelect: 'none',
    background: isDragging ? theme.palette.greys.white : 'transparent',
    borderRadius: theme.borderRadius,
    ...draggableStyle
  }
};

export const Drag = ({ id, index, ...props }) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style
        )}
      >
        {props.children}
      </div>
    )}
  </Draggable>
);
