import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/Chip/Chip';
import { Box, useTheme } from '@mui/material';

function ChipContainer({ labels, color, backgroundColor, sx }) {
  const theme = useTheme();
  return (
    <Box sx={{ m: -0.5, display: 'flex', flexWrap: 'wrap' }}>
      {labels?.map((label) => (
        <Chip
          margin={theme.spacing(0.5)}
          key={label}
          label={label}
          backgroundColor={backgroundColor}
          color={color}
          sx={sx}
        />
      ))}
    </Box>
  );
}

ChipContainer.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default ChipContainer;
