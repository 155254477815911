import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CustomDialog, CustomDialogContent, LoadingButton, Loading } from 'components';
import { Typography, Button, Card, Box } from '@mui/material';
import StripeCC from 'pages/Common/StripeCC';

const LetterDialog = ({ open, handleClose, sendLetter, loading, letterType, setLetterType }) => {
  const [cardOnFile, setCardOnFile] = useState(false);
  const [letters, setLetters] = useState([]);

  const LETTER_QUERY = gql`
    query GetLetterProducts {
      getLetterProducts {
        id
        price
        priceId
        name
        description
        type
      }
    }
  `;
  const { loading: loadingQuery } = useQuery(LETTER_QUERY, {
    onCompleted: (data) => {
      const products = data.getLetterProducts;
      const sorted = products.concat().sort((a, b) => a.price - b.price);
      setLetters(sorted);
      if (letterType === null && sorted.length > 0) {
        setLetterType(sorted[0]);
      }
    },
  });

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title="Send a Letter"
      maxWidth="sm"
      content={
        <CustomDialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {loadingQuery && <Loading />}
              {!loadingQuery &&
                letters.map((letter) => (
                  <Card
                    key={letter.type}
                    sx={[
                      {
                        flex: '1 1 0px',
                        borderRadius: (theme) => theme.borderRadius,
                        p: 2,
                        cursor: 'pointer',
                        boxShadow: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      },
                      letterType?.id === letter.id && {
                        border: (theme) => `2px solid ${theme.palette.primary.main}`,
                        bgcolor: 'primary.extraLight',
                      },
                      letterType?.id !== letter.id && {
                        border: (theme) => `2px dashed ${theme.palette.primary.main}`,
                        bgcolor: 'primary.white',
                      },
                    ]}
                    type="button"
                    role="button"
                    onClick={() => {
                      setLetterType(letter);
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {letter.name}
                    </Typography>
                    <Typography variant="h4" sx={{ color: 'primary.main' }}>
                      {letter.price.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                    <Typography variant="body1">{letter.description}</Typography>
                  </Card>
                ))}
            </Box>
            <Box sx={{ minHeight: '150px' }}>
              <StripeCC setCardOnFile={(c) => setCardOnFile(c)} size={8} />
            </Box>
          </Box>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!cardOnFile}
            loading={loading || loadingQuery}
            onClick={sendLetter}
          >
            Confirm
          </LoadingButton>
        </>
      }
    />
  );
};

export default LetterDialog;
