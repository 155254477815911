import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Scrollable, ScoreHighlight, NonScrollable, TabPanel, Loading } from 'components';
import { LineChart, Line, XAxis, YAxis, Tooltip as RETooltip, CartesianGrid } from 'recharts';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { Tabs, Tab, Button, Grid, Box, useTheme } from '@mui/material';
import { ReactComponent as BuildingIcon } from 'assets/business-building.svg';
import { ReactComponent as TestBuyIcon } from 'assets/shopping-cart.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { formatDate, roundToNearest30 } from 'utils/dates';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';
import PurchaseTestBuy from 'pages/TestBuys/TestBuyPurchase/PurchaseTestBuy';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { NavLink } from 'react-router-dom';
import OfferIcons from 'pages/Common/OfferIcons';
import { isAmazonSeller } from 'utils/misc';

const HistoryAboutItem = ({ title, info }) => (
  <>
    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{title}</Box>
    <Box sx={{ fontSize: '14px', fontWeight: '400' }}>{info}</Box>
  </>
);

const HistoryItem = ({ title, data, dataKey, color, fixedLength, currency }) => {
  const theme = useTheme();

  const cleanedValue = (v) => {
    if (v) {
      let value = v;
      value = `${value.toFixed(fixedLength || 0)}`;
      if (currency) {
        value = `${currency}${value}`;
      }
      return value;
    }
    return 0;
  };

  const getChartData = ({ history }) =>
    history.map((h) => ({ x: new Date(h.date), y: h[dataKey] })).reverse();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LineChart
            margin={{ top: 15, right: 10, left: 10, bottom: 15 }}
            width={500}
            height={200}
            data={getChartData({ history: data })}
          >
            <XAxis dataKey="x" tickFormatter={(v) => dayjs(v).format('M/D')} />
            <YAxis dataKey="y" />
            <RETooltip
              label={title}
              formatter={(v) => [`${cleanedValue(v)}`, `${title}`]}
              labelFormatter={(v) => dayjs(v).format('M/D/YYYY h:mm a')}
            />
            <Line
              strokeWidth={2}
              dataKey="y"
              stroke={color || theme.palette.primary.main}
              activeDot={{ r: 6 }}
              dot={false}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#bbb" />
          </LineChart>
        </Grid>
      </Grid>
    </>
  );
};

const OfferContent = ({ hide, offerId, seller, asin }) => {
  const [tab, setTab] = useState(0);
  const [offer, setOffer] = useState(null);
  const [referenceAddresses, setReferenceAddresses] = useState(null);
  const [rules, setRules] = useState(null);
  const [testBuyOpen, setTestBuyOpen] = useState(false);
  const [stockEstimationHistory, setStockEstimationHistory] = useState(null);
  const [winRateHistory, setWinRateHistory] = useState(null);
  const currentScore = offer?.currentScore;
  const isAmazon = isAmazonSeller({ sellerId: seller?.id, sellerName: seller?.name });
  const { account, region, hasAbility } = useContext(AuthDataContext);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const { regionUrl, regionCurrency } = useContext(RegionDataContext);

  const GET_OFFER_QUERY = gql`
    query GetOffer(
      $id: String!
      $asin: String
      $sellerId: String
      $dateStart: DateTime
      $dateEnd: DateTime
    ) {
      getOffer(offerId: $id) {
        asin
        sellerId
        buyBoxWinPercentage
        categoryId
        name
        offerId
        price
        mapPrice
        variantTitle
        fulfilledByAmazon
        image
        shippingPrice
        shippingDays
        harvestDate
        stockEstimation
        currentScore {
          score
        }
        winRateHistory {
          score
          date
        }
      }
      getAppliedRules {
        id
        categories {
          id
          name
        }
        userId
        name
        overrides {
          id
          ruleId
          ruleTypeId
          overrideValue
        }
      }
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getStockEstimationHistoryForAsinAndSeller(
        asin: $asin
        sellerId: $sellerId
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        score
        date
      }
    }
  `;

  const maxDate = new Date().getTime();
  const minDate = maxDate - 30 * 24 * 60 * 60 * 1000;
  const dateStart = roundToNearest30(new Date(minDate)).toISOString();
  const dateEnd = roundToNearest30(new Date(maxDate)).toISOString();

  const processData = (dataToProcess) => {
    const chartMinTime = (new Date(maxDate - 90 * 24 * 60 * 60 * 1000)).getTime();
    setOffer(dataToProcess.getOffer);
    setReferenceAddresses(dataToProcess.getAddresses);
    const reversedAppliedRules = dataToProcess.getAppliedRules?.concat().reverse();
    setRules(reversedAppliedRules);
    let sortedStockHistory = dataToProcess.getStockEstimationHistoryForAsinAndSeller
      ?.concat()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    sortedStockHistory = sortedStockHistory.filter(a => {
      const time1 = (new Date(a.date)).getTime();
      return time1 > chartMinTime;
    });
    setStockEstimationHistory(sortedStockHistory);
    let sortedWinRateHistory = dataToProcess.getOffer.winRateHistory
      ?.concat()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    sortedWinRateHistory = sortedWinRateHistory.filter(a => {
      const time1 = (new Date(a.date)).getTime();
      return time1 > chartMinTime;
    });
    setWinRateHistory(sortedWinRateHistory);
  };

  const { loading } = useQuery(GET_OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { id: offerId, asin, sellerId: seller.id, dateStart, dateEnd },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: processData,
  });

  const measure = (lat1, lon1, lat2, lon2) => {
    // generally used geo measurement function
    const R = 6378.137; // Radius of earth in KM
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000 * 0.00062137; // miles
  };

  const ruleTypes = {
    PRICE: 1,
    THIRTY_DAYS: 2,
    TWELVE_MONTHS: 3,
    LIFETIME_REVIEW: 4,
    SHIPPING_PRICE: 5,
    SHIPPING_DURATION: 6,
    DISTANCE: 7,
  };

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  const rulesForASIN = () => {
    if (rules.length > 0) {
      const workingRules = cloneDeep(rules[0]);
      for (let i = 1; i < rules.length; i += 1) {
        const ruleSet = rules[i];
        const ruleCategories = ruleSet.categories;
        for (let j = 0; j < ruleCategories.length; j += 1) {
          const category = ruleCategories[j];
          if (category.id === offer.categoryId) {
            const { overrides } = ruleSet;
            for (let k = 0; k < overrides.length; k += 1) {
              const override = overrides[k];
              if (override.overrideValue !== null) {
                for (let l = 0; l < workingRules.overrides.length; l += 1) {
                  const workingRuleOverride = workingRules.overrides[l];
                  if (workingRuleOverride.ruleTypeId === override.ruleTypeId) {
                    workingRuleOverride.overrideValue = override.overrideValue;
                    break;
                  }
                }
              }
            }
          }
        }
      }
      return workingRules;
    }
    return [];
  };

  const getRangeForAsin = (ruleType) => {
    const appliedRules = rulesForASIN();
    let rule = null;
    for (let i = 0; i < appliedRules.overrides.length; i += 1) {
      if (appliedRules.overrides[i].ruleTypeId === ruleType) {
        rule = appliedRules.overrides[i];
        break;
      }
    }
    if (rule.ruleTypeId === ruleTypes.PRICE) {
      const low = offer.mapPrice - (offer.mapPrice * rule.overrideValue) / 100.0;
      const high = offer.mapPrice + (offer.mapPrice * rule.overrideValue) / 100.0;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.SHIPPING_PRICE) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.SHIPPING_DURATION) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.THIRTY_DAYS) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.TWELVE_MONTHS) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.LIFETIME_REVIEW) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    if (rule.ruleTypeId === ruleTypes.DISTANCE) {
      const low = 0;
      const high = rule.overrideValue;
      return { low: Math.max(low, 0), high };
    }
    return {
      low: 0,
      high: 0,
    };
  };

  const getValueString = (ruleType) => {
    const range = getRangeForAsin(ruleType);
    let badValue = false;
    if (ruleType === ruleTypes.PRICE) {
      if (offer.price < range.low || offer.price > range.high) {
        if (range?.high !== 0) {
          badValue = true;
        }
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer.price !== null || Number.isNaN(offer.price)
            ? Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
                offer.price?.toFixed(2),
              )
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypes.SHIPPING_PRICE) {
      if (offer.shippingPrice > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer.shippingPrice !== null
            ? Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
                offer.shippingPrice?.toFixed(2),
              )
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypes.SHIPPING_DURATION) {
      if (offer.shippingDays > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {offer.shippingDays !== null ? offer.shippingDays?.toFixed(0) : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypes.THIRTY_DAYS) {
      if (seller?.negativeThirty > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {seller?.negativeThirty !== null ? `${seller.negativeThirty?.toFixed(0)}%` : '0'}
        </Box>
      );
    }
    if (ruleType === ruleTypes.TWELVE_MONTHS) {
      if (seller?.negativeTwelveMonths > range?.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {seller?.negativeTwelveMonths !== null
            ? `${seller.negativeTwelveMonths?.toFixed(0)}%`
            : 'N/A'}
        </Box>
      );
    }
    if (ruleType === ruleTypes.LIFETIME_REVIEW) {
      if (seller.countLifetime < range.high) {
        badValue = true;
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {seller.countLifetime?.toFixed(0)}
        </Box>
      );
    }
    if (ruleType === ruleTypes.DISTANCE) {
      let addr;
      let sellerAddr;
      let dist = null;
      if (referenceAddresses.length && seller?.coordinates) {
        addr = referenceAddresses.map((e) => e?.coordinates?.split(',').map((d) => parseFloat(d)));
        addr = addr.filter((a) => a);
        sellerAddr = seller?.coordinates?.split(',').map((d) => parseFloat(d));
        if (addr && sellerAddr) {
          dist = Math.min(
            ...addr.map((a) => measure(a[0], a[1], sellerAddr[0], sellerAddr[1])),
          ).toFixed(1);
          if (dist < range.high) {
            badValue = true;
          }
        }
      }
      return (
        <Box sx={{ color: badValue ? 'red' : 'black', fontWeight: badValue ? '800' : '400' }}>
          {dist ? `${dist} miles` : `N/A`}
        </Box>
      );
    }
    return null;
  };

  const getRangeForAsinString = (ruleType) => {
    const range = getRangeForAsin(ruleType);
    if (ruleType === ruleTypes.PRICE) {
      if (range?.high === 0) {
        return '';
      }
      return `(${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range?.low.toFixed(2))}-${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range.high.toFixed(2))})`;
    }
    if (ruleType === ruleTypes.SHIPPING_PRICE) {
      return `(<${Intl.NumberFormat(region, {
        style: 'currency',
        currency: regionCurrency,
      }).format(range?.high.toFixed(2))})`;
    }
    if (ruleType === ruleTypes.SHIPPING_DURATION) {
      return `(<${range?.high.toFixed(0)} days)`;
    }
    if (ruleType === ruleTypes.THIRTY_DAYS || ruleType === ruleTypes.TWELVE_MONTHS) {
      return `(<${range?.high.toFixed(0)}%)`;
    }
    if (ruleType === ruleTypes.LIFETIME_REVIEW) {
      return `(>${range?.high.toFixed(0)})`;
    }
    if (ruleType === ruleTypes.DISTANCE) {
      return `(>${range?.high.toFixed(0)} miles)`;
    }
    return null;
  };

  return (
    <>
      {loading && <Loading />}
      {!hide && !loading && offer && rules && referenceAddresses && (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
          <NonScrollable sx={{ pb: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <Box sx={{ display: 'flex', minHeight: '140px', alignItems: 'center' }}>
                  {offer.image && (
                    <Box
                      component="img"
                      src={`${offer.image}`}
                      alt="product"
                      sx={{ borderRadius: '6px', maxWidth: '140px', maxHeight: '140px' }}
                    />
                  )}
                  {!offer.image && <BlankImage style={{ width: '140px', height: '140px' }} />}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    height: '100%',
                    justifyContent: 'center',
                    maxWidth: '70%',
                    minWidth: '70%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                      <Box sx={{ fontSize: '14px', fontWeight: '400', color: 'greys.darkGrey' }}>
                        Health Index
                      </Box>
                      <Box sx={{ fontSize: '12px', fontWeight: '400', color: 'greys.silver' }}>
                        {`LAST UPDATED: ${formatDate(offer.harvestDate)}`}
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
                      {Intl.NumberFormat(region, {
                        style: 'currency',
                        currency: regionCurrency,
                      }).format(offer.price?.toFixed(2))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <ScoreHighlight value={currentScore?.score} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Box sx={{ fontSize: '14px' }}>
                        Buy Box Win Rate: {offer.buyBoxWinPercentage}%
                      </Box>
                      <Box sx={{ fontSize: '14px' }}>
                        {account.fetchInventory && (
                          <Box>
                            Inventory: {offer.stockEstimation !== null && offer.stockEstimation}
                            {offer.stockEstimation === null && 'Pending'}
                          </Box>
                        )}
                        {!account.fetchInventory && (
                          <Box>
                            Inventory:&nbsp;
                            <NavLink to="/settings/plan" style={{ textDecoration: 'underline' }}>
                              Upgrade
                            </NavLink>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <BuildingIcon />
                    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{seller.name}</Box>
                  </Box>
                  <Box sx={{ fontSize: '17px', fontWeight: '700', color: 'greys.darkGrey' }}>
                    {offer.name}
                    {offer.variantTitle && (
                      <Box component="span" sx={{ color: 'greys.grey', fontWeight: '500' }}>
                        {` ${offer.variantTitle}`}
                      </Box>
                    )}
                    <OfferIcons offer={offer} />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      sx={{ borderColor: 'greys.lightGrey' }}
                      variant="outlined"
                      onClick={() => {
                        window.open(`${regionUrl}/dp/${offer.asin}`);
                      }}
                    >
                      View on Amazon
                    </Button>
                    {!isAmazon && (
                      <>
                        <Button
                          sx={{ borderColor: 'greys.lightGrey' }}
                          variant="outlined"
                          onClick={() => {
                            window.open(`${regionUrl}/sp?_encoding=UTF8&seller=${seller.id}`);
                          }}
                        >
                          Visit Seller
                        </Button>
                        {hasAbility('makeTestBuy') && (
                          <Button
                            startIcon={<TestBuyIcon />}
                            onClick={() => {
                              setTestBuyOpen(true);
                            }}
                          >
                            Test Buy
                          </Button>
                        )}
                        <SellerAndOfferMenu
                          button={
                            <Button
                              sx={{
                                borderColor: 'greys.lightGrey',
                                p: 0,
                                maxWidth: '40px',
                                minWidth: '40px',
                              }}
                              variant="outlined"
                            >
                              <MoreHorizIcon />
                            </Button>
                          }
                          seller={seller}
                          offers={[offer]}
                          isOfferProfile
                          sx={{ maxHeight: '40px' }}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Tabs
                sx={{ mt: 3 }}
                color="primary"
                value={tab}
                onChange={(_event, value) => handleTabChange(value)}
              >
                <Tab label="Scoring" />
                <Tab label="Inventory" />
                <Tab label="Win Rate" />
              </Tabs>
            </Box>
          </NonScrollable>
          <Scrollable
            transparent
            sx={{
              mt: 1.5,
              ml: 1,
              mr: 1,
              mb: 1,
            }}
          >
            <TabPanel value={tab} index={0}>
              <Box
                sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
              >
                <Grid container item direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Price"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.PRICE, offer)}&nbsp;
                          {getRangeForAsinString(ruleTypes.PRICE)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Shipping Price"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.SHIPPING_PRICE)}&nbsp;
                          {getRangeForAsinString(ruleTypes.SHIPPING_PRICE)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Shipping Duration"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.SHIPPING_DURATION)}
                          &nbsp;
                          {getRangeForAsinString(ruleTypes.SHIPPING_DURATION)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Negative Reviews (30 days)"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.THIRTY_DAYS)}&nbsp;
                          {getRangeForAsinString(ruleTypes.THIRTY_DAYS)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Negative Reviews (12 months)"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.TWELVE_MONTHS)}&nbsp;
                          {getRangeForAsinString(ruleTypes.TWELVE_MONTHS)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Lifetime Reviews"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.LIFETIME_REVIEW)}&nbsp;
                          {getRangeForAsinString(ruleTypes.LIFETIME_REVIEW)}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HistoryAboutItem
                      title="Near a reference"
                      info={
                        <Box sx={{ display: 'flex' }}>
                          {getValueString(ruleTypes.DISTANCE)}&nbsp;
                          {getRangeForAsinString(ruleTypes.DISTANCE)}
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box
                sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
              >
                <Box sx={{ mb: 2, fontSize: '14px', fontWeight: '600' }}>Inventory History</Box>
                {account.fetchInventory &&
                  stockEstimationHistory &&
                  stockEstimationHistory.length > 0 && (
                    <HistoryItem
                      title="Inventory"
                      data={stockEstimationHistory}
                      dataKey="score"
                      color={theme.palette.primary.main}
                      fixedLength={0}
                    />
                  )}
                {account.fetchInventory &&
                  stockEstimationHistory &&
                  stockEstimationHistory.length === 0 && (
                    <Box sx={{ fontSize: '14px' }}>Inventory history pending</Box>
                  )}
                {account.fetchInventory && !stockEstimationHistory && (
                  <Box sx={{ fontSize: '14px' }}>Inventory history pending</Box>
                )}
                {!account.fetchInventory && (
                  <NavLink
                    to="/settings/plan"
                    style={{ fontSize: '14px', textDecoration: 'underline' }}
                  >
                    Contact sales to get inventory
                  </NavLink>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Box
                sx={{ m: 1, p: 3, bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
              >
                <Box sx={{ mb: 2, fontSize: '14px', fontWeight: '600' }}>Win Rate History</Box>
                {winRateHistory && winRateHistory.length > 0 && (
                  <HistoryItem
                    title="Win Rate"
                    data={winRateHistory}
                    dataKey="score"
                    color={theme.palette.primary.main}
                    fixedLength={0}
                  />
                )}
              </Box>
            </TabPanel>
          </Scrollable>
          {testBuyOpen && (
            <PurchaseTestBuy
              offerId={offer.offerId}
              sellerId={seller.id}
              onClose={() => setTestBuyOpen(false)}
            />
          )}
        </Box>
      )}
    </>
  );
};

OfferContent.propTypes = {
  hide: PropTypes.bool,
  offerId: PropTypes.string.isRequired,
  seller: PropTypes.oneOfType([PropTypes.object]).isRequired,
  asin: PropTypes.string.isRequired,
};

OfferContent.defaultProps = {
  hide: false,
};

export default OfferContent;
