import React, {  } from 'react';
import { S3Image } from 'components';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

const ImageViewer = () => {
  const { id } = useParams();
  return (
    <Box sx={{ m: 3 }}>
      <S3Image filename={id} openInTab />
    </Box>
  );
};

export default ImageViewer;
