import { secureGraphQLFetch } from './fetch-service';

const addToEnforcementQueue = ({ offers }) => {
  const cleanOffers = [];
  for (let index = 0; index < offers.length; index += 1) {
    const offer = offers[index];
    cleanOffers.push(
      `{asin:${JSON.stringify(offer.asin)}, offerId:${JSON.stringify(offer.offerId)}, sellerId:${JSON.stringify(offer.sellerId)}}`
    );
  }
  const query = `mutation {
    addToEnforcementQueue(offers: [${cleanOffers.join(',')}]) 
           {
             id
           }
          }`;
  return secureGraphQLFetch({ query }).then((result) => result?.addToEnforcementQueue);
};

export default {
  addToEnforcementQueue
};
