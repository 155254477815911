import React, { useState } from 'react';
import { Select, FormControl, Box } from '@mui/material';
import { DateRangePicker } from "mui-daterange-picker";
import dayjs from 'dayjs';
import { formatDate } from 'utils/dates';

function DateRangeDropdown({ onChooseRange, ranges = null, initialRange = null, large, border = false, sx }) {
  const [showRange, setShowRange] = useState(false);
  const [range, setRange] = useState(initialRange ?? {});
  const [selectionMade, setSelectionMade] = useState(false);

  const getFontSize = () => {
    if (border) {
      return '14px';
    }
    return large ? '13px' : '12px';
  }

  const getDefaultRanges = () => {
    const date = new Date();
    return [
      {
        label: 'Yesterday',
        startDate: dayjs(date).add(-1, 'day').startOf('day').toDate(),
        endDate: dayjs(date).add(-1, 'day').endOf('day').toDate(),
      },
      {
        label: 'Last Week',
        startDate: dayjs(date).add(-1, 'week').startOf('week').toDate(),
        endDate: dayjs(date).add(-1, 'week').endOf('week').toDate(),
      },
      {
        label: 'This Month',
        startDate: dayjs(date).startOf('month').toDate(),
        endDate: dayjs(date).endOf('month').toDate(),
      },
      {
        label: 'Last Month',
        startDate: dayjs(date).add(-1, 'month').startOf('month').toDate(),
        endDate: dayjs(date).add(-1, 'month').endOf('month').toDate(),
      },
      {
        label: 'Last 7 Days',
        startDate: dayjs(date).add(-6, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 30 Days',
        startDate: dayjs(date).add(-29, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 90 Days',
        startDate: dayjs(date).add(-89, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 180 Days',
        startDate: dayjs(date).add(-179, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
    ]
  };

  const defaultRanges = (() => ranges ?? getDefaultRanges())();
  const [initialLabel, setInitialLabel] = useState(initialRange ? initialRange.label : defaultRanges[4].label); 

  const handleChangeRange = (r) => {
    setRange(r);
    setSelectionMade(true);
  }

  const handleClose = () => {
    setShowRange(false);
    if (selectionMade) {
      if (onChooseRange) {
        const endDate = dayjs(range.endDate).endOf('day').toDate();
        const newRange = {
          startDate: range.startDate,
          endDate,
          label: range.label ?? `${formatDate(range.startDate)} to ${formatDate(endDate)}`
        };
        onChooseRange(newRange);
        setInitialLabel(newRange.label);
        setSelectionMade(false);
      }
    }
  }

  return (
    <Box>
      <FormControl sx={sx}>
        <Select
          onOpen={() => setShowRange(true)}
          open={showRange}
          value={''}
          displayEmpty
          sx={{
            borderStyle: border ? '1px solid #DDE2E4' : 'none',
            p: border ? '2px 14px 2px 14px' : 0,
            m: 0,
            '& .MuiSelect-select': {
              p: border ? '6px 0 6px' : 0,
              m: 0,
            },
          }}
          MenuProps={{
            sx: {
              '&& .Mui-selected': {
                backgroundColor: 'white',
              },
            },
          }}
          renderValue={() => (
            <Box sx={{ fontSize: getFontSize(), fontWeight: border ? 400 : 600 }}>
              {initialLabel}
            </Box>
          )}
        >
          <DateRangePicker
            initialDateRange={initialRange ?? defaultRanges[4]}
            definedRanges={defaultRanges}
            wrapperClassName="DateRangeDropdown"
            open
            toggle={handleClose}
            onChange={handleChangeRange}
          />
        </Select>
      </FormControl>
    </Box>
  );
}

export default DateRangeDropdown;
