import React, {  } from 'react';
import { ReactComponent as StarIcon } from 'assets/star.svg';
import { Tooltip, Box } from '@mui/material';

const SellerAuthorizationState = ({ globalAuth, partialAuth, small = false }) => {

  if (globalAuth) {
    return (
      <Tooltip title="Seller is authorized for all current and new categories that contain the seller's asins.">
        <Box
          sx={{
            color: 'chips.yellow.color',
            minWidth: small ? '16px' : '24px',
            minHeight: small ? '16px' : '24px',
            maxWidth: small ? '16px' : '24px',
            maxHeight: small ? '16px' : '24px',
          }}
        >
          <StarIcon fill="#FEBA0B" style={{ width: small ? '20px' : '24px', height: small ? '20px' : '24px' }} data-cy="sellers_authorized_star" />
        </Box>
      </Tooltip>
    );
  }
  if (!globalAuth && partialAuth) {
    return (
      <Tooltip title="Seller is authorized for at least 1 category that contains the seller's asins or authorized for at least 1 individual asin.">
        <Box
          sx={{
            color: 'chips.purple.color',
            minWidth: small ? '16px' : '24px',
            minHeight: small ? '16px' : '24px',
            maxWidth: small ? '16px' : '24px',
            maxHeight: small ? '16px' : '24px',
          }}
        >
          <StarIcon fill="#881CE0" style={{ width: small ? '20px' : '24px', height: small ? '20px' : '24px' }} data-cy="sellers_authorized_star" />
        </Box>
      </Tooltip>
    );
  }

  return null;
};

export default SellerAuthorizationState;
