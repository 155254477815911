/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';
import axios from 'axios';
import { ReactComponent as DocumentIcon } from 'assets/document.svg';
import Loading from '../Loading/Loading';

const S3Image = ({ sx = null, filename, openInTab = false, downloadFilename = null }) => {
  const theme = useTheme();
  const [url, setUrl] = useState(null);  
  const isImage = filename?.match(/\.(jpg|jpeg|png|gif)$/i);
  const [loading, setLoading] = useState(isImage);

  useEffect(async () => {
    if (filename) {
      const params = { filename };
      if (downloadFilename) {
        params.downloadFilename = encodeURIComponent(downloadFilename);
      }
      try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/file-url`,
          { params, timeout: 60000 }
        );
        setUrl(result.data);
      } catch (err) {
        console.error(err);
        setUrl(null);
        setLoading(false);
      }
    }
  }, [filename]);

  const openImageInNewTab = () => {
    if (openInTab) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`<html><title>${downloadFilename ?? filename}</title><a download="${downloadFilename ?? filename}"><img src="${url}"/></a></html>`);
        newWindow.opener = null;
      }
    }
  }

  const downloadFile = (u) => {
    if (openInTab) {
      const newWindow = window.open(u, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {loading && !url && <Box sx={{ width: '40px' }}><Loading small /></Box>}
      {url && isImage && (
        <Tooltip title={downloadFilename}>
          <img
            alt="user upload"
            style={{ cursor: openInTab && !loading ? 'pointer' : 'default', border: openInTab && !loading ? `1px solid ${theme.colors.linkBlue}` : 'none', ...sx }}
            src={url}
            onClick={() => openImageInNewTab(url)}
            onLoad={() => setLoading(false)}
          />
        </Tooltip>
      )}
      {url && !isImage && (
        <Box
          sx={{ height: '44px', width: '44px', cursor: openInTab && !loading ? 'pointer' : 'default', border: openInTab && !loading ? `1px solid ${theme.colors.linkBlue}` : 'none' }}
          onClick={() => downloadFile(url)}
        >
          <Tooltip title={downloadFilename}>
            <DocumentIcon fill={theme.palette.greys.grey} />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default S3Image;
