import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, Typography, TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { AuthDataContext, ToastDataContext } from 'contexts';
import userService from 'services/user-service';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordComplexityCheck from 'pages/Login/PasswordComplexityCheck';
import { Loading, LoadingButton } from 'components';

const ChangePassword = () => {
  const { user, setUser } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [newPass, setNewPass] = useState('');
  const [currentPass, setCurrentPass] = useState('');
  const [loading, setLoading] = useState(false);

  const getAndLoadUser = async () => {
    const userProfile = await userService.getUser();
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    setUser({ ...user, profile: userProfile, accessToken });
  };

  const handleChangePass = () => {
    setLoading(true);
    getAndLoadUser()
      .then(() => {
        userService
          .changePassword({
            accessToken: user?.accessToken,
            previousPass: JSON.stringify(currentPass),
            newPass: JSON.stringify(newPass),
          })
          .then((result) => {
            if (result === true) {
              setToast({ message: 'Password was successfully changed.' });
            } else {
              setToast({ type: 'error', message: 'Could not change password.' });
            }
          });
      })
      .finally(setLoading(false));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={6}>
          <Grid item xs={12}>
            <Typography variant="h3">Change Password</Typography>
          </Grid>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid item xs={6}>
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      fontSize: '14px',
                      fontWeight: 'normal',
                    },
                  }}
                  type={showCurrentPass ? 'text' : 'password'}
                  disabled={loading}
                  value={currentPass}
                  onChange={(e) => setCurrentPass(e.target.value)}
                  label="Current Password"
                  data-cy="profile_currentpass"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowCurrentPass(!showCurrentPass)}
                          edge="end"
                          size="large"
                          data-cy="profile_showCurrentPassword"
                        >
                          {showCurrentPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      fontSize: '14px',
                      fontWeight: 'normal',
                    },
                  }}
                  type={showNewPass ? 'text' : 'password'}
                  disabled={loading}
                  label="New Password"
                  data-cy="profile_newpass"
                  value={newPass}
                  autoComplete="no"
                  onChange={(e) => setNewPass(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPass(!showNewPass)}
                          edge="end"
                          size="large"
                          data-cy="profile_showNewPassword"
                        >
                          {showNewPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              disabled={loading}
              onClick={handleChangePass}
              data-cy="profile_updatePassword"
            >
              Update Password
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <PasswordComplexityCheck password={newPass} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePassword;
