import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Button, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import AccountChooserDialog from 'pages/Login/AccountChooserDialog';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';
import { Auth } from 'aws-amplify';

const FedLogin = () => {
  const [userAccounts, setUserAccounts] = useState(null);
  const { setUser, tenant, setAccount } = useContext(AuthDataContext);
  const [formError, setFormError] = useState(null);
  const history = useHistory();
  const theme = useTheme();

  const loginWithAccount = async (userAccount) => {
    const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
    localStorage.setItem(accountKey, userAccount.accountId);
    const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: userAccount.accountId });
    const r = localStorage.getItem(regionKey);
    if (!r) {
        localStorage.setItem(regionKey, USA_REGION);
    }
    const userProfile = await userService.getUser();
    await userService.updateUserLoggedIn();
    setUser({ profile: userProfile });
    const acct = await accountService.getAccountById(userAccount.accountId);
    setAccount(acct);

    if (userAccount.tenantPath !== tenant.tenant) {
      window.location.assign(`/${userAccount.tenantPath}/dashboard`);
    } else {
      history.replace('/dashboard');
    }
    setUserAccounts(null);
  }

  const handleAccountChoice = async (accounts) => {
    setUserAccounts(accounts);
  }
  
  const handleCancelChoice = () => {
    setUserAccounts(null);
  }

  useEffect(async () => {
    try {
      const accounts = await userService.getAvailableAccounts({ tenant: tenant.tenant });
      // This is kind of a hack to update the JWT to include the new Stripe customer ID that has been added
      // to the Cognito account, as this gets sent down in the JWT and is used by the back-end for Stripe calls
      const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, async () => {
        if (accounts.length === 0) {
          setFormError('User is not enabled in any accounts, please contact support to be added to an account.');
        } else if (accounts.length === 1) {
          await loginWithAccount(accounts[0]);
        } else {
          await handleAccountChoice(accounts);
        } 
      });
    } catch (err) {
      setFormError('User does not exist in system, please contact support.')
      console.log(err);
    }
  }, []);

  return (
    <Box sx={{ mt: 8, textAlign: 'center' }}>
      {!formError && (<Typography>Logging In...</Typography>)}
      {formError && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography sx={{ ...theme.custom.login.error }}>{formError}</Typography>
          <Box>
            <Button
              onClick={() => history.push('/login')}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      )}
      {userAccounts && (
        <AccountChooserDialog
          userAccounts={userAccounts}
          onCancel={() => handleCancelChoice()}
          onChoose={loginWithAccount}
        />
      )}
    </Box>
  );
};

export default FedLogin;
