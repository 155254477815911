import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ToastDataContext } from 'contexts';
import { DialogContentText, Grid, TextField, Button } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { gql, useMutation } from '@apollo/client';

const AlertEditor = ({
  isOpen,
  setEditorOpen,
  alertRules,
  targetAlert,
  setTargetAlert,
  setAlertRules,
  onClose,
  accountId = null,
  userId = null,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [saving, setSaving] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const { setToast } = useContext(ToastDataContext);

  const UPSERT_ALERT_RULE_MUTATION = gql`
    mutation UpsertAlertRule($alertRule: AlertRuleInput!, $accountId: Int, $userId: Int) {
      upsertAlertRule(alertRule: $alertRule, accountId: $accountId, userId: $userId) {
          id
          alertRuleTypeId
          emailEnabled
          thresholdValue
        }
      }
    `;

  const handleClose = (returnValue = false) => {
    setEditorOpen(false);
    onClose(returnValue);
  };

  const [upsertAlertRuleMutation] = useMutation(UPSERT_ALERT_RULE_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      handleClose(false);
    },
  });

  const handleThresholdChange = (event) => {
    const index = alertRules.map((e) => e.alertRuleTypeId).indexOf(targetAlert.alertRuleTypeId);
    const modifiedRules = JSON.parse(JSON.stringify(alertRules));
    modifiedRules[index].thresholdValue = event.target.value;
    setTargetAlert(modifiedRules[index]);
  };

  const saveTarget = (ev) => {
    ev.preventDefault();
    setSaving(true);
    const index = alertRules.map((e) => e.alertRuleTypeId).indexOf(targetAlert.alertRuleTypeId);
    let modifiedRules = JSON.parse(JSON.stringify(alertRules));
    modifiedRules[index] = targetAlert;
    const changedRule = modifiedRules[index];
    const thresholdValue = parseFloat(changedRule.thresholdValue);
    if (Number.isNaN(thresholdValue)) {
      setToast({ type: 'error', message: 'Invalid threshold value' });
      setSaving(false);
      return;
    }
    upsertAlertRuleMutation({
      variables: {
        accountId,
        userId,
        alertRule: {
          id: changedRule.id || '',
          thresholdValue,
          emailEnabled: changedRule.emailEnabled,
          alertRuleTypeId: changedRule.alertRuleTypeId,
          smsEnabled: false,
          pushEnabled: false,
        },
      },
      onCompleted: (result) => {
        modifiedRules = modifiedRules.map(r => {
          if (r.alertRuleTypeId === result.upsertAlertRule.alertRuleTypeId) {
            return { ...r, id: result.upsertAlertRule.id };
          }
          return r;
        })
        setAlertRules(modifiedRules);
        handleClose(true);
      },
    });
  };

  if (isOpen !== previousOpen) {
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  return (
    <CustomDialog
      title={`Alert: ${targetAlert.alertRuleType}`}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{ '& .MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      maxWidth="md"
      disableBackdropClick
      content={
        <CustomDialogContent>
          <form id="rule-form">
            <DialogContentText component="div" sx={{ paddingBottom: '21px' }}>
              {targetAlert.alertRuleDescription}
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  required
                  type="number"
                  label="Threshold"
                  fullWidth
                  placeholder="Value"
                  value={targetAlert.thresholdValue}
                  onChange={handleThresholdChange}
                />
              </Grid>
            </Grid>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button variant="outlined" disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton form="rule-form" loading={saving} onClick={saveTarget} data-cy="threshold_save">
            Save
          </LoadingButton>
        </>
      }
    />
  );
};

AlertEditor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setEditorOpen: PropTypes.func.isRequired,
  alertRules: PropTypes.arrayOf(PropTypes.object).isRequired,
  targetAlert: PropTypes.object.isRequired,
  setTargetAlert: PropTypes.func.isRequired,
  setAlertRules: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertEditor;
