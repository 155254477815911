import React, { useState, useContext } from 'react';
import {
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  IconButton,
  styled,
  tableCellClasses,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, ProductName } from 'components';
import { gql, useQuery, useMutation } from '@apollo/client';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import InfiniteScroll from 'react-infinite-scroller';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import RemoveIcon from '@mui/icons-material/RemoveCircleRounded';
import { cloneDeep } from 'lodash';

const AttachedRow = ({ asin, showAdd, onAdd, saving }) => {

  const handleAdd = () => {
    onAdd(asin);
  }

  return (
    <TableRow>
      <TableCell width="20%">{asin.id}</TableCell>
      <TableCell width="65%">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              height: '40px',
              width: '40px',
              minHeight: '40px',
              minWidth: '40px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {asin.imageUrl && (
              <Box
                component="img"
                src={`${asin.imageUrl}`}
                alt="product"
                sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
              />
            )}
            {!asin.imageUrl && <BlankImage style={{ width: '40px', height: '40px' }} />}
          </Box>
          <ProductName
            asin={asin.id}
            productName={asin.name}
            sx={{
              display: 'block',
              fontWeight: '500',
              fontSize: '14px',
              color: '#0071DA',
              textDecoration: 'underline',
              lineHeight: '18px',
              maxHeight: '36px',
              overflow: 'hidden',
            }}
          />
        </Box>
      </TableCell>
      <TableCell align="right" width="15%">
        {showAdd && !asin.saving && (
          <IconButton sx={{ minWidth: '34px', maxWidth: '34px' }} onClick={handleAdd} size="small" disabled={saving}>
            <AddIcon sx={{ color: 'success.main', opacity: saving ? 0.35 : 1.0 }} data-cy="approve_asin" />
          </IconButton>
        )}
        {showAdd && asin.saving && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Loading small sx={{ opacity: 0.5, minWidth: '34px', maxWidth: '34px' }} />
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}

const AuthorizedRow = ({ asin, onRemove, saving, showRemove }) => {

  const handleRemove = () => {
    onRemove(asin);
  }

  return (
    <TableRow>
      <TableCell>{asin.id}</TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              height: '40px',
              width: '40px',
              minHeight: '40px',
              minWidth: '40px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {asin.imageUrl && (
              <Box
                component="img"
                src={`${asin.imageUrl}`}
                alt="product"
                sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
              />
            )}
            {!asin.imageUrl && <BlankImage style={{ width: '40px', height: '40px' }} />}
          </Box>
          <ProductName
            asin={asin.id}
            productName={asin.name}
            sx={{
              display: 'block',
              fontWeight: '500',
              fontSize: '14px',
              color: '#0071DA',
              textDecoration: 'underline',
              lineHeight: '18px',
              maxHeight: '36px',
              overflow: 'hidden',
            }}
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        {!asin.saving && showRemove && (
          <IconButton sx={{ minWidth: '34px', maxWidth: '34px' }} onClick={handleRemove} size="small" disabled={saving}>
            <RemoveIcon sx={{ color: 'error.main', opacity: saving ? 0.35 : 1.0 }} data-cy="disapprove_asin" />
          </IconButton>
        )}
        {asin.saving && showRemove && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Loading small sx={{ opacity: 0.5, minWidth: '34px', maxWidth: '34px' }} />
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7FA'
  },
}));

const AuthorizedAsins = ({ seller, onUpdatedAsinAuthorization }) => {
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [attachedAsins, setAttachedAsins] = useState([]);
  const [authorizedAsins, setAuthorizedAsins] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const { hasAbility } = useContext(AuthDataContext);

  const theme = useTheme();

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const authAsins = cloneDeep(dataToProcess.getSellerAuthorizedAsins);
      authAsins.sort((a, b) => a.id.localeCompare(b.id));
      setAuthorizedAsins(authAsins);
      const attachAsins = cloneDeep(dataToProcess.getSellerUnauthorizedAsins);
      attachAsins.sort((a, b) => a.id.localeCompare(b.id));
      setAttachedAsins(attachAsins);
      setLoading(false);
      setSaving(false);
    }
  };

  const SET_AUTH_ASINS_MUTATION = gql`
    mutation SetAuthorizedAsins($sellers: [SellerTypeInput]!) {
      setAuthorizedAsins(sellers: $sellers)
    }
  `;

  const [setAuthorizedAsinsMutation] = useMutation(SET_AUTH_ASINS_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_ASINS_QUERY = gql`
    query GetAsins($id: String!) {
      getSellerAuthorizedAsins(id: $id) {
        id
        name
        imageUrl
      }
      getSellerUnauthorizedAsins(id: $id) {
        id
        name
        imageUrl
      }
    }
  `;

  const { refetch } = useQuery(GET_ASINS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !seller,
    variables: { id: seller.id },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const showAdd = (a) => {
    for (let i = 0; i < authorizedAsins.length; i += 1) {
      if (authorizedAsins[i].id === a.id) {
        return false;
      }
    }
    return true;
  }

  const authorizedByCategories = () => {
    for (let i = 0; i < seller.categories.length; i += 1) {
      if (seller.categories[i].authorized) {
        return true;
      }
    }
    return false;
  }

  const handleAdd = async (asin) => {
    setSaving(true);
    const modifiedAsins = attachedAsins.map(a => ({
      ...a,
      saving: a.id === asin.id ? true : a.saving ?? false,
    }))
    setAttachedAsins(modifiedAsins);
    const selectedAsins = authorizedAsins.map(a => a.id);
    selectedAsins.push(asin.id);
    await setAuthorizedAsinsMutation({
      variables: {
        sellers: [
          {
            asins: selectedAsins,
            sellerId: seller.id,
            authorized: selectedAsins.length > 0,
          },
        ],
      },
      update(cache) {
        cache.modify({
          id: cache.identify(seller),
          fields: {
            authorized() {
              return selectedAsins.length > 0 || authorizedByCategories();
            },
          },
        });
        cache.modify({
          id: `SellerList:${seller.id}`,
          fields: {
            authorized() {
              return selectedAsins.length > 0 || authorizedByCategories();
            },
          },
        });
      },
      onCompleted: () => {
        refetch();
        onUpdatedAsinAuthorization();
      }
    });
  }

  const handleRemove = async (asin) => {
    setSaving(true);
    const modifiedAsins = authorizedAsins.map(a => ({
      ...a,
      saving: a.id === asin.id ? true : a.saving ?? false,
    }))
    setAuthorizedAsins(modifiedAsins);
    const selectedAsins = [];
    for (let i = 0; i < authorizedAsins.length; i += 1) {
      if (authorizedAsins[i].id !== asin.id) {
        selectedAsins.push(authorizedAsins[i].id);
      }
    }
    await setAuthorizedAsinsMutation({
      variables: {
        sellers: [
          {
            asins: selectedAsins,
            sellerId: seller.id,
            authorized: selectedAsins.length > 0,
          },
        ],
      },
      update(cache) {
        cache.modify({
          id: cache.identify(seller),
          fields: {
            authorized() {
              return selectedAsins.length > 0 || authorizedByCategories();
            },
          },
        });
        cache.modify({
          id: `SellerList:${seller.id}`,
          fields: {
            authorized() {
              return selectedAsins.length > 0 || authorizedByCategories();
            },
          },
        });
      },
      onCompleted: () => {
        refetch();
        onUpdatedAsinAuthorization();
      }
    });
  }


  const loadMore = async () => {
    if (limit < attachedAsins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > attachedAsins.length) {
        newLimit = attachedAsins.length;
      }
      setLimit(newLimit);
    }
  };


  return (
    <Box sx={{ overflow: 'hidden', height: '100%' }}>
      {loading && <Loading />}
      {!loading && (
        <Box sx={{ overflow: 'hidden', height: '100%' }}>
          <Box sx={{ fontFamily: theme.typography.fontFamily, fontSize: '14px', mb: 2, mt: 1 }}>
            This area is to choose specific ASINs for this seller to be authorized. To manage whole categories of ASINs to be authorized, please go to the main Sellers page. 
          </Box>
          <Box sx={{width: '100%', display: 'flex', gap: 3, overflow: 'hidden', height: '100%'}}>
            <Box sx={{width: '50%', display: 'flex', flexDirection: 'column', overflow: 'scroll', height: 'calc(100vh - 502px)'}}>
              <Box sx={{ fontFamily: theme.typography.fontFamily, fontSize: '16px', fontWeight: '600', mb: 2, mt: 1, textAlign: 'center' }}>
                Attached but not approved ASINs
              </Box>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore
                loader={limit < attachedAsins.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer sx={{ overflowX: 'initial' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell width="20%">ASIN</StyledTableHeaderCell>
                        <StyledTableHeaderCell width="65%">Product</StyledTableHeaderCell>
                        <StyledTableHeaderCell width="15%" align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody data-cy="asin_offers">
                      {attachedAsins.slice(0, limit).map((asin) => (
                        <AttachedRow key={asin.id} asin={asin} showAdd={showAdd(asin) && hasAbility('asinManagement')} onAdd={handleAdd} saving={saving} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Box>
            <Box sx={{width: '50%', display: 'flex', flexDirection: 'column', overflow: 'scroll', height: 'calc(100vh - 502px)'}}>
              <Box sx={{ fontFamily: theme.typography.fontFamily, fontSize: '16px', fontWeight: '600', mb: 2, mt: 1, textAlign: 'center' }}>
                Authorized ASINs
              </Box>
              <TableContainer sx={{ overflowX: 'initial' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell width="20%">ASIN</StyledTableHeaderCell>
                      <StyledTableHeaderCell width="65%">Product</StyledTableHeaderCell>
                      <StyledTableHeaderCell width="15%" align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy="asin_offers">
                    {authorizedAsins.map((asin) => (
                      <AuthorizedRow key={asin.id} asin={asin} onRemove={handleRemove} saving={saving} showRemove={hasAbility('asinManagement')} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AuthorizedAsins;
