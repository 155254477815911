import React from 'react';
import { Typography, Box, Tooltip, useTheme } from '@mui/material';
// eslint-disable-next-line camelcase
import { ReactComponent as StatUp } from 'assets/stat-up.svg';
import { ReactComponent as StatDown } from 'assets/stat-down.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Loading } from 'components';
import Widget from './Widget';
import WidgetHeader from './WidgetHeader';

const SmallWidget = ({
  loading,
  data,
  currentScore,
  change,
  title,
  isSellerOffer,
  tooltip,
}) => {
  const theme = useTheme();

  return (
    <Widget>
      <WidgetHeader sx={{ pb: 0 }} center title={title} />
      <Box sx={{ minHeight: '55px', maxHeight: '55px' }}>
        {data !== null && !loading && (
          <Tooltip title={tooltip} arrow placement="bottom" sx={{ cursor: 'default' }}>
            <Box
              sx={{
                textAlign: 'center',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                m: '5px',
                pb: 0,
              }}
            >
              <Box sx={{ textAlign: 'center', m: '5px', display: 'flex', gap: 1 }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>{currentScore}</Typography>
                {tooltip && (
                  <Box sx={{ display: 'flex' }}><InfoOutlinedIcon sx={{ fontSize: 12, ml: '-4px' }} /></Box>
                )}
              </Box>
              <Box sx={{ textAlign: 'center', m: '5px' }}>
                <Typography
                  sx={{
                    color: change > 0 ? 'chips.green.color' : 'error.main',
                    fontWeight: 'bold',
                  }}
                >
                  {change > 0 && (
                    <>
                      <StatUp style={{ fill: theme.palette.chips.green.color }} /> +{change}
                      {!isSellerOffer && '%'}
                    </>
                  )}
                  {change < 0 && (
                    <>
                      <StatDown style={{ fill: theme.palette.error.main }} /> -{change * -1}
                      {!isSellerOffer && '%'}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        )}
        {loading && <Loading sx={{ p: 1 }} />}
      </Box>
    </Widget>
  );
};

export default SmallWidget;
