import React from 'react';
import { NavLink, matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, useTheme, Box } from '@mui/material';

function SideNavItem({ location, route, iconNotActive, iconActive, title, subtitle, datacy }) {
  const active = matchPath(location.pathname, route);
  const theme = useTheme();
  return (
    <NavLink
      to={route}
      style={{
        margin: '0px',
        display: 'flex',
        borderRadius: theme.borderRadius,
        alignItems: 'center',
        color: theme.palette.greys.lightGrey,
        transition: 'all 0.2s ease-in',
        padding: theme.spacing(2),
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
        },
      }}
      activeStyle={{
        margin: '0px',
        display: 'flex',
        borderRadius: theme.borderRadius,
        alignItems: 'center',
        color: 'white',
        transition: 'all 0.2s ease-in',
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
        },
      }}
    >
      <Box sx={{ mr: 1, display: 'flex', opacity: active ? 1.0 : 0.6 }}>
        {active ? iconActive : iconNotActive}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '24px', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px' }} data-cy={datacy}>
          {title}
        </Typography>
        {subtitle && (
          <Box sx={{ fontSize: '11px', opacity: 0.65 }} data-cy="asins_manage_asin_count">
            {subtitle}
          </Box>
        )}
      </Box>
    </NavLink>
  );
}

SideNavItem.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  route: PropTypes.string.isRequired,
  iconNotActive: PropTypes.oneOfType([PropTypes.object]).isRequired,
  iconActive: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

export default withRouter(SideNavItem);
