import React from 'react';
import { CustomDialog, CustomDialogContent, AccountChooser } from 'components';

const AccountChooserDialog = ({ onChoose, onCancel, userAccounts }) => {

  const handleChoice = (account) => {
    onChoose(account);
  }

  return (
    <CustomDialog
      title="Choose an Account"
      open
      onClose={() => onCancel()}
      fullWidth
      maxWidth='sm'
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <AccountChooser accounts={userAccounts} loginMode loginOnChoose={handleChoice} />
        </CustomDialogContent>
      }
    />
  );
};

export default AccountChooserDialog;
