import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Drawer,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { Loading, Scrollable } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { CSVLink } from 'react-csv';
import { getCurrentDateTime } from 'utils/dates';
import { formatStringForCSV } from 'utils/strings';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import InsightsAsinRow from './InsightsAsinRow';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const AsinInsightsDrawer = ({ open, insight, onClose }) => {
  const [wide, setwide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [orderBy, setOrderBy] = useState('tag');
  const [sortOrder, setOrder] = useState('desc');
  const [asins, setAsins] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { region } = useContext(AuthDataContext);
  const [exportFilename, setExportFilename] = useState('');
  const [exportData, setExportData] = useState('');
  const theme = useTheme();

  const headCells = [
    { id: 'name', label: 'Name', numeric: false, sortable: true, width: '30%', wide: true },
    {
      id: 'asin',
      label: 'ASIN',
      numeric: false,
      sortable: true,
      width: '20%',
      wide: true,
    },
    {
      id: 'categoryName',
      label: 'Category',
      numeric: false,
      sortable: true,
      width: '20%',
      wide: true,
    },
  ];

  const sortHandler = (headCell, dataToProcess, order, currentOrderBy) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = currentOrderBy === headCell.id && order === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(headCell.id);
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (headCell.numeric) {
        if (!isAsc) {
          return b[headCell.id] - a[headCell.id];
        }
        return a[headCell.id] - b[headCell.id];
      }
      if (!isAsc) {
        return b[headCell.id].localeCompare(a[headCell.id]);
      }
      return a[headCell.id].localeCompare(b[headCell.id]);
    });
    setAsins(sorted);
  };

  const loadMore = () => {
    if (limit < asins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > asins.length) {
        newLimit = asins.length;
      }
      setLimit(newLimit);
    }
  };

  const updateExport = () => {
    if (asins?.length > 0) {
      const csvData = [['Name', 'ASIN', 'ProductLink', 'Category', 'AuthorizedSellers']];
      for (let i = 0; i < asins.length; i += 1) {
        const asin = asins[i];
        csvData.push([
          formatStringForCSV(asin.name),
          asin.asin,
          formatStringForCSV(asin.asinUrl),
          formatStringForCSV(asin.categoryName),
          asin.authorizedSellers ? 'Yes' : '-'
        ]);
      }
      setExportData(csvData);
    }
  };

  const updateExportFilename = () => {
    let filename = `${region}-${insight?.description}`;
    filename = `${filename}-${getCurrentDateTime()}`;
    setExportFilename(`${filename}.csv`);
  };

  useEffect(() => {
    updateExportFilename();
    updateExport();
  }, [asins]);

  const defaultSortColumn = () => ({ cell: headCells[0], order: 'asc' });

  const processData = (dataToProcess) => {
    // Do the initial sorting
    const defaultSort = defaultSortColumn();
    setOrder(defaultSort.order);
    setOrderBy(defaultSort.cell.id);
    const data = dataToProcess.getAsinInsight;
    sortHandler(
      defaultSort.cell,
      data.map((a) => ({
        id: a.asinId,
        ...a,
      })),
      defaultSort.order,
      defaultSort.cell.id,
    );
    setLoading(false);
  };

  const GET_ASIN_INSIGHT_QUERY = gql`
    query GetAsinInsight($type: String!) {
      getAsinInsight(type: $type) {
        asin
        asinId
        asinUrl
        categoryName
        name
        image
        authorizedSellers
      }
    }
  `;
  useQuery(GET_ASIN_INSIGHT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !insight,
    variables: {
      type: insight?.type,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  if (open !== previousOpen) {
    setIsOpen(open);
    setPreviousOpen(open);
    setAsins([]);
    setLoading(true);
  }

  const headerContent = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box>
        <b>Insight:</b> {insight?.description}
      </Box>
    </Box>
  );

  return (
    <Drawer
      data-cy="seller_insights_drawer"
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        width: wide ? wideDrawerWidth : drawerWidth,
        flexShrink: 0,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
        '& .MuiDrawer-paper': {
          width: wide ? wideDrawerWidth : drawerWidth,
          bgcolor: 'greys.white',
          overflow: 'hidden',
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            bgcolor: 'greys.backgroundGrey',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => {
                setwide(!wide);
              }}
              size="large"
            >
              <ExpandWindowIcon
                style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                fill={theme.palette.greys.silver}
              />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
            {!loading && (
              <CSVLink target="_blank" filename={exportFilename} data={exportData}>
                <Button
                  size="small"
                  sx={{ borderColor: 'greys.lightGrey', ml: 2 }}
                  variant="outlined"
                  startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                >
                  Download
                </Button>
              </CSVLink>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              data-cy="seller_insights_drawer_close_button"
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <ClearIcon fill={theme.palette.greys.silver} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            borderRadius: theme.borderRadius,
            bgcolor: 'greys.backgroundGrey',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 44px)',
          }}
        >
          {loading && <Loading />}
          {!loading && (
            <Scrollable transparent sx={{ borderRadius: '0' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={true || false}
                loader={limit < asins.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer sx={{ overflowX: 'initial' }}>
                  <Table data-cy="seller_insights_drawer_table" stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderTop: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderLeft: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderRight: `1px solid ${theme.palette.greys.lighterGrey}`,
                        }}
                      >
                        {headCells
                          .filter((c) => wide || c.wide)
                          .map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              sortDirection={orderBy === headCell.id ? sortOrder : false}
                              width={headCell.width}
                            >
                              <TableSortLabel
                                hideSortIcon={!headCell.sortable}
                                active={headCell.sortable && orderBy === headCell.id}
                                direction={orderBy === headCell.id ? sortOrder : 'asc'}
                                onClick={() => {
                                  sortHandler(
                                    headCell,
                                    asins,
                                    sortOrder === 'asc' ? 'desc' : 'asc',
                                    orderBy,
                                  );
                                }}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {asins.slice(0, limit).map((asin, index) => (
                        <InsightsAsinRow
                          key={asin.id}
                          asin={asin}
                          index={index}
                          insight={insight}
                          compact={!wide}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Scrollable>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

AsinInsightsDrawer.propTypes = {
  insight: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

AsinInsightsDrawer.defaultProps = {
  open: false,
  insight: null,
};

export default AsinInsightsDrawer;
