/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useContext } from 'react';
import {
  Tooltip,
  Box,
  Grid,
  Card,
  CardContent,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Collapse,
  useTheme,
} from '@mui/material';
import { Scrollable, Loading, SellerName, LoadingButton } from 'components';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';
import { useQuery, useMutation, gql } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { styled } from '@mui/material/styles';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import NonTargetIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import TargetIcon from '@mui/icons-material/CrisisAlertRounded';
import { formatDateTimeFilename } from 'utils/dates';
import LostBBSellerDrawer from 'pages/Analytics/LostSales/LostBBTable/LostBBSellerDrawer';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FollowUpIcon from '@mui/icons-material/NotificationsRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import { CSVLink } from 'react-csv';
import { formatStringForCSV } from 'utils/strings';
import BounceLoader from 'react-spinners/BounceLoader';
import Cookies from 'universal-cookie';
import GasGauge from 'pages/Dashboard/GasGauge';
import dayjs from 'dayjs';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';
import EditActionFieldsDialog from 'pages/Common/EditActionFieldsDialog';
import HalfPieChart from './HalfPieChart';

const CardContentNoPadding = styled(CardContent)(`
  padding: 9px 11px 9px 11px;
  &:last-child {
    padding-bottom: 9px;
  }
`);

const ComplianceGroup = ({
  title,
  getColor,
  sellers,
  onTopClicked,
  onShowSeller,
  onShowLostSales,
  onNotesClicked,
}) => {
  const [topChanging, setTopChanging] = useState([]);
  const { account, region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const cookies = new Cookies();
  const cookieName = `BuyBoxWinRateChartSetting.${account.id}.${title}.collapsed`;
  const [open, setOpen] = useState(!cookies.get(cookieName));

  const handleTopClicked = (s) => {
    onTopClicked(s);
    const updatedTopChanging = [...topChanging];
    updatedTopChanging.push(s.id);
    setTopChanging(updatedTopChanging);
  };

  const topStateChanging = (s) => {
    return topChanging.includes(s.id);
  };

  useEffect(() => {
    setTopChanging([]);
  }, [sellers]);

  const handleCollapseClicked = () => {
    setOpen(!open);
    cookies.set(cookieName, open);
  };

  const daysUntilDate = (d) => {
    const nbsp = ' ';
    const date = dayjs(d).startOf('day');
    const now = dayjs().startOf('day');
    const diff = date.diff(now, 'day');
    if (diff === 0) {
      return 'Today';
    }
    if (diff < 0) {
      return 'Overdue';
    }
    return `${diff}${nbsp}day${diff > 1 ? 's' : ''}`;
  };

  return (
    <Box>
      <Box sx={{ fontWeight: 700, mb: '8px', ml: '11px', display: 'flex', gap: 1 }}>
        {title} {sellers.length > 0 ? ` (${sellers.length})` : ''}
        {sellers.length > 0 && (
          <IconButton sx={{ p: 0 }} size="small" variant="outlined" onClick={handleCollapseClicked}>
            {open && (
              <ExpandLessRoundedIcon
                sx={{ color: 'primary.main', maxWidth: '24px', maxHeight: '24px' }}
                data-cy="compliance_arrow_open"
              />
            )}
            {!open && (
              <ExpandMoreRoundedIcon
                sx={{ color: 'primary.main', maxWidth: '24px', maxHeight: '24px' }}
                data-cy="compliance_arrow_closed"
              />
            )}
          </IconButton>
        )}
      </Box>
      {sellers.length === 0 && (
        <Box sx={{ fontSize: '14px', mb: 2, ml: '11px' }}>
          <i>No sellers in this group</i>
        </Box>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          {sellers.map((s) => (
            <Grid item xs={4} key={s.id}>
              <Card variant="outlined" sx={{ bgcolor: getColor(s), boxShadow: 0 }}>
                <CardContentNoPadding>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box
                        type="button"
                        onClick={() => {
                          onShowSeller(s.id);
                        }}
                        sx={{ display: 'flex', alignItems: 'flex-start', gap: '1px' }}
                      >
                        <SellerName
                          sellerName={s.name}
                          sx={{
                            display: 'block',
                            fontWeight: '700',
                            fontSize: '14px',
                            color: 'greys.black',
                            textDecoration: 'underline',
                            lineHeight: '20px',
                            maxHeight: '20px',
                            overflow: 'hidden',
                          }}
                        />
                        <SellerAuthorizationState
                          small
                          globalAuth={s.globalAuth}
                          partialAuth={s.authorized}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {s.followUpDate && (
                          <Tooltip title="A follow-up is set on an enforcement for this seller">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                                fontWeight: '700',
                                color: 'error.main',
                                ml: '4px',
                              }}
                            >
                              <FollowUpIcon
                                sx={{ color: 'error.main', maxHeight: '14px', maxWidth: '14px' }}
                              />
                              &nbsp;{daysUntilDate(s.followUpDate)}
                            </Box>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={(s.notes && s.notes.length > 0) ?
                            (
                              <Box>
                                {s.notes}<br /><br />
                                <Box sx={{ fontStyle: 'italic' }}>Click to edit notes</Box>
                              </Box>
                            ) : <Box sx={{ fontStyle: 'italic' }}>Click to add notes</Box>}
                        >
                          <IconButton
                            sx={{ p: 0 }}
                            size="small"
                            variant="outlined"
                            onClick={() => onNotesClicked(s)}
                          >
                            <TextSnippetRoundedIcon
                              style={{
                                color: 'black',
                                opacity: (s.notes && s.notes.length > 0) ? 1.0 : 0.35,
                                maxWidth: '20px',
                                maxHeight: '20px',
                                minWidth: '20px',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {!topStateChanging(s) && (
                          <Tooltip title="Click to change the Top Target status for this seller">
                            <IconButton
                              sx={{ p: 0 }}
                              size="small"
                              variant="outlined"
                              onClick={() => handleTopClicked(s)}
                            >
                              {s.topTarget && (
                                <TargetIcon
                                  style={{
                                    color: 'greys.black',
                                    opacity: 0.65,
                                    maxWidth: '20px',
                                    maxHeight: '20px',
                                    minWidth: '20px',
                                  }}
                                  data-cy="compliance_toptarget_button"
                                />
                              )}
                              {!s.topTarget && (
                                <NonTargetIcon
                                  style={{
                                    color: 'greys.black',
                                    opacity: 0.65,
                                    maxWidth: '20px',
                                    maxHeight: '20px',
                                    minWidth: '20px',
                                  }}
                                  data-cy="compliance_make_toptarget_button"
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                        {topStateChanging(s) && (
                          <Box
                            sx={{
                              opacity: 0.35,
                              pr: '2px',
                              pl: '2px',
                              display: 'flex',
                              alignItems: 'center',
                              minWidth: '20px',
                            }}
                          >
                            <BounceLoader size={16} color="black" />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{ fontSize: '12px', fontWeight: '500', color: 'greys.black', mt: '6px' }}
                    >
                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
                        <Box>
                          Offer Compliance: <b>{s.compliance}%</b>
                        </Box>
                        <Box>
                          {s.winRate === undefined && (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              7-Day Win Rate:{' '}
                              <Box sx={{ opacity: 0.35 }}>
                                <Loading small />
                              </Box>
                            </Box>
                          )}
                          {s.winRate !== undefined && (
                            <Box>
                              7-Day Win Rate: <b>{s.winRate ?? '0'}%</b>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
                        <Box>
                          Total Actions: <b>{s.totalActions}</b>
                        </Box>
                        <Box>
                          <b>{s.totalOffers - s.attachedOffers}</b> out of <b>{s.totalOffers}</b>{' '}
                          offers removed
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
                        <Box>
                          {s.lostSales === undefined && (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              7-Day Est. Sales Taken:{' '}
                              <Box sx={{ opacity: 0.35 }}>
                                <Loading small />
                              </Box>
                            </Box>
                          )}
                          {s.lostSales !== undefined && (
                            <Box>
                              {s.lostSales !== 0 && (
                                <Box sx={{ display: 'flex', gap: '4px' }}>
                                  7-Day Est. Sales Taken:
                                  <Box
                                    type="button"
                                    onClick={() => {
                                      onShowLostSales(s);
                                    }}
                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  >
                                    <b>
                                      {Intl.NumberFormat(region, {
                                        style: 'currency',
                                        currency: regionCurrency,
                                        maximumFractionDigits: 0,
                                      }).format(s.lostSales)}
                                    </b>
                                  </Box>
                                </Box>
                              )}
                              {s.lostSales === 0 && (
                                <Box>
                                  7-Day Est. Sales Taken:{' '}
                                  <b>
                                    {Intl.NumberFormat(region, {
                                      style: 'currency',
                                      currency: regionCurrency,
                                      maximumFractionDigits: 0,
                                    }).format(0)}
                                  </b>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                        <Box>
                          {s.lifetimeLostSales === undefined && (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              Lifetime Taken:{' '}
                              <Box sx={{ opacity: 0.35 }}>
                                <Loading small />
                              </Box>
                            </Box>
                          )}
                          {s.lifetimeLostSales !== undefined && (
                            <Box>
                              {s.lifetimeLostSales !== 0 && (
                                <Box sx={{ display: 'flex', gap: '4px' }}>
                                  Lifetime Taken:
                                  <Box>
                                    <b>
                                      {Intl.NumberFormat(region, {
                                        style: 'currency',
                                        currency: regionCurrency,
                                        maximumFractionDigits: 0,
                                      }).format(s.lifetimeLostSales)}
                                    </b>
                                  </Box>
                                </Box>
                              )}
                              {s.lifetimeLostSales === 0 && (
                                <Box>
                                  Lifetime Taken:{' '}
                                  <b>
                                    {Intl.NumberFormat(region, {
                                      style: 'currency',
                                      currency: regionCurrency,
                                      maximumFractionDigits: 0,
                                    }).format(0)}
                                  </b>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContentNoPadding>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

const SellerCompliance = () => {
  const [loading, setLoading] = useState(true);
  const [loadingSales, setLoadingSales] = useState(true);
  const [loadingLifetimeSales, setLoadingLifetimeSales] = useState(true);
  const [loadingWinRate, setLoadingWinRate] = useState(true);
  const [sellers, setSellers] = useState([]);
  const [showSellerDrawer, setShowSellerDrawer] = useState(null);
  const [showNotes, setShowNotes] = useState(null);
  const [lostSales, setLostSales] = useState([]);
  const [lifetimeLostSales, setLifetimeLostSales] = useState([]);
  const [winRates, setWinRates] = useState([]);
  const [topTargetSellers, setTopTargetSellers] = useState([]);
  const [lowComplianceSellers, setLowComplianceSellers] = useState([]);
  const [mediumComplianceSellers, setMediumComplianceSellers] = useState([]);
  const [highComplianceSellers, setHighComplianceSellers] = useState([]);
  const [totalOffersActioned, setTotalOffersActions] = useState(null);
  const [overallCompliance, setOverallCompliance] = useState(null);
  const [pieChartData, setPieChartData] = useState([]);
  const [inCompliance, setInComplaince] = useState(null);
  const [sellerIdToShow, setSellerIdToShow] = useState(null);
  const [search, setSearch] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const { account, region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();

  const highCompliance = 75;
  const mediumCompliance = 25;

  const highComplianceColor = '#ebfceb';
  const mediumComplianceColor = '#fcf2d7';
  const lowComplianceColor = '#fce1e1';

  const getComplianceBackgroundColor = (s) => {
    if (s.compliance >= highCompliance) {
      return highComplianceColor;
    }
    if (s.compliance >= mediumCompliance) {
      return mediumComplianceColor;
    }
    return lowComplianceColor;
  };

  const getDateRanges = () => {
    const date = new Date();
    const dateStart = dayjs(date).add(-6, 'day').startOf('day').toISOString();
    const dateEnd = dayjs(date).endOf('day').toISOString();
    return { dateStart, dateEnd };
  };

  const { dateStart, dateEnd } = getDateRanges();

  const processLifetimeLostSalesData = (dataToProcess) => {
    if (dataToProcess) {
      const lostSalesData = dataToProcess.getTopLostBuyBox;
      setLifetimeLostSales(lostSalesData);
      setLoadingLifetimeSales(false);
    }
  };

  const processLostSalesData = (dataToProcess) => {
    if (dataToProcess) {
      const lostSalesData = dataToProcess.getTopLostBuyBox;
      setLostSales(lostSalesData);
      setLoadingSales(false);
    }
  };

  const processWinRateData = (dataToProcess) => {
    if (dataToProcess) {
      const winRateData = dataToProcess.getWinRateAverageForSellers;
      setWinRates(winRateData);
      setLoadingWinRate(false);
    }
  };

  const GET_LOST_SALES_QUERY = gql`
    query GetLostSales($dateStart: DateTime, $dateEnd: DateTime) {
      getTopLostBuyBox(dateStart: $dateStart, dateEnd: $dateEnd) {
        sellerId
        contestedLost
      }
    }
  `;

  useQuery(GET_LOST_SALES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { dateStart, dateEnd },
    onCompleted: processLostSalesData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_WIN_RATE_QUERY = gql`
    query GetExtraInfo($dateStart: DateTime, $dateEnd: DateTime) {
      getWinRateAverageForSellers(dateStart: $dateStart, dateEnd: $dateEnd) {
        sellerId
        winRate
      }
    }
  `;

  useQuery(GET_WIN_RATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { dateStart, dateEnd },
    onCompleted: processWinRateData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_LIFETIME_LOST_SALES_QUERY = gql`
    query GetLostSales {
      getTopLostBuyBox {
        sellerId
        contestedLost
      }
    }
  `;

  useQuery(GET_LIFETIME_LOST_SALES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { dateStart, dateEnd },
    onCompleted: processLifetimeLostSalesData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });


  const processSellerData = (dataToProcess) => {
    if (dataToProcess) {
      const sellerData = cloneDeep(dataToProcess.getSellerCompliance);
      sellerData.sort((a, b) => {
        if (a.compliance === b.compliance) {
          return a.name.localeCompare(b.name);
        }
        return a.compliance - b.compliance;
      });
      let inComplianceSellers = 0;
      let inComplianceOffers = 0;
      let offerCount = 0;
      for (let i = 0; i < sellerData.length; i += 1) {
        const seller = sellerData[i];
        offerCount += seller.totalOffers;
        inComplianceOffers += seller.totalOffers - seller.attachedOffers;
        if (seller.compliance === 100) {
          inComplianceSellers += 1;
        }
      }
      setTotalOffersActions(offerCount);
      setInComplaince({
        sellers: inComplianceSellers,
        offers: inComplianceOffers,
      });
      if (offerCount > 0) {
        setOverallCompliance(Math.round((inComplianceOffers / offerCount) * 100));
      } else {
        setOverallCompliance(100);
      }

      setSellers(sellerData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sellers.length > 0) {
      const modifiedSellers = sellers.filter((s) => {
        if (search === null) {
          return true;
        }
        const tokens = search?.toLowerCase().split(' ');
        let tokensFound = 0;
        for (let i = 0; i < tokens.length; i += 1) {
          const token = tokens[i];
          if (s.id?.toLowerCase().includes(token) || s.name?.toLowerCase().includes(token)) {
            tokensFound += 1;
          }
        }
        return tokensFound === (tokens ? tokens?.length : 0);
      });
      for (let j = 0; j < modifiedSellers.length; j += 1) {
        const seller = modifiedSellers[j];
        if (lifetimeLostSales.length) {
          seller.lifetimeLostSales = 0;
          for (let i = 0; i < lifetimeLostSales.length; i += 1) {
            const sale = lifetimeLostSales[i];
            if (sale.sellerId === seller.id) {
              seller.lifetimeLostSales = sale.contestedLost;
              break;
            }
          }
        }
        if (lostSales.length) {
          seller.lostSales = 0;
          for (let i = 0; i < lostSales.length; i += 1) {
            const sale = lostSales[i];
            if (sale.sellerId === seller.id) {
              seller.lostSales = sale.contestedLost;
              break;
            }
          }
        }
        if (winRates.length) {
          seller.winRate = 0;
          for (let i = 0; i < winRates.length; i += 1) {
            const winRate = winRates[i];
            if (winRate.sellerId === seller.id) {
              seller.winRate = winRate.winRate;
              break;
            }
          }
        }
      }
      const top = [];
      const barely = [];
      const partially = [];
      const mostly = [];
      const pieChartTop = [];
      const pieChartMid = [];
      const pieChartLow = [];
      for (let i = 0; i < modifiedSellers.length; i += 1) {
        const seller = modifiedSellers[i];

        if (seller.topTarget) {
          top.push(seller);
        } else if (seller.compliance >= highCompliance) {
          mostly.push(seller);
        } else if (seller.compliance >= mediumCompliance) {
          partially.push(seller);
        } else {
          barely.push(seller);
        }

        if (seller.compliance >= highCompliance) {
          pieChartTop.push(seller);
        } else if (seller.compliance >= mediumCompliance) {
          pieChartMid.push(seller);
        } else {
          pieChartLow.push(seller);
        }
      }
      setTopTargetSellers(top);
      setLowComplianceSellers(barely);
      setMediumComplianceSellers(partially);
      setHighComplianceSellers(mostly);
      const chartData = [];
      if (pieChartLow.length) {
        chartData.push({
          label: 'Low Compliance',
          amount: pieChartLow.length,
          color: lowComplianceColor,
        });
      }
      if (pieChartMid.length) {
        chartData.push({
          label: 'Medium Compliance',
          amount: pieChartMid.length,
          color: mediumComplianceColor,
        });
      }
      if (pieChartTop.length) {
        chartData.push({
          label: 'High Compliance',
          amount: pieChartTop.length,
          color: highComplianceColor,
        });
      }
      setPieChartData(chartData);
    }
  }, [sellers, lostSales, lifetimeLostSales, winRates, search]);

  const GET_COMPLIANCE_QUERY = gql`
    query GetSellerCompliance {
      getSellerCompliance {
        id
        name
        businessName
        businessAddress
        sellerUrl
        topTarget
        totalActions
        totalOffers
        attachedOffers
        compliance
        authorized
        globalAuth
        followUpDate
        notes
      }
    }
  `;

  const { refetch } = useQuery(GET_COMPLIANCE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processSellerData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_SELLER_MUTATION = gql`
    mutation UpdateSeller($sellerId: String!, $topTarget: Boolean) {
      updateSeller(sellerId: $sellerId, topTarget: $topTarget) {
        id
      }
    }
  `;

  const [updateSeller] = useMutation(UPDATE_SELLER_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleTopClicked = (s) => {
    updateSeller({
      variables: {
        sellerId: s.id,
        topTarget: !s.topTarget,
      },
      onCompleted: refetch,
    });
  };

  const onShowLostSales = (s) => {
    const seller = {
      sellerId: s.id,
      sellerName: s.name,
    };
    setShowSellerDrawer(seller);
  };
  
  const onNotesClicked = (s) => {
    setShowNotes(s);
  }

  const getArrayForGroup = (title, data) => {
    const results = [];
    if (data) {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];
        const removedOffers = item.totalOffers - item.attachedOffers;
        results.push([
          title,
          item.id,
          formatStringForCSV(item.name),
          formatStringForCSV(item.sellerUrl),
          formatStringForCSV(item.businessName),
          formatStringForCSV(item.businessAddress),
          `${item.compliance}%`,
          item.totalActions,
          item.totalOffers,
          removedOffers,
          `${Intl.NumberFormat(region, {
            style: 'currency',
            currency: regionCurrency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.lostSales)}`,
          `${Intl.NumberFormat(region, {
            style: 'currency',
            currency: regionCurrency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.lifetimeLostSales)}`,
          `${item.winRate}%`,
          formatStringForCSV(item.notes),
        ]);
      }
    }
    return results;
  };

  const exportData = () => {
    let csvData = [
      [
        'Compliance Group',
        'Seller ID',
        'Seller Name',
        'SellerLink', 
        'BusinessName', 
        'BusinessAddress',
        'Compliance',
        'Total Actions',
        'Total Offers',
        'Offers Removed',
        '7-Day Estimated Sales Taken',
        'Lifetime Estimated Sales Taken',
        '7-Day Win Rate',
        'Notes'
      ],
    ];
    csvData = csvData.concat(getArrayForGroup('Top Targets', topTargetSellers));
    csvData = csvData.concat(getArrayForGroup('Low Compliance', lowComplianceSellers));
    csvData = csvData.concat(getArrayForGroup('Medium Compliance', mediumComplianceSellers));
    csvData = csvData.concat(getArrayForGroup('High Compliance', highComplianceSellers));
    return csvData;
  };

  return (
    <Scrollable transparent sx={{ height: '100%' }}>
      {loading && <Loading />}
      {!loading && (
        <Box sx={{ mx: 0, mt: 1, mb: 1 }}>
          <Grid container>
            <Grid item xs={4} data-cy="offer_compliance_gauge">
              <GasGauge
                title="Offer Compliance"
                value={overallCompliance}
                min={0}
                minLabel="0"
                maxLabel={`${totalOffersActioned.toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}`}
                labelFontSize="12px"
                width="256px"
                max={100}
                percentage
              />
            </Grid>
            <Grid item xs={4} data-cy="compliant_sellers_guage">
              <GasGauge
                title="Compliant Sellers"
                value={inCompliance.sellers}
                min={0}
                minLabel="0"
                maxLabel={`${sellers.length.toLocaleString('en-US', { maximumFractionDigits: 0 })}`}
                labelFontSize="12px"
                max={sellers.length}
              />
            </Grid>
            <Grid item xs={4} data-cy="compliance_piechart">
              <HalfPieChart title="Compliance Distribution" data={pieChartData} />
            </Grid>
          </Grid>
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            bgcolor: 'greys.white',
            p: 2,
            borderRadius: theme.largeBorderRadius,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pl: 1,
              pr: 0,
              mb: 0,
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '38px',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'stretch' }}>
              <TextField
                onChange={(event) => setSearch(event.target.value)}
                multiline
                placeholder="Search"
                sx={{ width: '26ch', maxHeight: '37px' }}
                value={search || ''}
                data-cy="compliance_search"
                InputProps={{
                  sx: { pl: 1, minHeight: '100%', height: '100%' },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fill={theme.palette.greys.silver} />
                    </InputAdornment>
                  ),
                  endAdornment: search ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearch('');
                        }}
                        size="large"
                        data-cy="compliance_search_clear"
                      >
                        <ClearIcon fill={theme.palette.greys.silver} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  ),
                }}
              />
              <Box sx={{ opacity: loadingLifetimeSales || loadingSales || loadingWinRate ? 0.5 : 1 }}>
                {(loadingSales || loadingWinRate) && (
                  <LoadingButton
                    loading={loadingSales || loadingWinRate || loadingLifetimeSales}
                    sx={{ borderColor: 'greys.lightGrey' }}
                    variant="outlined"
                    startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                  >
                    Download
                  </LoadingButton>
                )}
                {!(loadingSales || loadingWinRate) && (
                  <CSVLink
                    target="_blank"
                    filename={`${region} - ${
                      account.name ?? 'Report'
                    } - SellerCompliance - ${formatDateTimeFilename(new Date())}.csv`}
                    data={exportData()}
                  >
                    <Button
                      sx={{ borderColor: 'greys.lightGrey' }}
                      variant="outlined"
                      startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                      data-cy="compliance_download"
                    >
                      Download
                    </Button>
                  </CSVLink>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <ComplianceGroup
              title="Your Top Targets"
              sellers={topTargetSellers}
              getColor={getComplianceBackgroundColor}
              onTopClicked={handleTopClicked}
              onShowSeller={setSellerIdToShow}
              onShowLostSales={onShowLostSales}
              onNotesClicked={onNotesClicked}
            />
            <ComplianceGroup
              title="Low Compliance"
              sellers={lowComplianceSellers}
              getColor={getComplianceBackgroundColor}
              onTopClicked={handleTopClicked}
              onShowSeller={setSellerIdToShow}
              onShowLostSales={onShowLostSales}
              onNotesClicked={onNotesClicked}
            />
            <ComplianceGroup
              title="Medium Compliance"
              sellers={mediumComplianceSellers}
              getColor={getComplianceBackgroundColor}
              onTopClicked={handleTopClicked}
              onShowSeller={setSellerIdToShow}
              onShowLostSales={onShowLostSales}
              onNotesClicked={onNotesClicked}
            />
            <ComplianceGroup
              title="High Compliance"
              sellers={highComplianceSellers}
              getColor={getComplianceBackgroundColor}
              onTopClicked={handleTopClicked}
              onShowSeller={setSellerIdToShow}
              onShowLostSales={onShowLostSales}
              onNotesClicked={onNotesClicked}
            />
          </Box>
        </Box>
      )}
      {showNotes && (
        <EditActionFieldsDialog
          actionId={showNotes.id}
          onClose={() => {
            setShowNotes(null);
            refetch();
          }}
          areas={['sellerCompliance']}
        />
      )}
      <SellerDrawer
        id={sellerIdToShow}
        open={sellerIdToShow !== null}
        onClose={() => {
          refetch();
          setSellerIdToShow(null);
        }}
      />
      <LostBBSellerDrawer
        seller={showSellerDrawer}
        open={!!showSellerDrawer}
        onClose={() => setShowSellerDrawer(null)}
        dateStart={dateStart}
        dateEnd={dateEnd}
        rangeLabel="Last 7 Days"
      />
    </Scrollable>
  );
};

export default SellerCompliance;
