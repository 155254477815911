import React, { useState, useContext } from 'react';
import { Button, Box, TextField } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { useMutation, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';

const AddPermittedDomain = ({ onCancel, onAdded, tenantId }) => {
  const [domain, setDomain] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastDataContext);

  const ADD_PERMITTED_DOMAIN_MUTATION = gql`
    mutation AddPermittedDomain($tenantId: ID!, $domain: String!) {
      addPermittedDomain(tenantId: $tenantId, domain: $domain)
    }
  `;

  const [addDomain] = useMutation(ADD_PERMITTED_DOMAIN_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  // Validation
  const validateForm = () => {
    const errors = [];
    if (domain && /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain) !== true) {
      errors.push({ domain: 'Invalid Domain' });
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    // // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      setLoading(true);
      addDomain({
        variables: {
          tenantId,
          domain,
        },
        onCompleted: () => {
          setToast({ type: 'success', message: 'Domain Added' });
          setLoading(false);
          onAdded();
        },
      });
    }
  };


  return (
    <CustomDialog
      title="Add Permitted Domain"
      open
      onClose={() => onCancel()}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <form id="permitted-domain" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <TextField
                fullWidth
                required
                variant="standard"
                placeholder="Enter Domain"
                label="Domain"
                data-cy="edit_domain"
                value={domain || ''}
                error={Boolean(formErrors.domain)}
                helperText={formErrors.domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
              />
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button
            disabled={loading}
            variant="outlined"
            onClick={() => onCancel()}
            data-cy="edit_asin_cancel"
          >
            Cancel
          </Button>
          <LoadingButton
            form="permitted-domain"
            loading={loading}
            disabled={loading}
            variant="contained"
            type="submit"
            data-cy="permitted_domain_add"
          >
            Add
          </LoadingButton>
        </>
      }
    />
  );
};

export default AddPermittedDomain;
