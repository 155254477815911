import React, { useState } from 'react';
import { Checkbox, Link, Button, Typography, FormControlLabel, Box, useTheme } from '@mui/material';
import { CustomDialog, CustomDialogContent } from 'components';
import ipsecureTerms from './terms.html';

const PrivacyTerms = ({ termsAgreed, setTermsAgreed }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const template = { __html: theme.terms ? theme.terms : ipsecureTerms };

  const onCheck = (e) => {
    setTermsAgreed(e.target.checked);
  };

  const onLinkClick = (e) => {
    e.preventDefault();
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          fontFamily: 'CircularStd',
          fontWeight: '400',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          sx={{ color: 'greys.darkGrey', mr: '5px' }}
          control={<Checkbox checked={termsAgreed} onChange={onCheck} />}
          label="I agree to IPSecure's"
          data-cy="terms_checkbox"
        />
        <Link sx={{ fontSize: '14px' }} href="#" onClick={onLinkClick} underline="hover">
          {`Privacy Policy & Terms of Use`}
        </Link>
      </Box>
      <CustomDialog
        onClose={handleClose}
        open={dialogOpen}
        title="Privacy Policy &amp; Terms of Use"
        content={
          <CustomDialogContent>
            <Typography component="div">
              { /* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={template} />
            </Typography>
          </CustomDialogContent>
        }
        actions={<Button onClick={handleClose}>OK</Button>}
      />
    </>
  );
};

export default PrivacyTerms;
