import React, { useState, useContext, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Tooltip,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import {
  ConfirmationDialog,
  ProductName,
  SellerName,
  Chip,
  NonScrollable,
  Loading,
} from 'components';
import { ToastDataContext } from 'contexts';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { ReactComponent as EmptyPage } from 'assets/empty-page.svg';
import { formatDate } from 'utils/dates';
import { useMutation, useQuery, gql } from '@apollo/client';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';
import EditActionFieldsDialog from 'pages/Common/EditActionFieldsDialog';

const EnforcementQueue = () => {
  const [enforcements, setEnforcements] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deletingActionItem, setDeletingActionItem] = useState(false);
  const [deleteConfirmationItemId, setDeleteConfirmationItemId] = useState(null);
  const [showEditId, setShowEditId] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const [sellerProfileOpen, setSellerProfileOpen] = useState(false);
  const [sellerProfile, setSellerProfile] = useState(null);
  const theme = useTheme();

  const REMOVE_ENFORCEMENT_MUTATION = gql`
    mutation RemoveAction($id: ID!) {
      removeEnforcementQueueItem(id: $id)
    }
  `;
  const [removeEnforcementMutation] = useMutation(REMOVE_ENFORCEMENT_MUTATION, {});

  const removeItem = async (id) => {
    setDeletingActionItem(true);
    removeEnforcementMutation({
      variables: { id },
      onCompleted: () => {
        setDeletingActionItem(false);
        setDeleteConfirmationItemId(null);
      },
      onError: (e) => {
        setDeletingActionItem(false);
        setDeleteConfirmationItemId(null);
        setToast({ type: 'error', message: e.message });
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'EnforcementQueueItem' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const GET_ENFORCEMENT_ITEMS_QUERY = gql`
    query GetActions {
      getActions {
        id
        description
      }
      getEnforcementQueueItems {
        id
        createdDate
        notes
        offers {
          id
          sellerId
          asin
          imageUrl
          offerId
          sellerName
          sellerAuthorized
          name
          price
          mapPrice
          categoryId
          globalAuth
          harvestDate
          regionId
          attached
          category {
            id
            name
          }
          currentScore {
            score
            harvestDate
          }
          category {
            name
          }
        }
        actions {
          id
          letterType
          actionTaken
          status
          statusExtra
          tracking
          description
          createdDate
          updatedDate
          userName
          userEmail
          fields {
            id
            idString
            customFieldId
            customValueId
            name
            description
            type
            area
            stringOptions
            valueInt
            valueString
            valueDate
            valueStrings
            valueAddress {
              addressLine1
              addressLine2
              city
              state
              zip
            }
            multiple
            timestamp
            extraTextLabel
            extraText
            userEmail
            userFirstName
            userLastName
            updatedDate
            createdDate
            s3filename
          }
        }
      }
    }
  `;

  const { data, refetch } = useQuery(GET_ENFORCEMENT_ITEMS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processData = (dataToProcess) => {
    const pending = dataToProcess.getEnforcementQueueItems.filter(
      (e) => e.actions.length === 0 && e.offers.length > 0,
    );
    setEnforcements(pending);
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const handleOnEditFields = (id) => {
    setShowEditId(parseInt(id, 10));
  }

  return (
    <NonScrollable
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        pt: 0, height: '100%',
      }}
    >
      <Box sx={{ overflow: 'scroll', height: 'calc(100vh - 148px)' }}>
        {loading && <Loading />}
        {!loading && enforcements.length === 0 && (
          <Box sx={{ textAlign: 'center' }}>
            <EmptyPage />
            <Typography variant="h4" sx={{ color: 'greys.grey' }}>
              No Pending or Sent Actions
            </Typography>
            <Box component="p" sx={{ color: 'greys.grey' }}>
              To queue low-scoring sellers or create actions on low-scoring sellers, please go to
              the Dashboard.
            </Box>
          </Box>
        )}
        {!loading && enforcements.length > 0 && (
          <TableContainer sx={{ overflowX: 'initial' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right" width="5%">
                    Score
                  </TableCell>
                  <TableCell align="left" width="15%">
                    <Box style={{ display: 'flex' }}>Seller Name</Box>
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Product
                  </TableCell>
                  <TableCell align="left" width="15%">
                    Category
                  </TableCell>
                  <TableCell align="left" width="8%">
                    Added
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Notes
                  </TableCell>
                  <TableCell align="right" width="5%">
                    {' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enforcements.map((enforcement) => (
                  <TableRow key={enforcement.id}>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {enforcement.offers[0].currentScore
                          ? enforcement.offers[0].currentScore.score
                          : 'Pending...'}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          type="button"
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            setSellerProfile({
                              sellerId: enforcement.offers[0].sellerId,
                              sellerName: enforcement.offers[0].sellerName,
                            });
                            setSellerProfileOpen(true);
                          }}
                        >
                          <SellerName
                            sellerName={enforcement.offers[0].sellerName}
                            sx={{
                              display: 'block',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#0071DA',
                              textDecoration: 'underline',
                              lineHeight: '18px',
                              maxHeight: '36px',
                              overflow: 'hidden',
                            }}
                          />
                          <SellerAuthorizationState globalAuth={enforcement.offers[0].globalAuth} partialAuth={enforcement.offers[0].sellerAuthorized} />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: '35%' }}>
                      <Tooltip
                        title={`ASIN: ${enforcement.offers[0].asin}`}
                        arrow
                        placement="bottom"
                        style={{ cursor: 'default' }}
                      >
                        <Box>
                          <ProductName
                            asin={enforcement.offers[0].asin}
                            productName={enforcement.offers[0].name}
                            sx={{
                              display: 'block',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#0071DA',
                              textDecoration: 'underline',
                              lineHeight: '18px',
                              maxHeight: '36px',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      {enforcement.offers[0].category && (
                        <Chip
                          label={enforcement.offers[0].category?.name}
                          backgroundColor={theme.palette.chips.purple.backgroundColor}
                          color={theme.palette.chips.purple.color}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">{formatDate(enforcement.createdDate)}</TableCell>
                    <TableCell align="left" sx={{ fontSize: '12px' }}>
                      {enforcement.notes}
                    </TableCell>
                    <TableCell align="right">
                      <SellerAndOfferMenu
                        enforcement={enforcement}
                        offers={enforcement.offers}
                        onDelete={(a) => {
                          setDeleteConfirmationItemId(a.id);
                        }}
                        isActionQueue
                        onActionTaken={refetch}
                        onEditFields={handleOnEditFields}
                        editFieldId={enforcement.id}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {deleteConfirmationItemId !== null && (
          <ConfirmationDialog
            open={deleteConfirmationItemId !== null}
            title="Delete action item"
            message="Are you sure you wish to delete this Action Item?"
            busy={deletingActionItem}
            okTitle="Delete"
            destructive
            onClose={(confirmed) => {
              if (confirmed) {
                removeItem(deleteConfirmationItemId);
              } else {
                setDeleteConfirmationItemId(null);
              }
            }}
          />
        )}
        {showEditId && (
          <EditActionFieldsDialog
            actionId={showEditId}
            onClose={() => {
              setShowEditId(false);
              refetch();
            }}
            areas={['enforcementQueue']}
          />
        )}
        <SellerDrawer
          id={sellerProfile?.sellerId}
          open={sellerProfileOpen}
          onClose={() => setSellerProfileOpen(false)}
        />
      </Box>
    </NonScrollable>
  );
};

export default EnforcementQueue;
