import { secureGraphQLFetch } from './fetch-service';

const createAmazonOrder = (asin, quantity, sellerId, shippingAddressId, offerId, enforcementId, privateRecipient) => {
  const unquoted = JSON.stringify({
    asin,
    quantity,
    sellerId,
    shippingAddressId,
    offerId,
    enforcementId,
    privateRecipient
  }).replace(/"([^"]+)":/g, '$1:');

  const query = `mutation {
    createAmazonOrder(orderInput: ${unquoted}) 
  }`;
  return secureGraphQLFetch({ query }).then((result) => result);
};

export default {
  createAmazonOrder
};
