import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, useTheme } from '@mui/material';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ReactComponent as StatUp } from 'assets/stat-up.svg';
import { ReactComponent as StatDown } from 'assets/stat-down.svg';

am5.addLicense('AM5C329389658');

function HealthDonut({ title, value, change, percentageChange, good, bad, ugly }) {
  const seriesRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {

    const root = am5.Root.new(title);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        panX: false,
        panY: false,
        startAngle: -200,
        endAngle: 20,
        height: 98,
        width: 144,
        paddingTop: 0,
        paddingBottom: 0,
        innerRadius: am5.percent(85),
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "count",
        categoryField: "rating"
      })
    );

    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    series.slices.template.states.create("hover", {
      scale: 1,
    });
    series.slices.template.states.create("active", {
      shiftRadius: 0,
    });
    series.slices.template.setAll({
      templateField: "sliceSettings"
    });

    seriesRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    const data = [];
    if (good > 0) {
      data.push({
        rating: 'Good',
        count: good,
        sliceSettings: {
          fill: am5.color(0x02B80A),
          stroke: am5.color(0x02B80A),
        }
      });
    }
    if (bad > 0) {
      data.push({
        rating: 'Marginal',
        count: bad,
        sliceSettings: {
          fill: am5.color(0xFEBA0B),
          stroke: am5.color(0xFEBA0B)
        }
      });
    }
    if (ugly > 0) {
      data.push({
        rating: 'Bad',
        count: ugly,
        sliceSettings: {
          fill: am5.color(0xFF2F62),
          stroke: am5.color(0xFF2F62)
        }
      });
    }
    seriesRef.current?.data.setAll(data);
  }, [good, bad, ugly]);

  return (
    <Box sx={{ display: 'grid', justifyContent: 'center' }}>
      <Box
        sx={{
          gridRowStart: 1,
          gridColumnStart: 1,
          height: '125px',
          width: '150px',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Box
          id={title}
          sx={{
            pt: 0,
            px: 0.5,
            pr: 0.5,
            mb: 0,
            width: '150px',
            height: '192px',
            display: 'inline-block',
            transform: 'translateY(-8px)',
          }}
        />
      </Box>
      <Box
        sx={{
          height: '125px',
          width: '150px',
          gridRowStart: 1,
          gridColumnStart: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '18px',
            color: 'greys.silver',
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '20px',
            color: 'greys.black',
          }}
        >
          {value}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {change && percentageChange && percentageChange > 0 && (
            <Typography
              component="div"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700' }}
            >
              <Box
                sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'success.main' }}
              >
                <StatUp fill={theme.palette.success.main} />
                <Box>{change}</Box>
                <Box>({percentageChange}%)</Box>
              </Box>
            </Typography>
          )}
          {change && !percentageChange && change > 0 && (
            <Typography
              component="div"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700' }}
            >
              <Box
                sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'success.main' }}
              >
                <StatUp fill={theme.palette.success.main} />
                <Box>{change}</Box>
              </Box>
            </Typography>
          )}
          {change && percentageChange && percentageChange < 0 && (
            <Typography
              component="div"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700' }}
            >
              <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'error.main' }}>
                <StatDown fill={theme.palette.error.main} />
                <Box>{change}</Box>
                <Box>({percentageChange}%)</Box>
              </Box>
            </Typography>
          )}
          {change && !percentageChange && change < 0 && (
            <Typography
              component="div"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700' }}
            >
              <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'error.main' }}>
                <StatDown fill={theme.palette.error.main} />
                <Box>{change}</Box>
              </Box>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

HealthDonut.propTypes = {
  title: PropTypes.string.isRequired,
  good: PropTypes.number.isRequired,
  bad: PropTypes.number.isRequired,
  ugly: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default HealthDonut;
