export const truncate = (text, length) => {
  if (text) {
    return text.length > length ? `${text.substr(0, length - 1)}...` : text;
  }
  return '';
};

export const formatStringForCSV = (text) => {
  let result;
  if (text) {
      result = text.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm,' ');
      result = result.replace(/"/g, '""');
      result = result.replace(/ +(?= )/g,'');
  } else {
      result = '';
  }
  return result;
}

export const foundKeywords = (text, keywords) => {
  const tokens = keywords?.toLowerCase().split(' ');
  if (text) {
    let tokensFound = 0;
    for (let i = 0; i < tokens?.length; i += 1) {
      const token = tokens[i];
      if (text.toLowerCase().includes(token)) {
        tokensFound += 1;
      }
    }
    return tokensFound === (tokens ? tokens?.length : 0);
  }
  return true;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

export const validEmailAddress = (email) => {
  if (!email || (email && email.length === 0)) {
    return true;
  }
  // eslint-disable-next-line max-len, no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
}

