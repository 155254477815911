import React, { useState } from 'react';
import { formatDate } from 'utils/dates';
import {
  TableRow,
  TableCell,
  IconButton,
  useTheme,
  Menu,
  MenuItem,
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';
import { ConfirmationDialog, CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';

const IPNumbersRow = ({ loading, number, selectedType, editIPNumber, removeIPNumbers }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [description, setDescription] = useState(number.notes);
  const [IPNumber, setIPNumber] = useState(number.number);
  const [formErrors, setFormErrors] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetForm = () => {
    setDescription(number.notes);
    setIPNumber(number.number);
  };

  const handleMenuClose = () => {
    resetForm();
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = () => {
    editIPNumber(number.id, description, IPNumber, selectedType);
    handleMenuClose();
    handleClose();
  };

  const validateForm = () => {
    const errors = [];
    if (description === '') {
      errors.push({ description: 'A description is needed' });
    }
    if (IPNumber === '') {
      errors.push({ IPNumber: 'An IP Number is needed' });
    }
    if (selectedType === '') {
      errors.push({ selectedType: 'Please choose an IP Type' });
    }

    if (errors.length === 0) {
      onSubmit();
    } else {
      setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    }
  };

  const onConfirm = () => {
    const ids = [];
    ids.push(number.id);
    removeIPNumbers(ids, selectedType);
    setConfirmDelete(false);
    handleClose();
  };

  return (
    <>
      <TableRow key={number.number}>
        <TableCell data-cy="number_description">{number.notes}</TableCell>
        <TableCell data-cy="number_number">{number.number}</TableCell>
        <TableCell data-cy="number_date">{formatDate(number.updatedDate)}</TableCell>
        <TableCell>
          <IconButton data-cy="number_menu" size="small" onClick={handleMenu}>
            <MoreHorizIcon fill={theme.palette.greys.silver} />
          </IconButton>
          {Boolean(anchorEl) && (
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => setOpen(true)} data-cy="edit_number">
                <EditIcon fill={theme.palette.primary.main} />
                Edit Number
              </MenuItem>
              <Box
                sx={{
                  width: '100%',
                  m: 0,
                  p: 0,
                  borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                }}
              />
              <MenuItem
                onClick={() => {
                  setConfirmDelete(true);
                  handleClose();
                }}
                data-cy="delete_number"
              >
                <DeleteIcon fill={theme.palette.chips.red.color} />
                <Typography sx={{ color: theme.palette.chips.red.color, mr: '5px' }}>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          )}
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={confirmDelete}
        title="Delete IP Number"
        message={
          <>
            <Typography>Are you sure you wish to delete this {selectedType} number?</Typography>
            <Typography>
              {number.notes} - {number.number}
            </Typography>
          </>
        }
        busy={loading}
        okTitle="Delete"
        destructive
        onClose={(confirmed) => {
          if (confirmed) {
            onConfirm();
          } else {
            setConfirmDelete(false);
          }
        }}
      />
      <CustomDialog
        onClose={handleMenuClose}
        open={open}
        title={'Edit IP'}
        maxWidth="sm"
        content={
          <CustomDialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'space-between',
                alignItems: 'left',
              }}
            >
              <TextField
                required
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                value={description}
                label="Description"
                data-cy="description_field"
                error={Boolean(formErrors.description)}
                helperText={formErrors.description}
              />
              <TextField
                required
                onChange={(e) => setIPNumber(e.target.value)}
                value={IPNumber}
                placeholder="IP Number"
                label="IP Number"
                error={Boolean(formErrors.IPNumber)}
                helperText={formErrors.IPNumber}
                data-cy="ip_number_field"
              />
              <Box>
                <FormControl error={Boolean(formErrors.selectedType)}>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: '600', mb: '8px', lineHeight: '16px' }}
                  >
                    IP Type *
                  </Typography>
                  <Select sx={{ minWidth: '15vw', maxWidth: '15vw' }} value={selectedType} disabled>
                    <MenuItem value={'copyright'}>Copyright</MenuItem>
                    <MenuItem value={'patent'}>Patent</MenuItem>
                    <MenuItem value={'trademark'}>Trademark</MenuItem>
                  </Select>
                  {formErrors.type && <FormHelperText error>{formErrors.type}</FormHelperText>}
                </FormControl>
              </Box>
            </Box>
          </CustomDialogContent>
        }
        actions={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              <Button variant="outlined" onClick={handleMenuClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                disabled={loading || (description === number.notes && IPNumber === number.number)}
                onClick={validateForm}
                data-cy="edit_ip_save"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        }
      />
    </>
  );
};
export default IPNumbersRow;
