import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { Content, Top, Header, TabPanel } from 'components';
import EnforcementQueue from './EnforcementQueue';
import EnforcementsList from './EnforcementsList';
import EnforcementHistory from './EnforcementHistory';
import SellerCompliance from './SellerCompliance/SellerCompliance';

const Enforcements = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <Content transparent>
      <Top
        sx={
          tab === 2 || tab === 3
            ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
            : null
        }
      >
        <Header title="Enforcements" showCredits>
          <Tabs color="primary" value={tab} onChange={(_event, value) => handleTabChange(value)}>
            <Tab label="Seller Compliance" data-cy="enforcement_seller_compliance_tab" />
            <Tab label="Enforcement List" data-cy="enforcement_list_tab" />
            <Tab label="Enforcement Queue" data-cy="enforcement_queue_tab" />
            <Tab label="Enforcement History" data-cy="enforcement_history_tab" />
          </Tabs>
        </Header>
      </Top>
      <Box sx={{ overflowY: 'scroll' }}>
        <TabPanel value={tab} index={0}>
          <SellerCompliance />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <EnforcementsList />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <EnforcementQueue />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <EnforcementHistory />
        </TabPanel>
      </Box>
    </Content>
  );
};

export default Enforcements;
