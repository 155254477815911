import { secureGraphQLFetch } from './fetch-service';

const sendFeedback = async ({ comment, url }) => {
  const query =
    `mutation {` +
    `sendFeedback(comment: "${encodeURIComponent(comment)}", url: "${encodeURIComponent(url)}")` +
    `}`;
  return secureGraphQLFetch({ query }).then((result) => result?.sendFeedback);
};

export default {
  sendFeedback,
};
