import React, { useState, lazy, Suspense, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthDataContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Top, Scrollable, Header } from 'components';
import { Tab, Box } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';

const Addresses = lazy(() => import('pages/Settings/Addresses/Addresses'));
const Emails = lazy(() => import('pages/Settings/Emails/Emails'));
const Templates = lazy(() => import('pages/Settings/Letters/Templates'));
const ScanRules = lazy(() => import('pages/Settings/ScanRules/ScanRules'));
const Notifications = lazy(() => import('pages/Settings/Notifications/Notifications'));
const Payments = lazy(() => import('pages/Settings/Payments/Payments'));
const PaymentHistory = lazy(() => import('pages/Settings/PaymentHistory/PaymentHistory'));
const Amazon = lazy(() => import('pages/Settings/Amazon/Amazon'));

const Settings = ({ routeTab }) => {
  const routeTabMapping = {
    notifications: '1',
    rules: '2',
    letters: '3',
    addresses: '4',
    emails: '5',
    plan: '6',
    payments: '7',
    paymentHistory: '8',
    amazon: '9',
  };
  const [tab, setTab] = useState(routeTabMapping[routeTab] ?? routeTabMapping.notifications);
  const history = useHistory();
  const location = useLocation();
  const { hasPermission, hasFeatureEnabled, account } = useContext(AuthDataContext);

  const handleTabChange = (newValue) => {
    setTab(newValue);
    const mappings = Object.entries(routeTabMapping);
    for (let i = 0; i < mappings.length; i += 1) {
      const [key, value] = mappings[i];
      if (value === newValue) {
        const newPath = `/settings/${key}`;
        if (newPath !== location.pathname) {
          history.push(newPath);
        }
      }
    }
  };

  useEffect(() => {
    const routePart = location.pathname.split('/').pop();
    const mapTab = routeTabMapping[routePart];
    if (mapTab !== undefined && mapTab !== tab) {
      handleTabChange(routeTabMapping[routePart]);
    }
  }, [location]);

  if (!account) {
    return (
      <Box />
    )
  }

  return (
    <Content>
      <TabContext value={tab}>
        <Top>
          <Header title="Settings">
            <TabList color="primary" onChange={(_event, value) => handleTabChange(value)}>
              <Tab label="Email Alerts" value={routeTabMapping.notifications} data-cy="notifications_tab" />
              {hasPermission('accountManagement') && <Tab label="Rules" value={routeTabMapping.rules} data-cy="scan_rules_tab" />}
              {hasPermission('accountManagement') && <Tab label="Letter/Email Templates" value={routeTabMapping.letters} data-cy="letters_tab" />}
              {hasPermission('accountManagement') && <Tab label="Addresses" value={routeTabMapping.addresses} data-cy="addresses_tab" />}
              {hasPermission('accountManagement') && account?.permittedDomains?.length > 0 && <Tab label="Email Addresses" value={routeTabMapping.emails} data-cy="emails_tab" />}
              {hasPermission('accountManagement') && <Tab label="Payments" value={routeTabMapping.payments} data-cy="payments_tab" />}
              {hasPermission('accountManagement') && <Tab label="Payment History" value={routeTabMapping.paymentHistory} data-cy="payment_history_tab" />}
              {hasPermission('accountManagement') && hasFeatureEnabled('sellingPartner') && <Tab label="Amazon" value={routeTabMapping.amazon} data-cy="amazon_tab" />}
            </TabList>
          </Header>
        </Top>
        <Scrollable>
          <Suspense fallback={<div />}>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.notifications}>
              <Notifications />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.rules}>
              <ScanRules />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.letters}>
              <Templates />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.addresses}>
              <Addresses />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.emails}>
              <Emails />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.payments}>
              <Payments />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.paymentHistory}>
              <PaymentHistory />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.amazon}>
              <Amazon />
            </TabPanel>
          </Suspense>
        </Scrollable>
      </TabContext>
    </Content>
  );
};

Settings.propTypes = {
  routeTab: PropTypes.string,
};

Settings.defaultProps = {
  routeTab: 'notifications',
};

export default Settings;
