import React, { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import GasGauge from './GasGauge';

const SellerHealthIndexWidget = ({ asinCount }) => {
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(null);

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      setLoading(false);
      setScore(dataToProcess.getAccountScore)
    }
  }

  const ACCOUNT_SCORE_QUERY = gql`
    query GetAccountScore {
      getAccountScore
    }
  `;
  const [fetchData] = useLazyQuery(ACCOUNT_SCORE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  return (
    <GasGauge
      loading={loading}
      title="Seller Health Index"
      value={score}
      min={-100}
      max={100}
    />
  );
};

export default SellerHealthIndexWidget;
