import React, { useContext, useEffect, useState } from 'react';
import { ToastDataContext } from 'contexts';
import { useLazyQuery, gql } from '@apollo/client';
import GasGauge from './GasGauge';

const CustomerRiskWidget = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(null);

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      setLoading(false);
      setScore(dataToProcess.getCustomerRisk)
    }
  }

  const CUSTOMER_RISK_QUERY = gql`
    query GetCustomerRisk {
      getCustomerRisk
    }
  `;
  const [fetchData] = useLazyQuery(CUSTOMER_RISK_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  return (
    <GasGauge
      loading={loading}
      title="Customer Risk"
      value={score}
      min={0}
      max={40}
      reverse
      percentage
    />
  );
};

export default CustomerRiskWidget;
