import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { TextField, Typography, Box, useTheme } from '@mui/material';
import { LoadingButton } from 'components';

function ForgotPass({ onReturn }) {
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  // validate form values
  const validateForm = ({ validateEmail }) => {
    const errors = [];
    if (!validateEmail) {
      errors.push({ forgotPassEmail: 'Email required' });
    } else if (!/^.+@.+\..+$/.test(validateEmail)) {
      errors.push({ forgotPassEmail: 'Invalid email' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm({ validateEmail: email });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        // request password reset
        const clientMetaData = { baseUrl: process.env.REACT_APP_WEB_URL };
        await Auth.forgotPassword(email.toLowerCase(), clientMetaData);
        setSuccess(true);
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography sx={{ ...theme.custom.login.title }}>Forgot password?</Typography>
      <Typography sx={{ ...theme.custom.login.subtitle }}>
        Reset your password using email
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '18px' }}>
          {formErrors.formError && (
            <Typography sx={{ ...theme.custom.login.error }}>{formErrors.formError}</Typography>
          )}
          <Box>
            <TextField
              disabled={loading || success}
              required
              fullWidth
              id="forgot-pass-email"
              variant="filled"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(formErrors.forgotPassEmail)}
              helperText={formErrors.forgotPassEmail}
              sx={{ mb: success ? 1 : 3 }}
            />
            {success && (
              <Typography
                sx={{
                  color: 'success.main',
                  fontFamily: theme.custom.login.fontFamily,
                  fontSize: '16px',
                  fontWeight: '400',
                  ml: 2,
                }}
              >
                New password has been sent!
              </Typography>
            )}
          </Box>
          {!success && (
            <>
              <LoadingButton
                fullWidth
                loading={loading}
                type="submit"
                sx={{ ...theme.custom.login.buttonContained }}
                data-cy="forgot_reset_password"
              >
                Reset password
              </LoadingButton>
              <LoadingButton
                fullWidth
                loading={loading}
                type="button"
                variant="outlined"
                sx={{ ...theme.custom.login.buttonOutlined }}
                onClick={onReturn}
                data-cy="forgot_cancel_password"
              >
                Cancel
              </LoadingButton>
            </>
          )}
          {success && (
            <LoadingButton
              fullWidth
              loading={loading}
              type="button"
              sx={{ ...theme.custom.login.buttonContained }}
              onClick={onReturn}
            >
              Return to login page
            </LoadingButton>
          )}
        </Box>
      </form>
    </>
  );
}

ForgotPass.propTypes = {
  onReturn: PropTypes.func.isRequired,
};

export default ForgotPass;
