import { secureGraphQLFetch } from './fetch-service';

const getSeller = ({ id }) => {
  const query = `query {
          getSeller(id: "${id}") {
            id
            coordinates
            dataSource
            harvestDate
            name
            storeLink
            logo
            rating
            totalRatings
            totalRatingsPercentage
            businessDetails
            businessAddress
            businessName
            positiveThirty
            neutralThirty
            negativeThirty
            countThirty
            positiveNinety
            neutralNinety
            negativeNinety
            countNinety
            positiveTwelveMonths
            neutralTwelveMonths
            negativeTwelveMonths
            countTwelveMonths
            positiveLifetime
            neutralLifetime
            negativeLifetime
            countLifetime
            authorized
            currentScore {
              score
              harvestDate
            }
        }
      }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getSeller);
};

const getOffer = async ({ offerId }) => {
  const query = `query {
    getOffer(offerId: "${offerId}") {
      asin
      price
      shippingPrice
      sellerId
      sellerName
      sellerLink
      shippingDays
      image
      name
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getOffer);
};

export default {
  getSeller,
  getOffer,
};
