import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Box } from '@mui/material';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { loadStripe } from '@stripe/stripe-js';
import stripeBadge from 'assets/stripe-badge.svg';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import accountService from 'services/account-service';

const AddCreditCardDialogWrapped = ({ open, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setToast } = useContext(ToastDataContext);
  const { account, setAccount } = useContext(AuthDataContext);
  const [saving, setSaving] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#1C1221',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#8E989B',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSave = () => {
    setSaving(true);
    const cardElement = elements.getElement(CardElement);
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then((paymentMethod) => {
        if (account.stripeCustomerId && account.stripeCustomerId !== '') {
          accountService
            .addCreditCard({
              stripeCustomerId: account.stripeCustomerId,
              stripeToken: paymentMethod.paymentMethod.id,
            })
            .then(async () => {
              onClose();
            })
            .catch((error) => {
              console.log(error);
              setToast({ type: 'error', message: error.message });
              setSaving(false);
            });
        } else {
          accountService
            .createStripeCustomer({
              stripeToken: paymentMethod.paymentMethod.id,
            })
            .then(async (result) => {
              const accountCopy = { ...account };
              accountCopy.stripeCustomerId = result.createStripeCustomer;
              setAccount({ ...accountCopy });
              try {
                // This is kind of a hack to update the JWT to include the new Stripe customer ID that has been added
                // to the Cognito account, as this gets sent down in the JWT and is used by the back-end for Stripe calls
                const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
                const currentSession = await Auth.currentSession();
                cognitoUser.refreshSession(currentSession.refreshToken, () => {
                  onClose();
                });
              } catch (e) {
                console.log('Unable to refresh Token', e);
              }
            })
            .catch((error) => {
              console.log(error);
              setToast({ type: 'error', message: error.message });
              setSaving(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setToast({ type: 'error', message: error.message });
        setSaving(false);
      });
  };

  return (
    <CustomDialog
      title="Add Credit Card"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      content={
        <CustomDialogContent>
          <Box
            data-cy="card-element-wrapper"
            sx={{
              border: 1,
              borderColor: 'greys.lightGrey',
              borderStyle: 'solid',
              borderRadius: (theme) => theme.borderRadius,
              p: 3,
            }}
          >
            <CardElement
              data-cy="add_cc_input"
              options={CARD_ELEMENT_OPTIONS}
              fonts={[
                {
                  cssSrc:
                    'url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap)',
                  family: 'Mulish',
                },
              ]}
            />
          </Box>
        </CustomDialogContent>
      }
      actions={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'bottom',
          }}
        >
          <Box component="img" alt="Powered By Stripe" src={stripeBadge} sx={{ width: '150px' }} />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button disabled={saving} variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton loading={saving} onClick={handleSave} data-cy="credit_card_save">
              Save
            </LoadingButton>
          </Box>
        </Box>
      }
    />
  );
};

const AddCreditCardDialog = ({ open, onClose }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <AddCreditCardDialogWrapped open={open} onClose={onClose} />
    </Elements>
  );
};

export default AddCreditCardDialog;
