import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Grid, useTheme } from '@mui/material';
import { LoadingButton, LinkButton, ChipContainer } from 'components';
import CustomFieldDisplay from 'pages/Common/CustomFieldDisplay';
import CustomFieldForm from 'pages/Common/CustomFieldForm';
import { formatDate } from 'utils/dates';
import { useApolloClient } from '@apollo/client';

const NotesDetailItem = ({ title, value, xs = 6, show = true }) => {
  if (value === null || !show) {
    return null;
  }
  return (
    <Grid item xs={xs}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ fontWeight: 600, fontSize: '13px' }}>{title}</Box>
        <Box sx={{ fontSize: '12px' }}>{value}</Box>
      </Box>
    </Grid>
  )
}


const Notes = ({ seller }) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const client = useApolloClient();

  const handleEdit = () => {
    setEditMode(true);
    setEditLoading(true);
  }

  const handleSaved = (values) => {
    let found = false;
    let tags = [];
    for (let i = 0; i < values.length; i += 1) {
      const value = values[i];
      if (value.idString === 'sellerProfileTags' || value.idString === 'sellerProfileSharedTags') {
        found = true;
        tags = tags.concat(value.valueStrings);
      }
    }
    if (found) {
      client.cache.modify({
        id: `SellerList:${seller.id}`,
        fields: {
          tags() {
            return tags;
          },
        },
      });
    } else {
      client.cache.modify({
        id: `SellerList:${seller.id}`,
        fields: {
          tags() {
            return [];
          },
        },
      });
    }
    setEditMode(false);
  }

  return (
    <Box sx={{ overflow: 'scroll' }}>
      <Box sx={{ display: 'flex-inline', flexDirection: 'column' }}>
        <Box sx={{ mt: 1, fontFamily: theme.typography.fontFamily, fontSize: '14px', color: 'greys.black' }}>
          {editMode && (
            <CustomFieldForm
              formId="edit-fields-seller-profile"
              areas={['sellerProfile']}
              attachedIdString={seller.id}
              onBusy={(v) => setSaving(v)}
              onSaved={(values) => handleSaved(values)}
              onLoaded={() => setEditLoading(false)}
              data-cy="note_textfield"
              onError={(v) => setDisableSave(v)}
            />
          )}
          {!editMode && (
            <Box>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <NotesDetailItem
                  title="Categories"
                  value={
                    <ChipContainer
                      backgroundColor={theme.palette.chips.silver.backgroundColor}
                      color={theme.palette.chips.silver.color}
                      labels={seller?.categories?.map(c => c.name)}
                      sx={{ fontSize: '13px' }}
                    />
                  }
                />
                <NotesDetailItem
                  title="System Tags"
                  value={
                    <ChipContainer
                      backgroundColor={theme.palette.chips.silver.backgroundColor}
                      color={theme.palette.chips.silver.color}
                      labels={seller?.systemTags}
                      sx={{ fontSize: '13px' }}
                    />
                  }
                />
                <NotesDetailItem
                  title="First Detected"
                  value={formatDate(seller?.firstDetected)}
                />
                <NotesDetailItem
                  title="Actions Taken"
                  value={seller?.actionsTaken ?? 0}
                />
              </Grid>
              <CustomFieldDisplay
                sx={{ mt: 1 }}
                attachedIdString={seller.id}
                areas={['sellerProfile']}
              />
            </Box>
          )}
          {!editMode && (
            <LinkButton
              hyperStyle
              sx={{ mb: '3px', mt: 2 }}
              onClick={handleEdit}
              data-cy="edit_notes"
            >
              Edit
            </LinkButton>
          )}
          {editMode && !editLoading && (
            <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
              <LoadingButton form="edit-fields-seller-profile" loading={saving} disabled={disableSave} variant="contained" data-cy="save_notes_button">
                Save
              </LoadingButton>
              <Button disabled={saving} variant="outlined" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Notes.propTypes = {
  seller: PropTypes.object.isRequired,
};

export default Notes;
