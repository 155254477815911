import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PasswordCheck = ({ title, checked }) => (
  <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '24px', minHeight: '24px' }}>
      {checked && (
        <CheckCircleRoundedIcon sx={{ color: 'success.main' }} data-cy="password_valid" />
      )}
      {!checked && <CancelRoundedIcon sx={{ color: 'error.main' }} data-cy="password_error" />}
    </Box>
    <Typography>{title}</Typography>
  </Box>
);

function PasswordComplexityCheck({ password }) {
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    setHasEightChars(password.length >= 8);
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
  }, [password]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <PasswordCheck title="At least 8 characters" checked={hasEightChars} />
      <PasswordCheck title="At least one symbol" checked={hasSpecialChar} />
      <PasswordCheck title="At least one uppercase letter" checked={hasUppercase} />
      <PasswordCheck title="At least one lowercase letter" checked={hasLowercase} />
      <PasswordCheck title="At least one number" checked={hasNumber} />
    </Box>
  );
}

export default PasswordComplexityCheck;
