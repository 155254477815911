import React from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as StatUp } from 'assets/stat-up.svg';
import { ReactComponent as StatDown } from 'assets/stat-down.svg';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Loading from 'components/Loading/Loading';

function DataHighlight({ icon, title, value, change, percentageChange, datacy, loading = false, tip = null }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2.5,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          width: '37px',
          height: '37px',
          bgcolor: 'greys.white',
          borderRadius: '4px',
          boxShadow: '2px 4px 8px rgba(66, 62, 86, 0.12)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Box>
        <Box>
          <Box
            sx={{ fontSize: '14px', fontWeight: '400', color: 'greys.silver', lineHeight: '20px', fontFamily: theme.typography.fontFamily }}
          >
            <Tooltip title={tip}>
              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Box>{title}</Box>
                {tip && (
                  <InfoIcon sx={{ ml: '4px', width: '13px', height: '13px' }} />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {loading && <Box sx={{ display: 'flex' }}><Loading small sx={{ opacity: 0.65 }} /></Box>}
        {!loading && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                fontSize: '24px',
                fontWeight: '700',
                color: 'greys.black',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
              }}
              data-cy={datacy}
            >
              {value}
            </Box>
            {!!change && !!percentageChange && percentageChange >= 0 && (
              <Box
                component="div"
                sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700', fontFamily: theme.typography.fontFamily }}
              >
                <Box
                  sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'success.main' }}
                >
                  <StatUp fill={theme.palette.success.main} />
                  <Box>{change}</Box>
                  <Box>({percentageChange}%)</Box>
                </Box>
              </Box>
            )}
            {!!change && !percentageChange && change >= 0 && (
              <Box
                component="div"
                sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700', fontFamily: theme.typography.fontFamily }}
              >
                <Box
                  sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'success.main' }}
                >
                  <StatUp fill={theme.palette.success.main} />
                  <Box>{change}</Box>
                </Box>
              </Box>
            )}
            {!!change && percentageChange && percentageChange < 0 && (
              <Box
                component="div"
                sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700', fontFamily: theme.typography.fontFamily }}
              >
                <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'error.main' }}>
                  <StatDown fill={theme.palette.error.main} />
                  <Box>{change}</Box>
                  <Box>({percentageChange}%)</Box>
                </Box>
              </Box>
            )}
            {!!change && !percentageChange && change < 0 && (
              <Box
                component="div"
                sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '700', fontFamily: theme.typography.fontFamily }}
              >
                <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center', color: 'error.main' }}>
                  <StatDown fill={theme.palette.error.main} />
                  <Box>{change}</Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

DataHighlight.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  change: PropTypes.number,
  percentageChange: PropTypes.number,
};

DataHighlight.defaultProps = {
  change: undefined,
  percentageChange: undefined,
};

export default DataHighlight;
