module.exports = `
<p>
  Please read these terms and conditions carefully before using Our Service.In addition to Our Terms
  and Conditions, which was last updated on July 28, 2021, please read these Additional Terms and
  Conditions carefully before using Our Service. These Additional Terms and Conditions are to
  supplement the Terms and Conditions. Capitalized terms used but not otherwise defined herein shall
  have the meanings ascribed to such terms in the Terms and Conditions.
</p>
<h4>Test Buys</h4>
<p>
  Our Service allows users to use Our platform for purposes of reviewing and analyzing suspicious
  sellers and based on such analysis, decide whether to perform a “Test Buy.” In a Test Buy, once
  the user determines which product to purchase and from which seller, We will purchase, via Our
  automated platform, that product from that seller for the total cost (including tax and shipping)
  of the product plus a premium (the “Total Cost”). The product will ship to an address provided by
  the user performing the Test Buy. By using Our Test Buy service, the user represents and warrants
  that (i) the product purchased in the Test Buy, and from whom it is purchased from, is the intent
  of the user and not by mistake, (ii) the user is solely responsible for the Total Cost, which such
  Total Cost will be automatically charged using the billing information provided by the user, and
  (iii) the address information to which the product is to be shipped is accurate and complete.
</p>
<h4>Physical Letters</h4>
<p>
  We will harvest sellers’ business names and addresses of off Amazon, and Our users have the
  ability to send physical letters to such sellers. For example, in a Test Buy, if the product that
  was purchased is fake or defective, the user has the ability to send the seller of that product a
  certified cease &amp; desist letter. The user will be able to track such letters and view delivery
  confirmation via Our application. The user also has the option to create and send other types of
  letters via Our application. By using Our physical letters Service, the user represents and
  warrants that (i) the sending of the physical letter, and to whom it is sent, is the intent of the
  user and not by mistake, (ii) the user is solely responsible for the content of the physical
  letter, and (iii) the content of the physical letter is true, accurate, and complete.
</p>
<h3>Reporting to Amazon</h3>
<p>
  We have automated the process of reporting to Amazon through Our application, providing users the
  ability to report both intellectual property violations and seller policy violations directly to
  Amazon. Through Our Service, We will complete online forms for the user using Our database. By
  using Our Service, the user represents and warrants that all information provided to Us by the
  user is true, accurate, and complete. The user acknowledges and agrees that We may use or rely on
  third-party data or services that are not owned or controlled by Us with regard to the Services
  mentioned herein and to the other types of Services that We provide. We have no control over, and
  assume no responsibility for, the content, privacy policies, or practices of any third-party
  services. The user further acknowledges and agrees that We shall not be responsible or liable,
  directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
  with the use of or reliance on any such data or services available on or through any such
  third-party services.
</p>
<p>
  The user must defend, indemnify, and hold harmless Us and Our subsidiaries, affiliates,
  successors, assigns, licensees, directors, officers, employees, and agents against any claim
  arising in connection with any of the user’s use of the Service in violation of these Additional
  Terms and Conditions, unless caused by Our negligence or willful misconduct.
</p>
<p>
  The user acknowledges and agrees that We will not be held liable for any damages caused to users
  by third-parties, including, without limitation, damages caused as a result of hacking Our system
  or phishing.
</p>
<h3>Contact Us</h3>
<p>
  If you have any questions about these additional Terms and Conditions, You can contact us: By
  email: <a href="mailto:sales@ipsecure.com">sales@ipsecure.com</a>
</p>
`
