import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'components';
import { Typography, Box, useTheme } from '@mui/material';

function SwitchLogin({ onSwitchLogin }) {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ ...theme.custom.login.switch, mr: 1 }}>Already have an account?</Typography>
      <LinkButton onClick={onSwitchLogin}>
        <Typography sx={{ ...theme.custom.login.switchLink }}>Log in</Typography>
      </LinkButton>
    </Box>
  );
}

SwitchLogin.propTypes = {
  onSwitchLogin: PropTypes.func.isRequired,
};

export default SwitchLogin;
