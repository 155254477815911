import React, { useState, useContext } from 'react';
import { Button, TextField, Box } from '@mui/material';
import dayjs from 'dayjs';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { gql, useMutation } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const UpdateCreditVariablesDialog = ({
  name,
  open,
  accountId,
  onClose,
  type,
  creditRow,
  handleRefetch,
}) => {
  const { setToast } = useContext(ToastDataContext);
  const [creditMaxPrice, setCreditMaxPrice] = useState(creditRow.creditMaxPrice);
  const [fixedCostFee, setFixedCostFee] = useState(creditRow.fixedCostFee);
  const [fixedCostThreshold, setFixedCostThreshold] = useState(creditRow.fixedCostThreshold);
  const [fixedPercentage, setFixedPercentage] = useState(creditRow.fixedPercentage);
  const [recurringCredits, setRecurringCredits] = useState(creditRow.recurringCredits);
  const [startDate, setStartDate] = useState(dayjs(creditRow.startDate));
  const [endDate, setEndDate] = useState(dayjs(creditRow.endDate));
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  const UPDATE_ACCOUNT_CREDIT_VARIABLES = gql`
    mutation UpdateAccountVariables(
      $accountId: Int
      $creditMaxPrice: Float
      $fixedCostFee: Float
      $fixedCostThreshold: Float
      $fixedPercentage: Float
      $recurringCredits: Float
      $startDate: DateTime
      $endDate: DateTime
    ) {
      updateAccountCredits(
        accountId: $accountId
        creditMaxPrice: $creditMaxPrice
        fixedCostFee: $fixedCostFee
        fixedCostThreshold: $fixedCostThreshold
        fixedPercentage: $fixedPercentage
        recurringCredits: $recurringCredits
        startDate: $startDate
        endDate: $endDate
      )
    }
  `;

  const resetForm = () => {
    setCreditMaxPrice(creditRow.creditMaxPrice);
    setFixedCostFee(creditRow.fixedCostFee);
    setFixedCostThreshold(creditRow.fixedCostThreshold);
    setFixedPercentage(creditRow.fixedPercentage);
    setRecurringCredits(creditRow.recurringCredits);
    setStartDate(dayjs(creditRow.startDate));
    setEndDate(dayjs(creditRow.endDate));
    setFormErrors([]);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const isDisabled = () => {
    if (type === 'creditVars') {
      if (
        creditMaxPrice === creditRow.creditMaxPrice &&
        fixedCostFee === creditRow.fixedCostFee &&
        fixedCostThreshold === creditRow.fixedCostThreshold &&
        fixedPercentage === creditRow.fixedPercentage
      ) {
        return true;
      }
    } else if (
      recurringCredits === creditRow.recurringCredits &&
      startDate === dayjs(creditRow.startDate) &&
      endDate === dayjs(creditRow.endDate)
    ) {
      return true;
    }
    return false;
  };

  const [updateAccountVariables] = useMutation(UPDATE_ACCOUNT_CREDIT_VARIABLES, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: () => {
      handleRefetch();
      setLoading(false);
    },
  });

  const validateForm = () => {
    const errors = [];
    if (Number.isNaN(creditMaxPrice)) {
      errors.push({ creditMaxPrice: 'Invalid input' });
    }
    if (Number.isNaN(fixedCostFee)) {
      errors.push({ fixedCostFee: 'Invalid input' });
    }
    if (Number.isNaN(fixedCostThreshold)) {
      errors.push({ fixedCostThreshold: 'Invalid input' });
    }
    if (Number.isNaN(fixedPercentage)) {
      errors.push({ fixedPercentage: 'Invalid input' });
    }
    if (Number.isNaN(recurringCredits)) {
      errors.push({ recurringCredits: 'Invalid input' });
    }
    return errors;
  };

  const updateCreditsVar = () => {
    try {
      setLoading(true);
      const formattedMaxPrice = parseFloat(creditMaxPrice);
      const formattedFixedCostFee = parseFloat(fixedCostFee);
      const formattedFixedCostThreshold = parseFloat(fixedCostThreshold);
      const formattedFixedPercentage = parseFloat(fixedPercentage);
      updateAccountVariables({
        variables: {
          accountId,
          creditMaxPrice: formattedMaxPrice,
          fixedCostFee: formattedFixedCostFee,
          fixedCostThreshold: formattedFixedCostThreshold,
          fixedPercentage: formattedFixedPercentage,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const updateCreditsRecurring = () => {
    try {
      setLoading(true);
      const formattedCredits = parseFloat(recurringCredits);
      updateAccountVariables({
        variables: {
          accountId,
          recurringCredits: formattedCredits,
          startDate,
          endDate,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const handleUpdateCredits = () => {
    const errors = validateForm();
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      if (type === 'creditVars') {
        updateCreditsVar();
      } else {
        updateCreditsRecurring();
      }
    }
  };

  return (
    <CustomDialog
      title={`Change Credit Variables for Client: ${name}`}
      content={
        <CustomDialogContent>
          {type === 'creditVars' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                  gap: 4,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    value={creditMaxPrice}
                    error={Boolean(formErrors.creditMaxPrice)}
                    helperText={formErrors.creditMaxPrice}
                    onChange={(e) => setCreditMaxPrice(e.target.value)}
                    label="Credit Max Price"
                  />
                  <TextField
                    value={fixedCostFee}
                    error={Boolean(formErrors.fixedCostFee)}
                    helperText={formErrors.fixedCostFee}
                    onChange={(e) => setFixedCostFee(e.target.value)}
                    label="Fixed Cost Fee"
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    value={fixedCostThreshold}
                    error={Boolean(formErrors.fixedCostThreshold)}
                    helperText={formErrors.fixedCostThreshold}
                    onChange={(e) => setFixedCostThreshold(e.target.value)}
                    label="Fixed Cost Threshold"
                  />
                  <TextField
                    value={fixedPercentage}
                    error={Boolean(formErrors.fixedPercentage)}
                    helperText={formErrors.fixedPercentage}
                    onChange={(e) => setFixedPercentage(e.target.value)}
                    label="Fixed Percentage"
                  />
                </Box>
              </Box>
            </>
          )}
          {type === 'recurringVars' && (
            <>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 4 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    placeholder="Recurring Credits"
                    error={Boolean(formErrors.recurringCredits)}
                    helperText={formErrors.recurringCredits}
                    value={recurringCredits}
                    onChange={(e) => setRecurringCredits(e.target.value)}
                    label="Monthly Recurring Credits"
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 4 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: 200 }}
                      label="Recurring Start Date"
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      value={startDate}
                      slotProps={{
                        field: { clearable: true, onClear: () => setStartDate(null) },
                      }}
                    />
                    <DatePicker
                      sx={{ width: 200 }}
                      label="Recurring End Date"
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      value={endDate}
                      slotProps={{
                        field: { clearable: true, onClear: () => setEndDate(null) },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </>
          )}
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading || isDisabled()}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleUpdateCredits()}
          >
            Update Credit Variables
          </LoadingButton>
        </>
      }
      sx={{ '& .MuiDialog-paper': { flexGrow: 1, borderRadius: '8px' } }}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    />
  );
};

export default UpdateCreditVariablesDialog;
