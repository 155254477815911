import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyImage } from 'assets/empty-page.svg';
import { Box } from '@mui/material';

const EmptyState = ({ content, button }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      mt: 8,
    }}
  >
    <Box>
      <EmptyImage />
      <Box>
        <Box
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '600',
          }}
        >
          {content}
        </Box>
        {button && <Box sx={{ mt: 3 }}>{button}</Box>}
      </Box>
    </Box>
  </Box>
);

EmptyState.propTypes = {
  content: PropTypes.any,
  button: PropTypes.any,
};

EmptyState.defaultProps = {
  content: '',
  button: '',
};

export default EmptyState;
