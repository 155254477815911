import React from 'react';
import { Select, MenuItem, FormControl, Tooltip, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { truncate } from 'utils/strings';

function FilterDropdown({ label, items, icons, value, onSelect, truncateLength, truncateWidth, disableAllOption, large, sx, datacy }) {
  const titleForValue = (v) => {
    if (v === '') {
      return label;
    }
    for (let i = 0; i < items?.length; i += 1) {
      if (items[i].value === v) {
        return items[i].title;
      }
    }
    return null;
  };

  const tooltipForValue = (v) => {
    for (let i = 0; i < items?.length; i += 1) {
      if (items[i].value === v) {
        return items[i].tooltip;
      }
    }
    return null;
  };
  
  const iconForValue = (v) => {
    for (let i = 0; i < items?.length; i += 1) {
      if (items[i].value === v) {
        return items[i].icon;
      }
    }
    return null;
  };

  const fontSize = large ? '13px' : '12px';

  return (
    <Box>
      <FormControl sx={sx}>
        <Select
          value={value || ''}
          displayEmpty
          data-cy="select_dropdown"
          sx={{
            borderStyle: 'none',
            p: 0,
            '& .MuiSelect-select': {
              p: 0,
            },
          }}
          onChange={(e) => {
            if (e.target.value === '') {
              onSelect(null);
            } else {
              onSelect(e.target.value);
            }
          }}
          MenuProps={{
            sx: {
              '&& .Mui-selected': {
                backgroundColor: 'white',
              },
            },
          }}
          renderValue={(selected) => {
            const title = titleForValue(selected);
            const tooltip = tooltipForValue(selected);
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {icons && (
                  <>
                    {iconForValue(selected)}
                  </>
                )}
                {((title?.length > truncateLength && truncateLength > 0) || tooltip) && (
                  <Tooltip title={tooltip || title}>
                    <Box
                      sx={{
                        fontSize,
                        fontWeight: '600',
                        color: 'greys.black',
                        maxWidth: truncateWidth || null,
                        textOverflow: 'ellipsis',
                        overflow: 'clip'
                      }}
                    >
                      {truncateLength > 0 &&
                        (truncateLength > 0 ? truncate(title, truncateLength) : title)}
                      {truncateLength === 0 && title}
                    </Box>
                  </Tooltip>
                )}
                {(title?.length <= truncateLength || truncateLength === 0) && !tooltip && (
                  <Box
                    sx={{
                      fontSize,
                      fontWeight: '600',
                      color: 'greys.black',
                      maxWidth: truncateWidth || null,
                      textOverflow: 'ellipsis',
                      overflow: 'clip'
                    }}
                    data-cy={datacy}
                  >
                    {title}
                  </Box>
                )}
              </Box>
            );
          }}
        >
          {!disableAllOption && (
            <MenuItem value="" data-cy="filter_dropdown_all_item">
              <Box sx={{ fontSize, fontWeight: '600', color: 'greys.black' }}>All</Box>
            </MenuItem>
          )}
          {disableAllOption && (
            <MenuItem disabled value="" data-cy="filter_dropdown_all_item">
              <Box sx={{ fontSize, fontWeight: '600', color: 'greys.black' }}>{label}</Box>
            </MenuItem>
          )}
          <Box
            sx={{
              width: '100%',
              m: 0,
              p: 0,
              borderBottom: (theme) => `1px solid ${theme.palette.greys.lightGrey}`,
            }}
          />
          {items &&
            items.map((i, index) => (
              <MenuItem key={i.value} value={i.value} data-cy="filter_dropdown_menu_item">
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                  {icons && (
                    <>
                      {icons[index]}
                    </>
                  )}
                  {((i.title?.length > truncateLength && truncateLength > 0) || i.tooltip) && (
                    <Tooltip title={i.tooltip || i.title}>
                      <Box sx={{ fontSize, fontWeight: '600', color: 'greys.black', maxWidth: truncateWidth || null, textOverflow: 'ellipsis', overflow: 'clip' }}>
                        {truncateLength > 0 &&
                          (truncateLength > 0 ? truncate(i.title, truncateLength) : i.title)}
                        {truncateLength === 0 && i.title}
                      </Box>
                    </Tooltip>
                  )}
                  {(i.title?.length <= truncateLength || truncateLength === 0) && !i.tooltip && (
                    <Box sx={{ fontSize, fontWeight: '600', color: 'greys.black', maxWidth: truncateWidth || null, textOverflow: 'ellipsis', overflow: 'clip' }}>
                      {i.title}
                    </Box>
                  )}
                </Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}

FilterDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  truncateLength: PropTypes.number,
  disableAllOption: PropTypes.bool,
  large: PropTypes.bool,
};

FilterDropdown.defaultProps = {
  truncateLength: 0,
  value: undefined,
  items: undefined,
  disableAllOption: false,
  large: false,
};

export default FilterDropdown;
