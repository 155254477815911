import React, { useState, useContext, useEffect } from 'react';
import { ToastDataContext } from 'contexts';
import { useLazyQuery, gql } from '@apollo/client';
import { roundToNearest30 } from 'utils/dates';
import SmallWidget from './SmallWidget';

const OffersSellersAnalyzedWidget = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [sellersDiscovered, setSellersDiscovered] = useState(0);
  const [sellerOfferHistory, setSellerOfferHistory] = useState(null);
  const [offersDiscovered, setOffersDiscovered] = useState(0);
  const [SOChange, setSOChange] = useState(0);

  const maxDate = new Date().getTime();
  const minDate = maxDate - 30 * 24 * 60 * 60 * 1000;
  const dateStart = roundToNearest30(new Date(minDate)).toISOString();
  const dateEnd = roundToNearest30(new Date(maxDate)).toISOString();

  const setSellerOfferHistoryData = (list) => {
    if (list && list.length > 0) {
      const filteredSellerOffer = list.map((l, index) => ({
        ...l,
        value: Math.round((l.offers / l.sellers) * 100) / 100,
        date: new Date(`01/${31 - index}/2021`),
        chartDate: new Date(`01/${31 - index}/2021`),
      }));

      const chronologicalOrder = [...filteredSellerOffer].reverse();
      setSellerOfferHistory(chronologicalOrder);
      if (filteredSellerOffer.length > 1) {
        setSOChange(
          Number((filteredSellerOffer[1].value - filteredSellerOffer[0].value) * -1).toFixed(2),
        );
      }
    } else {
      setSellerOfferHistory([]);
    }
  };

  const processData = (dataToProcess) => {
    if (dataToProcess.getDashboardAnalysis?.length > 0) {
      setSellersDiscovered(dataToProcess.getDashboardAnalysis[0]?.sellers || 0);
      setOffersDiscovered(dataToProcess.getDashboardAnalysis[0]?.offers || 0);
      setSellerOfferHistoryData(dataToProcess.getDashboardAnalysis?.slice(0, 30));
      setLoading(false);
    }
  };

  const OFFERS_AND_SELLERS_QUERY = gql`
    query GetOffersAndSellersData {
      getDashboardAnalysis {
        offers
        sellers
      }
    }
  `;

  const [fetchData] = useLazyQuery(OFFERS_AND_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      dateStart,
      dateEnd,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  return (
    <SmallWidget
      loading={loading}
      data={sellerOfferHistory}
      currentScore={`${offersDiscovered}/${sellersDiscovered}`}
      change={SOChange}
      title={'Offers/Sellers Analyzed'}
    />
  );
};

export default OffersSellersAnalyzedWidget;
