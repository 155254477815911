import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Typography,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Button,
  useTheme,
  Divider,
  TextField,
} from '@mui/material';
import { Loading } from 'components';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import TestBuyCreditRow from './TestBuyCreditRow';

const TestBuyCredits = ({ account }) => {
  const [loading, setLoading] = useState(true);
  const [creditRows, setCreditRows] = useState([]);
  const [showAddCredits, setShowAddCredits] = useState(false);
  const [creditsToAdd, setCreditsToAdd] = useState(undefined);
  const { setToast } = useContext(ToastDataContext);
  const [creditBalance, setCreditBalance] = useState(null);
  const { hasPermission } = useContext(AuthDataContext);

  const theme = useTheme();

  const headCells = [
    { id: 'maxPrice', label: 'Credit Max Price', width: '12%', align: 'center' },
    { id: 'fixedCostFee', label: 'Fixed Cost Fee', width: '12%', align: 'center' },
    { id: 'fixedCostThreshold', label: 'Fixed Cost Threshold', align: 'center' },
    { id: 'fixedPercentage', label: 'Fixed Percentage', width: '12%', align: 'center' },
    { id: 'recurring', label: 'Recurring Credits', width: '10%', align: 'center' },
    { id: 'start', label: 'Start Date', width: '10%', align: 'center' },
    { id: 'end', label: 'End Date', width: '10%', align: 'center' },
    { id: 'updated', label: 'Last Updated', width: '15%', align: 'center' },
    { id: 'actions', label: '', width: '5%', align: 'center' },
  ];

  const processData = (result) => {
    if (result) {
      setCreditRows(cloneDeep(result.getAccountCredits));
      setCreditBalance(result.getAccountCredits[0].creditBalance);
    }
  };

  const GET_CREDITS = gql`
    query GetAccountCredits($accountId: Int) {
      getAccountCredits(accountId: $accountId) {
        id
        creditBalance
        updatedDate
        creditMaxPrice
        fixedCostFee
        fixedCostThreshold
        fixedPercentage
        recurringCredits
        startDate
        endDate
      }
    }
  `;

  const { data, refetch } = useQuery(GET_CREDITS, {
    fetchPolicy: 'network-only',
    skip: !account,
    variables: {
      accountId: account.id,
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const ADD_CREDITS_MUTATION = gql`
    mutation AddCredits($accountId: Int, $creditBalance: Float) {
      updateAccountCredits(accountId: $accountId, creditBalance: $creditBalance)
    }
  `;

  const [addCredits] = useMutation(ADD_CREDITS_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const onClose = () => {
    setCreditsToAdd(null);
    setShowAddCredits(false);
  };

  const updateCredits = () => {
    const formattedCredits = parseFloat(creditsToAdd);
    const newTotalCredits = creditBalance + formattedCredits;
    try {
      addCredits({
        variables: {
          accountId: account.id,
          creditBalance: newTotalCredits,
        },
        onCompleted: () => {
          refetch();
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      onClose();
    }
  };

  const handleUpdateCredits = () => {
    setShowAddCredits(true);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (data) processData(data);
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 2,
          mt: 1,
        }}
      >
        <Box>
          <Typography variant="body2">Manage test buy credits for this client</Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Typography variant="h4" sx={{ mr: 2, pl: 2 }}>
            <b>Client Credit Balance:</b>
            <SavingsRoundedIcon sx={{ mx: 1, mb: -1, color: theme.palette.greys.black }} />
            {creditBalance}
          </Typography>
        </Box>
        {showAddCredits && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <TextField
                error={creditsToAdd > 0 && creditsToAdd !== undefined && Number.isNaN(creditsToAdd)}
                placeholder="Credits to add"
                value={creditsToAdd}
                onChange={(e) => setCreditsToAdd(e.target.value)}
                sx={{ width: '130px' }}
              />
              <Button sx={{ height: '30px' }} variant="outlined" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                sx={{ height: '30px' }}
                disabled={
                  creditsToAdd === undefined || Number.isNaN(creditsToAdd) || creditsToAdd <= 0
                }
                onClick={() => updateCredits()}
              >
                Add
              </Button>
            </Box>
            <Typography variant="body2" color={theme.palette.chips.red.color}>
              {creditsToAdd > 0 && creditsToAdd !== undefined && Number.isNaN(creditsToAdd)
                ? 'Must be a valid number'
                : ''}
            </Typography>
          </Box>
        )}
        {!showAddCredits && hasPermission('tenantManagement') && (
          <Button
            sx={{ width: '200px' }}
            startIcon={<AddIcon style={{ fill: theme.palette.primary.main }} />}
            variant="outlined"
            disabled={loading}
            onClick={() => handleUpdateCredits(true)}
          >
            Add One-Time Credits
          </Button>
        )}
      </Box>
      <Box>
        {loading && <Loading />}
        {!loading && (
          <Box sx={{ overflow: 'scroll' }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                        <TableSortLabel hideSortIcon data-cy={headCell.label}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditRows.map((creditRow, index) => (
                    <TestBuyCreditRow
                      key={creditRow.id}
                      account={account}
                      index={index}
                      creditRow={creditRow}
                      onRefetch={() => refetch()}
                      showOptions={hasPermission('tenantManagement')}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TestBuyCredits;
