import React from 'react';
import MaterialUIButton from '@mui/material/Button';
import PropTypes from 'prop-types';

function LinkButton({
  sx,
  loading,
  variant,
  disabled,
  color,
  size,
  children,
  hyperStyle,
  ...rest
}) {
  return (
    <MaterialUIButton
      variant="text"
      color="primary"
      type="button"
      size={size}
      sx={{
        color: 'primary.main',
        ml: 0,
        p: 0,
        minWidth: 0,
        '&:hover': {
          transition: 'background-color .25s',
          bgcolor: 'transparent',
        },
        ...(hyperStyle && {
          textDecoration: 'underline',
          color: (theme) => theme.colors.linkBlue,
          '&:hover': {
            transition: 'background-color .25s',
            textDecoration: 'underline',
          },
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MaterialUIButton>
  );
}

LinkButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  loading: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  hyperStyle: PropTypes.bool,
};

LinkButton.defaultProps = {
  loading: false,
  variant: 'contained',
  disabled: false,
  color: 'primary',
  size: 'medium',
  type: null,
  hyperStyle: false,
  sx: null,
};

export default LinkButton;
