import React from 'react';
import BadErrorDialog from 'components/BadErrorDialog/BadErrorDialog';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    window.onerror = (msg, url, line, col, error) => {
      let extra = !col ? '' : `\ncolumn: ${col}`;
      extra += !error ? '' : `\nerror: ${error}`;
      console.error(`ERROR-BOUNDARY (onerror): ${msg}\nurl: ${url}\nline: ${line}${extra}`);
      return true;
    };

    window.onunhandledrejection = (e) => {
      console.error('ERROR-BOUNDARY (unhandled): ', e);
      this.setState({ hasError: true });
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ERROR-BOUNDARY (didCatch): ', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { history, children } = this.props;
    if (hasError) {
      return (
        <BadErrorDialog
          open={hasError}
          onClose={() => {
            this.setState({ hasError: false });
            history.push('/dashboard');
          }}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
