import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BuyBoxPercentage, ProductName } from 'components';
import { TableRow, TableCell, Box, Checkbox, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { ReactComponent as Childoffer } from 'assets/child-asin.svg';
import { SCORE_GOOD, SCORE_MARGINAL } from 'utils/constants';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { AuthDataContext, RegionDataContext } from 'contexts';
import OfferIcons from 'pages/Common/OfferIcons';
import { ReactComponent as StarIcon } from 'assets/star.svg';
import { ReactComponent as Checkmark } from 'assets/checkmark.svg';

const SellerOfferRow = ({
  seller,
  offer,
  onSelectOffer,
  compact,
  onShowWinRateAsin,
  onCheckboxClicked,
  selected,
}) => {
  const score = !offer.parent && offer.currentScore;
  const stock = offer.stockEstimation;
  const { account, hasAbility, region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();

  const getRowColor = () => {
    if (score) {
      if (score?.score < SCORE_MARGINAL) {
        return '#FCF3F5';
      }
      if (score?.score < SCORE_GOOD) {
        return '#FFF8E7';
      }
    }
    return offer.child ? theme.palette.greys.backgroundGrey : theme.palette.greys.white;
  };

  return (
    <>
      <TableRow sx={{ bgcolor: getRowColor() }}>
        <TableCell sx={{ pr: 0, pl: '12px' }}>
          <Box sx={{ display: 'flex' }}>
            {!offer.parent && (hasAbility('sendLetter') || hasAbility('sendEmail')) && (
              <Checkbox
                onClick={(event) => onCheckboxClicked(event, offer)}
                data-cy="offer_checkbox"
                checked={selected}
              />
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {score && score.score < SCORE_MARGINAL && (
                <ErrorRoundedIcon sx={{ color: theme.colors.red }} />
              )}
              {score && score.score >= SCORE_MARGINAL && score.score < SCORE_GOOD && (
                <ErrorRoundedIcon sx={{ color: theme.colors.orange }} />
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {offer.child && (
              <Box sx={{ width: '30px' }}>
                <Childoffer fill={theme.palette.greys.grey} />
              </Box>
            )}
            <Box
              sx={{
                height: '40px',
                width: '40px',
                minHeight: '40px',
                minWidth: '40px',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {offer.image && (
                <Box
                  component="img"
                  src={`${offer.image}`}
                  alt="product"
                  sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
                />
              )}
              {!offer.image && <BlankImage style={{ width: '40px', height: '40px' }} />}
            </Box>
            {offer.parent && (
              <Box
                sx={{
                  display: 'block',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: '#0071DA',
                  lineHeight: '18px',
                  maxHeight: '36px',
                  overflow: 'hidden',
                }}
              >
                {offer.name}
              </Box>
            )}
            {!offer.parent && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                {offer.harvested && (
                  <ProductName
                    asin={offer.asin}
                    productName={offer.name}
                    harvested={offer.harvested}
                    sx={{
                      display: 'block',
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#0071DA',
                      textDecoration: 'underline',
                      lineHeight: '18px',
                      maxHeight: '36px',
                      overflow: 'hidden',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      onSelectOffer(offer);
                    }}
                  />
                )}
                {!offer.harvested && `Loading ${offer.asin}...`}
                {offer.parentAsin && (
                  <Box sx={{ color: 'greys.silver', fontSize: '12px' }}>{offer.variantTitle}</Box>
                )}
              </Box>
            )}
            {!offer.authorizedSellerExists && (
              <Box sx={{ height: '24px' }}>
                <Tooltip title="ASIN has no authorized sellers at the moment">
                  <StarIcon sx={{ minWidth: '24px' }} fill="#FE4646" />
                </Tooltip>
              </Box>
            )}
            <OfferIcons offer={offer} hideAuthorized />
          </Box>
        </TableCell>
        <TableCell data-cy="sellers_varaint_count">
          {offer.isAuthorized && (
            <Tooltip title="This ASIN is authorized individually or is part of an authorized category for this seller">
              <Checkmark />
            </Tooltip>
          )}
        </TableCell>
        {!compact && (
          <>
            <TableCell align="center" data-cy="seller_offer_asin">
              {!offer.parent && offer.asin}
            </TableCell>
            <TableCell align="right">
              {!offer.parent &&
                offer.price &&
                `${region ? Intl.NumberFormat(region, {
                  style: 'currency',
                  currency: regionCurrency,
                }).format(offer.price.toFixed(2)) : 0}`}
            </TableCell>
            <TableCell align="right">
              {!offer.parent &&
                offer.mapPrice &&
                `${region ? Intl.NumberFormat(region, {
                  style: 'currency',
                  currency: regionCurrency,
                }).format(offer.mapPrice.toFixed(2)) : 0}`}
            </TableCell>
            <TableCell align="right">
              <Box sx={{ alignItems: 'right' }}>
                {!offer.parent && offer.buyBoxWinPercentage !== null && (
                  <Box
                    type="button"
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      float: 'right',
                      color: theme.colors.linkBlue,
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      onShowWinRateAsin(offer.asin);
                    }}
                  >
                    {`${offer.buyBoxWinPercentage}%`}
                  </Box>
                )}
                {!offer.parent && offer.buyBoxWinPercentage === null && <BuyBoxPercentage />}
              </Box>
            </TableCell>
            <TableCell align="right">
              {!offer.parent && account.fetchInventory && <Box>{stock}</Box>}
            </TableCell>
          </>
        )}
        <TableCell align="right">
          {!offer.parent && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '3px', justifyContent: 'flex-end' }}
            >
              <Box>{offer.currentScore.score}</Box>
            </Box>
          )}
          {offer.parent && offer.minScore && (
            <Box>
              {offer.minScore} to {offer.maxScore}
            </Box>
          )}
        </TableCell>
        <TableCell data-cy="seller_row_options">
          <Box sx={{ minWidth: '30px' }}>
            {!offer.parent && <SellerAndOfferMenu offers={[offer]} seller={seller} />}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

SellerOfferRow.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.object]).isRequired,
  seller: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSelectOffer: PropTypes.func.isRequired,
  onCheckboxClicked: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  onShowWinRateAsin: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

SellerOfferRow.defaultProps = {
  compact: false,
  selected: false,
};

export default SellerOfferRow;
