import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function BuyBoxPercentage({ percentage }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      {percentage !== null && (
        <Box>{percentage}%</Box>
      )}
      {percentage === null && (
        <Tooltip title="Rolled or suppressed buy box" arrow placement="bottom" sx={{ cursor: 'default' }}>
          <Box sx={{ display: 'flex', mr: '-12px' }}>
            <Box><CloseRoundedIcon color="error" sx={{ height: '100%' }} /></Box>
            <Box><InfoOutlinedIcon color="error" sx={{ fontSize: 12, ml: '-4px' }} /></Box>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

BuyBoxPercentage.propTypes = {
  percentage: PropTypes.number,
};

BuyBoxPercentage.defaultProps = {
  percentage: null,
};

export default BuyBoxPercentage;
