/* eslint-disable import/prefer-default-export */
import React from "react";
import { Droppable } from "react-beautiful-dnd";

export const Drop = ({ id, type, ...props }) => (
  <Droppable droppableId={id} type={type}>
    {(provided) => (
      <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
        {props.children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);
