import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Drawer, Box, useTheme, Typography } from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import OffersPanel from 'pages/AsinManager/OffersPanel';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { AuthDataContext, RegionDataContext } from 'contexts';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const OffersDrawer = ({ open, asin, onClose, setSellerProfile }) => {
  const [wide, setWide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const { region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();

  if (open !== previousOpen) {
    setWide(false);
    setIsOpen(open);
    setPreviousOpen(open);
  }

  return (
    <Drawer
      sx={[
        {
          flexShrink: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
          '& .MuiDrawer-paper': {
            backgroundColor: 'greys.white',
            overflow: 'hidden',
            borderTopLeftRadius: theme.borderRadius,
            borderBottomLeftRadius: theme.borderRadius,
          },
        },
        wide && {
          width: wideDrawerWidth,
          '& .MuiDrawer-paper': {
            width: wideDrawerWidth,
          },
        },
        !wide && {
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        },
      ]}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        {asin && (
          <>
            <Box
              sx={{
                bgcolor: 'greys.backgroundGrey',
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                maxHeight: '44px',
                minHeight: '44px',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  onClick={() => {
                    setWide(!wide);
                  }}
                  size="large"
                >
                  <ExpandWindowIcon
                    style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                    fill={theme.palette.greys.silver}
                  />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      fontSize: '14px',
                      fontWeight: '400',
                      color: 'greys.silver',
                    }}
                  >
                    <Typography>Offers</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                  size="large"
                  data-cy="offer_drawer_close_button"
                >
                  <ClearIcon fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                p: 0,
                borderRadius: theme.borderRadius,
                bgcolor: 'greys.white',
                overflow: 'hidden',
                display: 'flex',
                flexFlow: 'column',
                height: 'calc(100vh - 44px)',
              }}
            >
              <Box>
                <Box sx={{ p: '20px', display: 'flex', gap: 2 }}>
                  <Box>
                    {asin.imageUrl && (
                      <Box
                        component="img"
                        src={`${asin.imageUrl}`}
                        alt="product"
                        sx={{
                          borderRadius: '6px',
                          minWidth: '70px',
                          maxWidth: '70px',
                          maxHeight: '70px',
                        }}
                      />
                    )}
                    {!asin.imageUrl && (
                      <BlankImage
                        style={{
                          borderRadius: '6px',
                          minWidth: '70px',
                          maxWidth: '70px',
                          maxHeight: '70px',
                        }}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {asin.minPrice === asin.maxPrice && (
                      <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
                        {Intl.NumberFormat(region, {
                          style: 'currency',
                          currency: regionCurrency,
                        }).format(asin.minPrice?.toFixed(2))}
                      </Typography>
                    )}
                    {asin.minPrice !== asin.maxPrice && (
                      <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
                        {Intl.NumberFormat(region, {
                          style: 'currency',
                          currency: regionCurrency,
                        }).format(asin.minPrice?.toFixed(2))}{' '}
                        -{' '}
                        {Intl.NumberFormat(region, {
                          style: 'currency',
                          currency: regionCurrency,
                        }).format(asin.maxPrice?.toFixed(2))}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'greys.darkGrey',
                      }}
                      data-cy="asin_name"
                    >
                      {asin.name}
                    </Typography>
                    {asin.variantTitle && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '400',
                          color: 'greys.grey',
                        }}
                      >
                        {asin.variantTitle}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  bgcolor: 'greys.backgroundGrey',
                  overflow: 'hidden',
                  flex: 1,
                }}
              >
                <OffersPanel asin={asin} showOffers drawer setSellerProfile={setSellerProfile} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

OffersDrawer.propTypes = {
  asin: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setSellerProfile: PropTypes.func.isRequired,
};

OffersDrawer.defaultProps = {
  open: false,
  asin: null,
};

export default OffersDrawer;
