import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { Chip, SellerName } from 'components';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { getColor, getBackgroundColor, getActionLabel } from 'utils/enforcements';
import FollowUpIcon from '@mui/icons-material/NotificationsRounded';
import dayjs from 'dayjs';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';

const EnforcementItem = ({ enforcement, setSelectedEnforcement, actionTypes, onRefetch }) => {
  const [sellerProfileOpen, setSellerProfileOpen] = useState(false);
  const [sellerProfile, setSellerProfile] = useState(null);
  const theme = useTheme();

  const daysUntilDate = (d) => {
    const date = dayjs(d).startOf('day');
    const now = dayjs().startOf('day');
    const diff = date.diff(now, 'day')
    if (diff === 0) {
      return 'Today';
    }
    if (diff < 0) {
      return 'Overdue';
    }
    return `${diff} day${diff > 1 ? 's' : ''}`;
  }

  return (
    <Grid
      sx={{ cursor: 'pointer', justifyContent: 'center' }}
      item
      container
      xs={12}
      onClick={() => setSelectedEnforcement(enforcement)}
    >
      <Grid container item xs={3} justifyContent="center">
        {enforcement.offers[0].asin ? (
          <Grid item>
            {enforcement.offers[0].imageUrl && (
              <Box
                component="img"
                src={`${enforcement.offers[0].imageUrl}`}
                alt="product"
                sx={{
                  borderRadius: '6px',
                  minWidth: '60px',
                  maxWidth: '60px',
                  maxHeight: '60px',
                }}
              />
            )}
            {!enforcement.offers[0].imageUrl && (
              <BlankImage
                style={{
                  borderRadius: '6px',
                  minWidth: '60px',
                  maxWidth: '60px',
                  maxHeight: '60px',
                }}
              />
            )}
          </Grid>
        ) : (
          <BlankImage style={{ width: '40px', height: '40px' }} />
        )}
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={6}>
          <Box
            tabIndex={0}
            role="button"
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onKeyDown={() => {
              setSellerProfile({
                sellerId: enforcement.offers[0].sellerId,
                sellerName: enforcement.offers[0].sellerName,
              });
              setSellerProfileOpen(true);
            }}
            onClick={() => {
              setSellerProfile({
                sellerId: enforcement.offers[0].sellerId,
                sellerName: enforcement.offers[0].sellerName,
              });
              setSellerProfileOpen(true);
            }}
          >
            <SellerName
              sellerName={enforcement.offers[0].sellerName}
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
            <SellerAuthorizationState globalAuth={enforcement.offers[0].globalAuth} partialAuth={enforcement.offers[0].sellerAuthorized} />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }} data-cy="action_item_count">
          <Box
            sx={{
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            <Chip
              label={
                enforcement.actions.length > 1
                  ? enforcement.actions.length.toString()
                  : getActionLabel(actionTypes, enforcement.actions[0])
              }
              backgroundColor={
                enforcement.actions.length > 1
                  ? theme.palette.chips.red.backgroundColor
                  : getBackgroundColor(enforcement.actions[0].actionTaken)
              }
              color={
                enforcement.actions.length > 1
                  ? theme.palette.chips.red.color
                  : getColor(enforcement.actions[0].actionTaken)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'greys.silver' }} variant="body1">
              {enforcement.offers[0].asin}
            </Typography>
            {enforcement.followUpDate && (
              <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', fontWeight: '600', color: 'error.main' }}>
                <FollowUpIcon sx={{ color: 'error.main', maxHeight: '14px', maxWidth: '14px' }} />&nbsp;{daysUntilDate(enforcement.followUpDate)}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: 'greys.darkGrey', fontWeight: 'bold' }}>
            {enforcement.offers[0].name}
          </Typography>
        </Grid>
        {enforcement.offers.length > 1 && (
          <Chip
            sx={{ mt: 1 }}
            backgroundColor={theme.palette.chips.blue.backgroundColor}
            color={theme.palette.chips.blue.color}
            label={
              enforcement.offers.length > 2
                ? `${enforcement.offers.length - 1} more offers included`
                : `1 more offer included`
            }
          />
        )}
      </Grid>
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfileOpen}
        onClose={() => {
          setSellerProfileOpen(false);
          onRefetch();
        }}
      />
    </Grid>
  );
};

EnforcementItem.propTypes = {
  enforcement: PropTypes.oneOfType([PropTypes.object]).isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedEnforcement: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
};

export default EnforcementItem;
