import React, { useState } from 'react';

export const ToastDataContext = React.createContext();

export const ToastDataProvider = ({ children }) => {
  const [toast, setToast] = useState(null);
  return (
    <ToastDataContext.Provider value={{ toast, setToast }}>{children}</ToastDataContext.Provider>
  );
};
