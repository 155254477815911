/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import UpdateTenantForm from './UpdateTenantForm';

const UpdateTenant = ({ tenant, isOpen, onClose, onDone }) => {
  const [loading, setLoading] = useState(tenant);

  return (
    <CustomDialog
      title={tenant ? "Edit Tenant" : "New Tenant"}
      open={isOpen}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="sm"
      fullScreen
      scroll="paper"
      disableBackdropClick
      sx={{
        '& .MuiDialog-paper': {
          flexGrow: 1,
          height: '100%',
          minHeight: `calc(100vh - 150px)`,
          maxHeight: `calc(100vh - 150px)`,
          minWidth: `calc(100vw - 150px)`,
          maxWidth: `calc(100vw - 150px)`,
        }
      }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <UpdateTenantForm tenant={tenant} onDone={onDone} formId="update-tenant" onSaving={(val) => setLoading(val)} onLoaded={() => setLoading(false)} />
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <LoadingButton form="update-tenant" loading={loading} variant="contained" type="submit">
            {tenant && 'Save Tenant'}
            {!tenant && 'Add Tenant'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default UpdateTenant;
