import React, { useState } from 'react';
import { Button, Box, TextField } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';

const AddToQueueDialog = ({ onCancel, onAdd }) => {
  const [notes, setNotes] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(notes);
  };


  return (
    <CustomDialog
      title="Add to Queue"
      open
      onClose={() => onCancel()}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <form id="enforcement-queue-notes" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Enter any notes"
                label="Notes"
                data-cy="edit_notes"
                value={notes || ''}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button
            variant="outlined"
            onClick={() => onCancel()}
            data-cy="edit_asin_cancel"
          >
            Cancel
          </Button>
          <LoadingButton
            form="enforcement-queue-notes"
            variant="contained"
            type="submit"
            data-cy="queue_add"
          >
            Add to Queue
          </LoadingButton>
        </>
      }
    />
  );
};

export default AddToQueueDialog;
