import MaterialUIBadge from '@mui/material/Badge';
import { styled } from '@mui/material';

const Badge = styled(MaterialUIBadge)({
  '& .MuiBadge-badge': {
    fontWeight: '600',
    fontSize: '12px',
    right: '4px',
    top: '4px',
    border: 2,
    borderStyle: 'solid',
    borderColor: 'greys.white',
  },
});

export default Badge;
