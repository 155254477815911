import React, { useContext, useRef, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Main from 'components/Main/Main';
import Header from 'components/Header/Header';
import Content from 'components/Content/Content';
import Top from 'components/Content/Top';
import { Box } from '@mui/material';
import { AuthDataContext } from 'contexts/AuthDataContext/AuthDataContext';
import Loading from 'components/Loading/Loading';
import { ALL_REGIONS } from 'utils/constants';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { RegionDataContext } from 'contexts';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const { user, tenant, account, region } = useContext(AuthDataContext);
  const { regionIso } = useContext(RegionDataContext);

  const history = useHistory();

  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
  
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  const handleInterval = async () => {
    // Check for user session expiration
    try {
      await Auth.currentSession();
    } catch (err) {
      window.location.href = '/signin';
    }
  }

  useInterval(handleInterval, 60000);

  const showRegionError = () => {
    if (typeof account === 'object' && typeof tenant === 'object' && typeof user === 'object' && user?.profile) {
      const isAllRegionsSelected = (list) => {
        if (list) {
          for (let i = 0; i < list.length; i += 1) {
            if (list[i].id === ALL_REGIONS) {
              return true;
            }
          }
        }
        return false;
      }
      const hasRegionAvailableInTenant = (r) => tenant?.configuredRegions?.find(i => i.id === r.id);
      const hasRegionAvailableInAccount = (r) => account?.configuredRegions?.find(i => i.id === r.id);
      const hasRegionConfiguredInProfile = (r) => user?.profile?.configuredRegions?.find(i => i.id === r.id);

      const enabledRegions = [];
      for (let i = 0; i < tenant.allRegions.length; i += 1) {
        const regionToCheck = tenant.allRegions[i];
        const found = (hasRegionConfiguredInProfile(regionToCheck) || isAllRegionsSelected(user?.profile?.configuredRegions)) &&
          (hasRegionAvailableInAccount(regionToCheck) || 
          isAllRegionsSelected(account.configuredRegions) && (hasRegionAvailableInTenant(regionToCheck) || 
          isAllRegionsSelected(tenant.configuredRegions)));
        if (found !== false) {
          enabledRegions.push(regionToCheck);
        }
      }
      return enabledRegions.length === 0;
    }
    return false;
  }

  const getPage = (appUser) => {

    // Special case for when a user is logged in, but they don't have any regions enabled for their account
    if (showRegionError()) {
      return (
        <Main>
          <Content>
            <Top>
              <Header title=" " />
              <Box
                sx={{
                  p: 5,
                  fontStyle: 'italic',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
              >
                No regions are available for this user
              </Box>
            </Top>
          </Content>
        </Main>
      );
    }

    if (appUser === 'loading' || !region || !regionIso) {
      return <Loading />;
    }

    if (appUser?.profile) {
      return (
        <Main>
          {!render && <Component />}
          {render && render()}
        </Main>
      );
    }
    return history.push('/login');
  };
  return <AuthWrapper><Route {...rest} render={() => getPage(user)} /></AuthWrapper>;
};

export default PrivateRoute;
