import { secureGraphQLFetch } from './fetch-service';

const addressFragment = `
  id
  accountId
  addressLine1
  addressLine2
  city
  state
  zip
  country
  addressTypeId
  addressTypeName
  name
  description
  coordinates
`;

const getAddresses = () => {
  const query = `query {
    getAddresses {
      ${addressFragment}
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getAddresses);
};

const getVerifiedAddress = ({ address }) => {
  const query = `query {
    getVerifiedAddress(address: ${JSON.stringify(address)}) {
      addressLine1
      addressLine2
      city
      state
      zip
      country
      deliverable
      errors
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getVerifiedAddress);
};

const upsertAddress = (address) => {
  const unquoted = JSON.stringify(address).replace(/"([^"]+)":/g, '$1:');
  const query = `mutation { upsertAddress(address:${unquoted}) {
      ${addressFragment}
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.upsertAddress);
};

export default { getAddresses, upsertAddress, getVerifiedAddress };
