import React, { useState, useContext, useEffect } from 'react';
import { Button, Select, Box, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton, Loading } from 'components';
import CustomFieldForm from 'pages/Common/CustomFieldForm';
import { ToastDataContext } from 'contexts';
import { useQuery, useMutation, gql } from '@apollo/client';
import { capitalizeFirstLetter } from 'utils/strings';

const EditManualTestBuyDialog = ({ onClose, order }) => {
  const [innerSaving, setInnerSaving] = useState(false);
  const [innerSaved, setInnerSaved] = useState(false);
  const [outerSaving, setOuterSaving] = useState(false);
  const [outerSaved, setOuterSaved] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [status, setStatus] = useState(order.orderStatusId);
  const [amazonOrderId, setAmazonOrderId] = useState(order.amazonOrderId);
  const [orderStatusError, setOrderStatusError] = useState(order.orderStatusError)
  const [productPrice, setProductPrice] = useState(order.productPrice);
  const [shippingPrice, setShippingPrice] = useState(order. shippingPrice);
  const [tax, setTax] = useState(order.tax);
  const [tracking, setTracking] = useState(order.tracking);
  const [retailerTrackingNumber, setRetailerTrackingNumber] = useState(order.retailerTrackingNumber);
  const [formErrors, setFormErrors] = useState({});
  const { setToast } = useContext(ToastDataContext);

  const processData = (data) => {
    if (data) {
      setOrderStatuses(data.getAmazonOrderStatuses);
    }
    setLoading(false);
  }

  const GET_INFO_QUERY = gql`
    query GetManualTestBuyInfo {
      getAmazonOrderStatuses {
        id
        name
      }
    }
  `;

  useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_TESTBUY_MUTATION = gql`
    mutation UpdateTestBuy (
      $id: ID!
      $amazonOrderId: String
      $orderStatusId: Int
      $tracking: String
      $retailerTrackingNumber: String
      $productPrice: Float
      $shippingPrice: Float
      $tax: Float
      $orderStatusError: String
    ) {
      updateAmazonOrder(
        id: $id
        amazonOrderId: $amazonOrderId
        orderStatusId: $orderStatusId
        tracking: $tracking
        retailerTrackingNumber: $retailerTrackingNumber
        productPrice: $productPrice
        shippingPrice: $shippingPrice
        tax: $tax
        orderStatusError: $orderStatusError
      )
    }
  `;

  const [updateOrder] = useMutation(UPDATE_TESTBUY_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setOuterSaving(false);
    },
  });

  const validateForm = () => {
    const errors = [];
    if (productPrice && productPrice.length > 0 && Number.isNaN(parseFloat(productPrice))) {
      errors.push({ productPrice: `Invalid price` })
    }
    if (shippingPrice && shippingPrice.length > 0 && Number.isNaN(parseFloat(shippingPrice))) {
      errors.push({ shippingPrice: `Invalid shipping` })
    }
    if (tax && tax.length > 0 && Number.isNaN(parseFloat(tax))) {
      errors.push({ tax: `Invalid tax` })
    }
    if (errors.length) {
      setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
      return false;
    }
    setFormErrors({});
    return true;
  }

  const handleSubmit = async () => {
    const valid = validateForm();
    if (valid) {
      setOuterSaving(true);
      const variables = {
        id: order.id,
        orderStatusId: status,
        amazonOrderId,
        tracking,
        retailerTrackingNumber,
        orderStatusError,
        productPrice: productPrice !== null ? parseFloat(productPrice) : null,
        shippingPrice: shippingPrice !== null ? parseFloat(shippingPrice) : null,
        tax: tax !== null ? parseFloat(tax) : null,
      };
      updateOrder({
        variables,
        onCompleted: () => {
          setOuterSaving(false);
          setOuterSaved(true);
        }
      });
    } 
  }

  useEffect(() => {
    if (innerSaved && outerSaved) {
      onClose(true);
    }
  }, [innerSaved, outerSaved]);

  return (
    <CustomDialog
      title="Edit Manual Information"
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px', minHeight: '80vh' },
      }}
      content={
        <CustomDialogContent>
          {loading && <Loading />}
          {!loading && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl fullWidth>
                <InputLabel required shrink>
                  Order Status
                </InputLabel>
                <Select
                  disabled={loading}
                  value={status}
                  fullWidth
                  sx={{ minWidth: '15vw', marginTop: '0px' }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setStatus(value);
                  }}
                  data-cy="test_buy_status"
                >
                  {orderStatuses.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {capitalizeFirstLetter(s.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Order Error Information"
                  fullWidth
                  value={orderStatusError ?? ''}
                  data-cy="amazon_order_status_error"
                  onChange={(e) => setOrderStatusError(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Amazon Order ID"
                  fullWidth
                  value={amazonOrderId ?? ''}
                  data-cy="amazon_order_id"
                  onChange={(e) => setAmazonOrderId(e.target.value)}
                />
              </FormControl>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Product Price"
                    fullWidth
                    value={productPrice ?? ''}
                    data-cy="product_price"
                    onChange={(e) => setProductPrice(e.target.value)}
                    error={Boolean(formErrors.productPrice)}
                    helperText={formErrors.productPrice}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Shipping"
                    fullWidth
                    value={shippingPrice ?? ''}
                    data-cy="shipping_price"
                    onChange={(e) => setShippingPrice(e.target.value)}
                    error={Boolean(formErrors.shippingPrice)}
                    helperText={formErrors.shippingPrice}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Tax"
                    fullWidth
                    value={tax ?? ''}
                    data-cy="tax"
                    onChange={(e) => setTax(e.target.value)}
                    error={Boolean(formErrors.tax)}
                    helperText={formErrors.tax}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Tracking"
                    fullWidth
                    value={tracking ?? ''}
                    data-cy="tracking"
                    onChange={(e) => setTracking(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Retailer Tracking Number"
                    fullWidth
                    value={retailerTrackingNumber ?? ''}
                    data-cy="retailer_tracking_number"
                    onChange={(e) => setRetailerTrackingNumber(e.target.value)}
                  />
                </FormControl>
              </Box>
              <CustomFieldForm
                formId="edit-testbuy-info"
                areas={['testBuyManual']}
                attachedIdInt={order.actionId}
                onBusy={(v) => setInnerSaving(v)}
                onSaved={() => {
                  setInnerSaved(true);
                  setInnerSaving(false);
                  handleSubmit();
                }}
                onError={(v) => setDisableSave(v)}
              />
            </Box>
          )}
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={innerSaving || outerSaving} variant="outlined" onClick={() => onClose(false)} data-cy="edit_cancel">
            Cancel
          </Button>
          <LoadingButton form="edit-testbuy-info" loading={innerSaving || outerSaving} disabled={disableSave} variant="contained" type="submit" data-cy="edit_save">
            Save
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditManualTestBuyDialog;
