import { secureGraphQLFetch } from './fetch-service';

const sendPolicyViolation = (str, contact, enforcementId) => {
  let req = '{';
  for (let i = 0; i < str.length; i += 1) {
    let match = false;
    switch (str[i]?.name) {
      case 'listingContentViolationType':
      case 'specificSellerViolationType':
        req = `${req}\n\t\t"violationType": "${str[i]?.tag}",`;
        match = true;
        break;
      case 'listingContentIssueCopyright':
      case 'specificSellerIssueCopyright':
      case 'entireAsinIssue':
        req = `${req}\n\t\t"copyrightIssue": "${str[i]?.tag}",`;
        match = true;
        break;
      case 'listingContentIssuePatent':
      case 'specificSellerIssuePatent':
        req = `${req}\n\t\t"patentIssue": "${str[i]?.tag}",`;
        match = true;
        break;
      case 'listingContentIssueTrademark':
      case 'specificSellerIssueTrademark':
        req = `${req}\n\t\t"trademarkIssue": "${str[i]?.tag}",`;
        match = true;
        break;
      default:
        break;
    }
    if (!match) {
      switch (str[i]?.val) {
        case true:
          req = `${req}\n\t\t"${str[i]?.name}": true,`;
          break;
        case false:
          req = `${req}\n\t\t"${str[i]?.name}": false,`;
          break;
        default:
          if (Array.isArray(str[i]?.val)) {
            req = `${req}\n\t\t"${str[i]?.name}": ${JSON.stringify(str[i]?.val)},`;
          } else if (str[i]?.val) {
            req = `${req}\n\t\t"${str[i]?.name}": "${str[i]?.val}",`;
          } else {
            req = `${req}\n\t\t"${str[i]?.name}": "${str[i]?.tag || str[i]?.val}",`;
          }
      }
    }
  }
  req = `${req}\n\t\t"infringementScope": {\n\t\t\t"asin": "${contact.asin}",`;
  req = `${req}\n\t\t\t"sellerName": "${contact.sellerName}",`;
  req = `${req}\n\t\t\t"sellerId": "${contact.sellerId}" },`;
  req = `${req}\n\t\t"amazonContact": {\n\t\t\t"isSeller": ${contact.isSeller},`;
  const tags = Object.keys(contact);
  const vals = Object.values(contact);
  for (let i = 4; i < tags.length; i += 1) {
    if (tags[i] !== 'sellerId') {
      if (i === tags.length - 1) {
        req = `${req}\n\t\t\t"${tags[i]}": "${vals[i]}"`;
      } else {
        req = `${req}\n\t\t\t"${tags[i]}": "${vals[i]}",`;
      }
    }
  }
  let query = `${req}}\n}`;
  const escapedQuery = encodeURIComponent(query);
  // Temporary fix while we send manually send policy violation reports
  query = `mutation { sendPolicyViolation( 
    enforcementId: ${enforcementId} 
    jsonData: "${escapedQuery}"
  )}`;
  // ==================================================================

  return secureGraphQLFetch({ query }).then((result) => ({ result, escapedQuery }));
};

export default {
  sendPolicyViolation,
};
