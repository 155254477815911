import React from 'react';
import { Switch as MuiSwitch, useTheme } from '@mui/material';

const Switch = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      sx={{
        width: 38,
        height: 22,
        p: 0,
        '& .MuiSwitch-switchBase': {
          p: 0,
          m: '2px',
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              bgcolor: 'primary.main',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          bgcolor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 250,
          }),
        },
      }}
      {...rest}
    />
  );
};

export default Switch;
