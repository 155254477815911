import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Loading, S3Image, ChipContainer } from 'components';
import { useQuery, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { formatDate } from 'utils/dates';

const CustomFieldDisplay = ({ areas, attachedIdInt, attachedIdString, values, sx, include, compact = false, emptyResultsLabel, imageSx = {} }) => {
  const [loading, setLoading] = useState(!values);
  const [fieldGroups, setFieldGroups] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const theme = useTheme();

  const processValues = (theValues) => {
    if (theValues) {
      const customFields = theValues.slice().filter(v => areas.includes(v.area));
      let groups = [];
      let currentGroup = {}
      if (customFields.length > 0) {
        const currentCustomField = customFields[0];
        currentGroup = { field: currentCustomField, values: [] };
        for (let i = 0; i < customFields.length; i += 1) {
          const field = customFields[i];
          if (field.customFieldId !== currentGroup.field.customFieldId) {
            groups.push(currentGroup);
            currentGroup = { field, values: [] };
          }
          const modifiedArray = [...currentGroup.values];
          if (field.customValueId) {
            modifiedArray.push(field);
          }
          currentGroup.values = modifiedArray;
        }
      }
      groups.push(currentGroup);
      groups = groups.filter(g => g.values?.length > 0);
      if (include) {
        const newGroups = [];
        // Do it in the order of the include group, so that it determines the order of the fields to display
        for (let i = 0; i < include.length; i += 1) {
          for (let j = 0; j < groups.length; j += 1) {
            if (include[i] === groups[j].field.idString) {
              newGroups.push(groups[j]);
            }
          }
        }
        groups = newGroups;
      }
      setFieldGroups(groups);
      setLoading(false);
    }
  }

  const processData = (dataToProcess) => {
    if (dataToProcess?.getCustomFieldsAndValues) {
      processValues(dataToProcess.getCustomFieldsAndValues);
    }
  }

  const GET_FIELDS_QUERY = gql`
    query GetCustomFields($areas: [String]!, $attachedIdInt: Int, $attachedIdString: String) {
      getCustomFieldsAndValues(areas: $areas, attachedIdInt: $attachedIdInt, attachedIdString: $attachedIdString, valuesOnly: true) {
        id
        idString
        customFieldId
        customValueId
        name
        description
        type
        area
        stringOptions
        valueInt
        valueString
        valueDate
        valueStrings
        valueAddress {
          addressLine1
          addressLine2
          city
          state
          zip
          country
        }
        multiple
        timestamp
        extraTextLabel
        extraText
        userEmail
        userFirstName
        userLastName
        updatedDate
        createdDate
        s3filename
      }
    }
  `;

  useQuery(GET_FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    skip: values,
    variables: { areas, attachedIdInt, attachedIdString },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (values) {
      processValues(values);
    }
  }, [values])

  if (loading) return <Box sx={{ display: 'flex', opacity: 0.5 }}><Loading small /></Box>;

  if (!loading && fieldGroups.length === 0) return (
    <Box sx={{ fontSize: '14px', color: 'greys.grey' }}>
      {emptyResultsLabel}
    </Box>
  );

  const flexDirectionForType = (type) => {
    if ((type === 'image' || type === 'document')) {
      return 'row';
    }
    return 'column'
  }

  const flexGapForValue = (type) => {
    if (type === 'email') {
      return 0;
    }
    if (type === 'string' || type === 'trademark') {
      return 0.5
    }
    if ((type === 'image' || type === 'document') && compact) {
      return 1;
    }
    return 1.5
  }

  const gridGroupSizeForType = (field) => {
    if (field.type === 'email' || field.type === 'document' || field.type === 'trademark' || field.type === 'image' || (field.type === 'string' && field.stringOptions === null)) {
      return 12;
    }
    return 4;
  }

  return (
    <Box sx={sx}>
      {!loading && (
        <Grid container spacing={compact ? 1 : 2} data-cy="testbuy_grid">
          {fieldGroups.map(g => (
            <Grid item key={g.field.customFieldId} xs={gridGroupSizeForType(g.field)}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1, mb: (g.field.type === 'document' || g.field.type === 'image') ? '7px' : '1px' }} data-cy={`text_${g.field.customFieldId}`}>
                <Box sx={{ fontWeight: 600, fontSize: '13px' }}>{g.field.name}</Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: flexDirectionForType(g.field.type), flexWrap: 'wrap', gap: flexGapForValue(g.field.type) }}>
                {g.values.map((v) => (
                  <Box key={v.id} data-cy={`testbuy_${v.valueString}`}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: compact ? 1 : 2 }}>
                        {v.type === 'date' && (
                          <Box sx={{ fontSize: '12px' }} data-cy="custom_field_date">
                            {formatDate(new Date(v.valueDate))}
                          </Box>
                        )}
                        {(v.type === 'string' || v.type === 'trademark') && !v.stringOptions && (
                          <Box sx={{ fontSize: '12px' }}>{v.valueString}</Box>
                        )}
                        {v.type === 'string' && v.stringOptions && (
                          <Box sx={{ fontSize: '12px' }}>{v.valueString}</Box>
                        )}
                        {v.type === 'address' && v.valueAddress && (
                          <Box sx={{ fontSize: '12px' }}>
                            {v.valueAddress.addressLine1}
                            <br />
                            {v.valueAddress.addressLine2?.length > 0 && (
                              <Box>
                                <br />
                                {v.valueAddress.addressLine2}
                              </Box>
                            )}
                            {v.valueAddress.city} {v.valueAddress.state}
                            {', '}
                            {v.valueAddress.zip}
                            {', '}
                            {v.valueAddress.country}
                          </Box>
                        )}
                        {v.type === 'tags' && v.valueStrings && (
                          <ChipContainer
                            backgroundColor={theme.palette.chips.silver.backgroundColor}
                            color={theme.palette.chips.silver.color}
                            labels={v.valueStrings}
                            sx={{ fontSize: '13px' }}
                          />
                        )}
                        {v.type === 'email' && (
                          <Box sx={{ fontSize: '12px' }}>{v.valueString}</Box>
                        )}
                        {(v.type === 'image' || v.type === 'document') && (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: compact ? 0 : 1 }}>
                            <S3Image sx={{ width: '125px', ...imageSx }} filename={v.s3filename} downloadFilename={v.valueString} openInTab />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              {v.extraTextLabel && !compact && (
                                <Box sx={{ fontSize: '12px' }}>{v.extraText}</Box>
                              )}
                              <Box sx={{ fontSize: '11px', color: 'greys.silver' }}>{!compact && v.valueString}</Box>
                            </Box>
                          </Box>
                        )}
                        {v.extraTextLabel && g.field.type !== 'image' && g.field.type !== 'document' && !compact && (
                          <Box sx={{ fontSize: '12px' }}>
                            {!compact && v.extraText}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {g.field.timestamp && v.customValueId && !compact && (
                      <Box sx={{ fontSize: '11px', color: 'greys.grey', mt: '1px' }}>
                        {formatDate(new Date(v.updatedDate))}
                        {v.userEmail && (
                          ` ${v.userEmail} ${v.userFirstName ? `(${v.userFirstName} ${v.userLastName})` : ''}`
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default CustomFieldDisplay;
