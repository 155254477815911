import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import AddressEditorForm from './AddressEditorForm';

const AddressEditor = ({ isOpen, onClose, address, mailingMode, returnMode }) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setEditMode(address);
  }, []);

  const getTitle = () => {
    if (editMode) {
      if (returnMode) {
        return 'Return Address';
      }
      if (mailingMode) {
        return 'To Address';
      }
      return 'Edit Address';
    }
    return 'Add Address';
  };

  return (
    <CustomDialog
      onClose={handleClose}
      title={getTitle()}
      busy={loading}
      open={isOpen}
      maxWidth="md"
      disableBackdropClick
      content={
        <CustomDialogContent>
          <AddressEditorForm
            mailingMode={mailingMode}
            returnMode={returnMode}
            address={address}
            editMode={editMode}
            formId="address-form"
            onSaving={() => setLoading(true)}
            onDone={(result) => onClose(result)}
          />
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton disabled={loading} loading={loading} form="addresses-form" type="submit">
            {editMode ? 'Save' : 'Add'}
          </LoadingButton>
        </>
      }
    />
  );
};

AddressEditor.propTypes = {
  address: PropTypes.oneOfType([PropTypes.object]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mailingMode: PropTypes.bool,
  returnMode: PropTypes.bool,
};

AddressEditor.defaultProps = {
  mailingMode: false,
  returnMode: false,
  address: null,
};

export default AddressEditor;
