import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Box } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { useMutation, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';

const AddClient = ({ isOpen, onClose, onAdded, tenant }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const { setToast } = useContext(ToastDataContext);

  const ADD_ACCOUNT_MUTATION = gql`
    mutation AddAccount($tenantId: ID!, $name: String) {
      addAccount(tenantId: $tenantId, name: $name)
    }
  `;

  const [addAccount] = useMutation(ADD_ACCOUNT_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (companyName.length > 100) {
      errors.push({ companyName: 'Name must be under 100 characters' });
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm({
      validateName: companyName,
    });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        addAccount({
          variables: { 
            tenantId: tenant.id, 
            name: companyName,
          },
          onCompleted: () => {
            setToast({ type: 'success', message: "Client added." });
            setLoading(false);
            onAdded();
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
      } 
    }
  }

  return (
    <CustomDialog
      title="New Client"
      open={isOpen}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <form id="asin-price" onSubmit={handleSubmit}>
            {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                disabled={loading}
                required
                id="client-name"
                data-cy="client_name"
                label="Client Name"
                autoComplete="no"
                type="text"
                value={companyName || ''}
                onChange={(e) => setCompanyName(e.target.value)}
                error={Boolean(formErrors.companyName)}
                helperText={formErrors.companyName}
              />
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <LoadingButton form="asin-price" loading={loading} variant="contained" type="submit" data-cy="add_client_button">
            Add Client
          </LoadingButton>
        </>
      }
    />
  );
};

AddClient.defaultProps = {
  isOpen: false
};

AddClient.propTypes = {
  tenant: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default AddClient;
