import React, { useState, useContext } from 'react';
import {
  TextField,
  Box,
  useTheme,
  Typography,
} from '@mui/material';
import { LoadingButton, Loading } from 'components';
import { useMutation, useQuery, gql } from '@apollo/client';
import { AuthDataContext, ToastDataContext } from 'contexts';

const Tiers = ({ client }) => {
  const [formErrors, setFormErrors] = useState({});
  const [saved, setSaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const { account, updateAccount, hasFeatureEnabled } = useContext(AuthDataContext);
  const [tiers, setTiers] = useState([]);
  const theme = useTheme();

  const processData = (data) => {
    if (data?.getTiersForAccount) {
      const existingTiers = data.getTiersForAccount;
      const updatedTiers = data.getTiers
        .filter((t) => (t.tier !== 'research' && t.tier !== 'twiceDaily'))
        .filter((t) => {
          if (!hasFeatureEnabled('tiers', client) && t.tier !== 'legacy') {
            return false;
          }
          return true;
        })
        .map((t) => {
          const result = { ...t, slots: 0 };
          for (let i = 0; i < existingTiers.length; i += 1) {
            const existing = existingTiers[i];
            if (existing.id === t.id && existing.slots) {
              result.slots = existing.slots;
              if (existing.asinCount) {
                result.asinCount = existing.asinCount;
              } else {
                result.asinCount = 0;
              }
            }
          }
          return result;
        });
      setTiers(updatedTiers);
      setLoading(false);
    }
  };
  const GET_TIER_INFO_QUERY = gql`
    query GetTierInfo($accountId: Int) {
      getTiersForAccount(accountId: $accountId) {
        id
        tier
        description
        slots
        asinCount
      }
      getTiers {
        id
        tier
        description
      }
    }
  `;

  const { refetch } = useQuery(GET_TIER_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { accountId: client.id },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleResetForm = () => {
    setLoading(true);
    setSaved(false);
    setUnsavedChanges(false);
    setFormErrors([]);
    refetch();
  };

  const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient($id: ID!, $tiers: [TierInput]) {
      updateAccount(id: $id, tiers: $tiers)
    }
  `;

  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION, {
    onError: (err) => {
      setSaving(false);
      setFormErrors({ formError: err.message });
    },
  });

  // validate form values
  const validateForm = () => {
    const errors = [];
    for (let i = 0; i < tiers.length; i += 1) {
      const tier = tiers[i];
      const val = parseInt(tier.slots, 10);
      if (Number.isNaN(val) || val < 0) {
        const error = {};
        error[`${tier.tier}`] = 'Must be greater or equal to 0';
        errors.push(error);
      }
      // compare existing slots to proposed new slots
      if (tier.slots < tier.asinCount) {
        const error = {};
        error[
          `${tier.tier}`
        ] = `Tier cannot have less than ${tier.asinCount} slots. Remove asins from the tier or increase slots.`;
        errors.push(error);
      }
    }
    return errors;
  };

  const handleChangeTier = (tier, value) => {
    const updatedTiers = tiers.map((t) => {
      const result = { ...t };
      if (t.tier === tier) {
        result.slots = value;
      }
      return result;
    });
    setTiers(updatedTiers);
    setUnsavedChanges(true);
    setSaved(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate form input
    const errors = validateForm();

    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    const tiersToSave = tiers
      .map((t) => ({ id: t.tier, slots: parseInt(t.slots, 10) }));

    if (errors.length === 0) {
      setSaving(true);
      const params = {
        id: client.id,
        tiers: tiersToSave,
      };
      updateClient({
        variables: params,
        onCompleted: () => {
          if (client.id === account.id) {
            updateAccount();
          }
          setSaving(false);
          setSaved(true);
          setUnsavedChanges(false);
        },
        update(cache) {
          cache.modify({
            id: cache.identify(client),
            fields: {
              tiers() {
                return tiersToSave;
              },
            },
          });
        },
      });
      setSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleResetForm}>
      {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
      {loading && (
        <Box sx={{ display: 'flex' }}>
          <Loading />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 1,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {tiers
            .map((tier) => (
              <Box key={tier.tier} sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}>
                <TextField
                  key={tier.tier}
                  disabled={loading || saving}
                  required
                  data-cy={`client-tier-${tier.tier}`}
                  label={`${tier.description} Slots`}
                  type="number"
                  value={tier.slots}
                  sx={{ width: '200px' }}
                  onChange={(e) => {
                    handleChangeTier(tier.tier, e.target.value);
                  }}
                  error={Boolean(formErrors[tier.tier])}
                  helperText={formErrors[tier.tier]}
                />
                <Typography sx={{ ml: -2 }} fontSize="12px">
                  Asin Count: {tier.asinCount}
                </Typography>
              </Box>
            ))}
        </Box>
      )}
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LoadingButton
            disabled={loading}
            loading={saving}
            type="submit"
            variant="contained"
            color="primary"
            data-cy="tiers_update"
            sx={{ minWidth: '100px' }}
          >
            Save Tiers
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={loading || saving}
            type="reset"
            onClick={() => {
              setSaved(false);
              setUnsavedChanges(false);
            }}
          >
            Reset
          </LoadingButton>
        </Box>
        {saved && (
          <Box
            data-cy="client_profile_saved"
            sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.green }}
          >
            Client Profile Saved
          </Box>
        )}
        {unsavedChanges && (
          <Box sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.red }}>
            Unsaved Changes
          </Box>
        )}
      </Box>
    </form>
  );
};

export default Tiers;
