import { useTheme } from '@mui/material';

const getColor = (actionType) => {
  const theme = useTheme();
  //   Letter/Email - Blue
  if (actionType === 1 || actionType === 6) {
    return theme.palette.chips.blue.color;
  }
  //   IP Violation - Burnt Orange
  if (actionType === 2) {
    return theme.palette.chips.burntOrange.color;
  }
  //   Test Buy - Orange
  if (actionType === 3) {
    return theme.palette.chips.orange.color;
  }
  //   Licensing Violation - Pink
  if (actionType === 4) {
    return theme.palette.chips.pink.color;
  }
  //   Seller Policy Violation - turquoise
  if (actionType === 5) {
    return theme.palette.chips.turquoise.color;
  }
  //   Multiple Enforcements - Red
  if (actionType === 11) {
    return theme.palette.chips.red.color;
  }
  return theme.palette.chips.purple.color;
};

const getBackgroundColor = (actionType) => {
  const theme = useTheme();
  //   Letter/Email - Blue
  if (actionType === 1 || actionType === 6) {
    return theme.palette.chips.blue.backgroundColor;
  }
  if (actionType === 2) {
    return theme.palette.chips.burntOrange.backgroundColor;
  }
  //   Test Buy - Orange
  if (actionType === 3) {
    return theme.palette.chips.orange.backgroundColor;
  }
  //   Licensing Violation - Pink
  if (actionType === 4) {
    return theme.palette.chips.pink.backgroundColor;
  }
  //   Seller Policy Violation - turquoise
  if (actionType === 5) {
    return theme.palette.chips.turquoise.backgroundColor;
  }
  //   Multiple Enforcements - Red
  if (actionType === 11) {
    return theme.palette.chips.red.backgroundColor;
  }
  return theme.palette.chips.purple.backgroundColor;
};

const getActionType = (actionTypes, typeId) => {
  const type = actionTypes.filter((t) => t.id === typeId);
  return type[0];
};

const getActionDescription = (action) => {
  switch (action.actionTaken) {
    case 1:
      return `${
        (action.letterType ? action.letterType.charAt(0).toUpperCase() : '') +
        (action.letterType ? action.letterType.slice(1) : '')
      } Letter Sent`;
    case 2:
      return 'IP Violation Submitted to Amazon';
    case 3:
      return 'Test Buy Made';
    case 4:
      return 'License Violation Submitted to Amazon';
    case 5:
      return 'Seller Policy Violation Submitted to Amazon';
    case 6:
      return 'Email Sent';
    default:
      return '';
  }
};

const getTemplateDescription = (action) => {
  const templateName = action.templateName ? ` (Template: ${action.templateName})` : ''
  return templateName;
};

// Shorter description
const getActionDescriptionShort = (actionTypes, action) => {
  switch (action.actionTaken) {
    case 1:
      return `${action.letterType ? action.letterType.charAt(0).toUpperCase() : ''}${
        action.letterType ? action.letterType.slice(1) : ''
      } Letter`;
    case 3:
      return 'Test Buy';
    case 6:
      return 'Email';
    default:
      return getActionType(actionTypes, action.actionTaken).description;
  }
};

// For enforcement labels
const getActionLabel = (actionTypes, action) => {
  switch (action.actionTaken) {
    case 1:
      return 'Letter';
    case 2:
      return 'IP';
    case 3:
      return 'Test Buy';
    case 4:
      return 'License';
    case 5:
      return 'Policy';
    case 6:
      return 'Email';
    default:
      return getActionType(actionTypes, action.actionTaken).description;
  }
};

export {
  getBackgroundColor,
  getColor,
  getActionDescription,
  getActionType,
  getActionDescriptionShort,
  getActionLabel,
  getTemplateDescription,
};
