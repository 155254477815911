import React, { useState, useContext } from 'react';
import { Box, Menu, MenuItem, IconButton, Typography, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material/';
import { AuthDataContext } from 'contexts';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { createLocalStorageKey } from 'utils/misc';
import Loading from '../Loading/Loading';

const AccountDropdown = ({ sx, accounts, loginOnChoose }) => {
  const { user, account } = useContext(AuthDataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosen, setChosen] = useState(false);

  const basicAccounts = accounts || (user?.profile?.availableAccounts ?? []);
  const userAccounts = basicAccounts.sort((a,b) => {
    if (a.tenantName === b.tenantName) {
      return a.name.localeCompare(b.name);
    }
    return a.tenantName.localeCompare(b.tenantName);
  }).filter(a => {
    if (!loginOnChoose && a.accountId === account.id) {
      return false;
    }
    return true;
  });

  const multipleTenants = (() => {
    const tenants = {};
    for (let i = 0; i < userAccounts.length; i += 1) {
      tenants[userAccounts[i].tenantPath] = 1;
    }
    return Object.keys(tenants).length > 1;
  })();

  if (userAccounts.length < 1) {
    return null;
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = async (ua) => {
    if (loginOnChoose) {
      loginOnChoose(ua);
      setChosen(ua);
    } else {
      const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: ua.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
      const accountId = parseInt(localStorage.getItem(accountKey), 10);
      if (ua.accountId !== accountId) {
        localStorage.setItem(accountKey, ua.accountId);

        const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: ua.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: ua.accountId });
        const r = localStorage.getItem(regionKey);
        if (!r) {
          localStorage.setItem(regionKey, USA_REGION);
        }

        setAnchorEl(null);

        if (ua.tenantPath === 'ipsecure') {
          window.location.assign(`/dashboard`);
        } else {
          window.location.assign(`/${ua.tenantPath}/dashboard`);
        }
      }
      handleClose();
    }
  };

  return (
    <Box>
      {loginOnChoose && (
        <List sx={{ opacity: chosen ? 0.5 : 1.0, pointerEvents: chosen ? 'none' : 'auto' }}>
          {userAccounts.map((ua) => (
            <Box key={ua.id}>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ p: 0, my: '1px', borderRadius: '8px', display: 'flex', gap: 2 }}
                  onClick={() => handleMenuSelect(ua)}
                  data-cy="account_choice"
                >
                  <ListItemIcon>
                    <Box sx={{ display: 'flex', bgcolor: 'tenant.bgSizeNav', minWidth: '90px', minHeight: '42px', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', my: '2px' }}>
                      {ua.logo && (
                        <Box
                          component="img"
                          sx={{
                            maxWidth: '75px',
                            maxHeight: '28px',
                            objectFit: 'contain',
                            m: '4px'
                          }}
                          alt={ua.name}
                          src={ua.logo}
                        />
                      )}
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    sx={{ m: 0, p: 0 }}
                    primary={ua.name}
                    secondary={multipleTenants ? ua.tenantName : null}
                    primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 600 } }}
                    secondaryTypographyProps={{ sx: { fontSize: '11px' } }}
                  />
                  {ua.id === chosen.id && (
                    <Loading small />
                  )}
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
        </List>
      )}
      {!loginOnChoose && (
        <Box sx={{ bgcolor: 'tenant.bgSizeNav', display: 'flex', alignItems: 'center', ...sx }}>
          <IconButton
            sx={{
              borderRadius: 1,
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
            onClick={handleMenu}
            data-cy="account_choice_button"
          >
            <Typography sx={{ display: 'flex', fontSize: '14px', color: 'white' }}>
              Switch Account
            </Typography>
            {!anchorEl && <Box sx={{ display: 'flex', alignItems: 'center' }}><CaretDown fill="white" /></Box>}
            {anchorEl && (
              <Box sx={{ transform: 'rotate(180deg)', display: 'flex', alignItems: 'center' }}>
                <Icon>
                  <CaretDown fill="white" />
                </Icon>
              </Box>
            )}
          </IconButton>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ dense: true }}
            sx={
              {
                '& .MuiMenu-paper':
                {
                  bgcolor: 'tenant.bgSizeNav',
                  boxShadow: '0 0 0 1px #777',
                },
              }
            }
          >
            {userAccounts.map((ua) => (
              <MenuItem
                key={ua.id}
                onClick={() => handleMenuSelect(ua)}
                data-cy="user_account"
                sx={{ color: 'white', '&:hover': { color: 'tenant.bgSizeNav', bgcolor: '#fffa' } }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', minWidth: '80px', minHeight: '20px',  maxHeight: '20px', m: '2px', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', }}>
                    {ua.logo && (
                      <Box
                        component="img"
                        sx={{
                          minWidth: '80px', minHeight: '20px', maxHeight: '20px',
                          objectFit: 'contain',
                        }}
                        alt={ua.name}
                        src={ua.logo}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>{ua.name}</Box>
                    {multipleTenants && (
                      <Box sx={{ fontSize: '11px', opacity: 0.6 }}>({ua.tenantName})</Box>
                    )}
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default AccountDropdown;
