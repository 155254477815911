import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { CustomDialog, CustomDialogContent } from 'components';
import Notifications from 'pages/Settings/Notifications/Notifications';

const EditUserAlerts = ({ isOpen, onClose, accountId, user, onAlertsUpdated }) => {

  const handleSubmit = async (e) => {
    e.preventDefault();
    onClose();
  }

  return (
    <CustomDialog
      title="Edit Email Alerts"
      open={isOpen}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <form id="asin-price" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Notifications userId={user.userId} accountId={accountId} onAlertsUpdated={onAlertsUpdated} />
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button form="asin-price" variant="contained" type="submit">
            Done
          </Button>
        </>
      }
    />
  );
};

EditUserAlerts.defaultProps = {
  isOpen: false,
};

EditUserAlerts.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  accountId: PropTypes.number.isRequired,
};

export default EditUserAlerts;
