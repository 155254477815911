import gql from 'graphql-tag';
import axios from 'axios';
import apolloClient from 'services/graphql-service';

const TIMEOUT = 300000; // 5 minute timeout

const graphql = `${process.env.REACT_APP_API_URL}/graphql`;

const secureGraphQLFetch = async ({ query }) => {
  const queryString = gql`
    ${query}
  `;
  const isMutation = query.match(/mutation/i);
  try {
    if (isMutation) {
      const response = await apolloClient.mutate({ mutation: queryString });
      return response.data;
    }
    const response = await apolloClient.query({ query: queryString });
    return response.data;
  } catch (err) {
    console.error('FETCH-SERVICE ERROR: ', err);
    throw new Error(err);
  }
};

const graphQLFetch = ({ query }) =>
  axios({
    url: graphql,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data: JSON.stringify({ query }),
    timeout: TIMEOUT,
  })
    .then((r) => r.data)
    .then((data) => {
      if (data.errors?.length > 0) {
        throw new Error(data.errors[0].message);
      } else {
        return data?.data;
      }
    });

export { secureGraphQLFetch, graphQLFetch };
