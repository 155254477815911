import React, { useState, useEffect, useContext } from 'react';
import { ToastDataContext } from 'contexts';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Typography, Grid, Button, Box, useTheme } from '@mui/material';
import AddCreditCardDialog from 'components/AddCreditCard/AddCreditCardDialog';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { cloneDeep } from '@apollo/client/utilities';
import { Loading } from 'components';

const StripeCC = ({ setCardOnFile, size }) => {
  const [ccDialogOpen, setCCDialogOpen] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);

  const theme = useTheme();

  const openCCDialog = () => {
    setCCDialogOpen(true);
  };

  const GET_CREDIT_CARDS_QUERY = gql`
    query GetCreditCards {
      getCreditCards {
        cardBrand
        expirationMonth
        expirationYear
        last4
      }
    }
  `;

  const { data, refetch } = useQuery(GET_CREDIT_CARDS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setLoading(false);
    },
  });

  const processData = (cards) => {
    setCreditCards(cards);
    if (cards.length > 0) {
      setCardOnFile(cards[0]);
    } else {
      setCardOnFile(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(cloneDeep(data.getCreditCards));
    }
  }, [data]);

  const closeDialog = () => {
    refetch();
    setCCDialogOpen(false);
  };

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      sx={{ bgcolor: 'greys.white', borderRadius: theme.largeBorderRadius }}
    >
      <Grid item xs={12} sx={{ mb: 1.5 }}>
        <Box display="flex">
          <Typography variant="h4">Payment Details</Typography>
        </Box>
      </Grid>
      {loading && <Loading />}
      {!loading && (
        <Grid item xs={12} container spacing={2}>
          {creditCards.length > 0 && (
            <Grid item xs={size}>
              <Box
                sx={{
                  borderRadius: theme.borderRadius,
                  border: `1px solid ${theme.palette.greys.lightGrey}`,
                  p: 2,
                  color: 'greys.grey',
                }}
              >
                {`**** **** **** ${creditCards[0].last4} (${creditCards[0].cardBrand})`}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button variant="outlined" onClick={() => openCCDialog()}>
              {creditCards.length > 0 ? (
                <Typography>Change Credit Card</Typography>
              ) : (
                <>
                  <AddRoundedIcon sx={{ color: 'primary.main' }} />
                  <Typography>Add Credit Card</Typography>
                </>
              )}
            </Button>
            {ccDialogOpen && (
              <AddCreditCardDialog
                getCreditCards={refetch()}
                open={ccDialogOpen}
                onClose={() => {
                  closeDialog();
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

StripeCC.propTypes = {
  setCardOnFile: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};

export default StripeCC;
