import React, { useEffect, useContext, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { AuthDataContext, ToastDataContext } from 'contexts';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import { useMutation, gql } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const AuthWrapper = ({ children }) => {
  const { user, setUser, setAccount } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const [active, setActive] = useState(true);
  const location = useLocation();
  const history = useHistory();

  const PING_ACTIVITY_MUTATION = gql`
    mutation PingActivity($url: String!) {
      pingActivity(url: $url)
    }
  `;

  const [pingActivity] = useMutation(PING_ACTIVITY_MUTATION, {
    onError: (e) => {
      console.error('Ping Activity Error: ', e.message);
    },
  });

  const handleInterval = async () => {
    // Track activity
    if (user?.profile?.userId) {
      if (!document.hidden && active) {
        pingActivity({ variables: { url: location.pathname } });
      }
    }
  }

  useInterval(handleInterval, 10000);

  useEffect(() => {
    window.addEventListener('blur', () => {
      setActive(false);
    });
    window.addEventListener('focus', () => {
      setActive(true);
    });
  }, []);

  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      try {
        await Auth.currentSession();
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        const token = (await Auth.currentSession()).getIdToken();
        const payload = token?.payload;
        if (payload["custom:UserID"]) {
          userService.getUser().then((profile) => {
            setUser({ profile });
            userService.updateUserLoggedIn();
            const userAccounts = profile.availableAccounts;
            if (userAccounts.length > 0) {
              const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: profile.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
              let accountId = parseInt(localStorage.getItem(accountKey), 10);
              let found = false;
              for (let i = 0; i < userAccounts.length; i += 1) {
                const userAccount = userAccounts[i];
                if (userAccount.accountId === accountId) {
                  found = true;
                }
              }
              if (!found) {
                // Pick the first account in their list, if in the corner case, that there last account is no longer active
                accountId = userAccounts[0].accountId;
              }
              const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: profile.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId });
              const r = localStorage.getItem(regionKey);
              if (!r) {
                localStorage.setItem(regionKey, USA_REGION);
              }

              accountService.getAccountById(accountId).then(async (acct) => {
                setAccount(acct);
              });
            } else {
              setToast({ type: 'error', message: 'This user does not have access to any accounts' });
            }
          }).catch(err => {
            Auth.signOut();
            console.error(`AuthWrapper: ${err}`);
            window.location.href = '/signin';
          });
        }
      } catch (err) {
        if (err.code !== 'NotAuthorizedException') {
          if (err?.toLowerCase() !== 'no current user' && err?.toLowerCase() !== 'the user is not authenticated') {
            setToast({ type: 'error', message: 'Sorry an error has occurred' });
            console.error('AuthWrapper authentication error', err);
          } else if (user?.jwtToken && err === 'No current user') {
            setToast({ type: 'error', message: 'User Session Expired' });
            history.push('/login');
          } else if (err === 'No current user') {
            history.push('/login');
          }
        }
        setUser({});
      }
    }
    return () => {
      mounted = false;
    };
  }, []);
  
  return <>{children}</>;
};

export default AuthWrapper;
