import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Drawer, Box, useTheme, Typography } from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import DetachedSellers from './DetachedSellers';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const DetachedSellersDrawer = ({ open, asin, onClose }) => {
  const [wide, setWide] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const theme = useTheme();

  if (open !== previousOpen) {
    setWide(true);
    setIsOpen(open);
    setPreviousOpen(open);
  }

  return (
    <Drawer
      sx={[
        {
          flexShrink: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
          '& .MuiDrawer-paper': {
            backgroundColor: 'greys.white',
            overflow: 'hidden',
            borderTopLeftRadius: theme.borderRadius,
            borderBottomLeftRadius: theme.borderRadius,
          },
        },
        wide && {
          width: wideDrawerWidth,
          '& .MuiDrawer-paper': {
            width: wideDrawerWidth,
          },
        },
        !wide && {
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        },
      ]}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        {asin && (
          <>
            <Box
              sx={{
                bgcolor: 'greys.backgroundGrey',
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                maxHeight: '44px',
                minHeight: '44px',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  onClick={() => {
                    setWide(!wide);
                  }}
                  size="large"
                >
                  <ExpandWindowIcon
                    style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                    fill={theme.palette.greys.silver}
                  />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      fontSize: '14px',
                      fontWeight: '400',
                      color: 'greys.silver',
                    }}
                  >
                    <Typography>Detached Sellers</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                  size="large"
                  data-cy="offer_drawer_close_button"
                >
                  <ClearIcon fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                px: 2,
                pb: 2,
                borderRadius: theme.borderRadius,
                bgcolor: 'greys.backgroundGrey',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 44px)',
              }}
            >
              <Box
                sx={{
                  bgcolor: 'greys.backgroundGrey',
                  overflow: 'scroll',
                  flex: 1,
                }}
              >
                <DetachedSellers asin={asin} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

DetachedSellersDrawer.propTypes = {
  asin: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

DetachedSellersDrawer.defaultProps = {
  asin: null,
  open: false,
};

export default DetachedSellersDrawer;
