import React from 'react';
import PropTypes from 'prop-types';

function TabPanel({ children, value, index }) {
  return <>{value === index && <>{children}</>}</>;
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
