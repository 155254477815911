import dayjs from "dayjs";

const formatDateTime = (sqlDate) => {
  const date = new Date(sqlDate);
  const timeOptions = { hour: 'numeric', minute: '2-digit' };
  const dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit'}
  return `${date.toLocaleDateString([], dateOptions)} @ ${date.toLocaleTimeString([], timeOptions)}`;
};

const formatDateTimeFilename = (sqlDate) => {
  const date = dayjs(new Date(sqlDate));
  return date.format('YYYY.MM.DD @ hh.mm a');
};

const formatDate = (sqlDate) => {
  const date = new Date(sqlDate);
  const dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit'}
  return `${date.toLocaleDateString([], dateOptions)}`;
};

const getTimeElapsed = (sqlDate) => {
  const today = new Date();
  if (formatDate(sqlDate) === formatDate(today)) {
    const fromDate = new Date(sqlDate);
    const minutesElapsed = Math.round((today - fromDate) / (1000 * 60));
    if (minutesElapsed >= 60) {
      const hoursElapsed = Math.round(minutesElapsed / 60);
      if (hoursElapsed > 1) {
        return `${hoursElapsed} hrs`;
      }
      if (hoursElapsed === 1) {
        return `${hoursElapsed} hr`;
      }
    }
    return `${minutesElapsed} min`;
  }
  if (formatDate(sqlDate) !== formatDate(today)) {
    const startDate = new Date(sqlDate);
    const daysElapsed = Math.round((today - startDate) / (1000 * 60 * 60 * 24));
    if (daysElapsed > 1) {
      return `${daysElapsed} days`;
    }
    if (daysElapsed === 1) {
      return `${daysElapsed} day`;
    }
  }
  return '';
};

const getCurrentDateTime = () => {
  const date = new Date();
  const timeOptions = { hour: 'numeric', minute: '2-digit' };
  const dateString = `${date.toLocaleDateString()}_${date.toLocaleTimeString([], timeOptions)}`;
  return dateString.replace(' ', '_');
};

const roundToNearest30 = (date = new Date()) => {
  const minutes = 30;
  const ms = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / ms) * ms);
};

export {
  formatDateTime,
  formatDate,
  getTimeElapsed,
  getCurrentDateTime,
  roundToNearest30,
  formatDateTimeFilename
};
