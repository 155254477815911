import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Select,
  styled,
  MenuItem,
  Tooltip,
  Checkbox,
  useTheme,
  Button,
} from '@mui/material';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { cloneDeep } from 'lodash';
import { Loading, LoadingButton } from 'components';

const StyledMenuItem = styled(MenuItem)({
  padding: '6px 24px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const ChangeAuthSeller = ({ seller, sellerId, enforcement, offers, onClose, onAuthorizedChanged }) => {
  const [categories, setCategories] = useState([]);
  const [globalAuth, setGlobalAuth] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSellerInAccount, setIsSellerInAccount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const [previousSellerId, setPreviousSellerId] = useState(null);
  const client = useApolloClient();

  const theme = useTheme();

  const sellerIdToUse = sellerId || (offers && offers[0].sellerId) || (enforcement && enforcement.offers[0].sellerId) || (seller && seller.id);

  const resetCategorySelections = (sellerCategories, isGlobalAuth) => {
    if ((seller && seller.globalAuth) || isGlobalAuth) {
      setSelectedCategories([0]);
    } else {
      const catArray = sellerCategories.filter((c) => c.authorized);
      setSelectedCategories(catArray.length > 0 ? catArray.map((a) => a.id) : []);
    }
  };

  const processCategories = (result, isGlobalAuth) => {
    const filteredCategories = cloneDeep(result);
    setCategories(filteredCategories);
    resetCategorySelections(filteredCategories, isGlobalAuth);
  };

  const findSellerInAccount = (sellers) => {
    const sellersInAccount = sellers.filter((a) => sellerIdToUse.includes(a.id));
    if (sellersInAccount.length <= 0) {
      setIsSellerInAccount(false);
    } else {
      setIsSellerInAccount(true);
    }
  };

  const processData = (dataToProcess) => {
    setGlobalAuth(dataToProcess.getSellerGlobalAuth.globalAuth);
    processCategories(
      dataToProcess.getSellerCategories,
      dataToProcess.getSellerGlobalAuth.globalAuth,
    );
    findSellerInAccount(dataToProcess.getSellersSimple)
    setLoading(false);
  };

  const SET_AUTH_CATEGORIES_MUTATION = gql`
    mutation SetAuthorizedCategories($sellers: [SellerTypeInput]!) {
      setAuthorizedCategories(sellers: $sellers)
    }
  `;
  const [setAuthorizedCategoriesMutation] = useMutation(SET_AUTH_CATEGORIES_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_SELLER_STATUS_AND_CATEGORIES = gql`
    query GetSellerStatusAndCategories($sellerId: String!) {
      getSellerCategories(sellerId: $sellerId) {
        id
        name
        authorized
        uniqueId
      }
      getSellerGlobalAuth(sellerId: $sellerId) {
        authorized
        globalAuth
        uniqueId
      }
      getSellersSimple {
        id
        name
      }
    }
  `;

  const { refetch } = useQuery(
    GET_SELLER_STATUS_AND_CATEGORIES,
    {
      onError: (e) => {
        setToast({ type: 'error', message: e.message });
      },
      skip: !!seller,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      variables: {
        sellerId: sellerIdToUse,
      },
      onCompleted: (e) => processData(e),
    },
    [],
  );

  const handlePreferredSellers = async () => {
    setSaving(true);
    const newCategories = categories.map((c) =>
      selectedCategories.includes(0) || selectedCategories.includes(c.id)
        ? {
          ...c,
          authorized: true,
        }
        : {
          ...c,
          authorized: false,
        },
    );
    setCategories(newCategories);
    setGlobalAuth(selectedCategories.includes(0));
    if (seller) {
      await setAuthorizedCategoriesMutation({
        variables: {
          sellers: [
            {
              categories: selectedCategories.includes(0) ? [0] : selectedCategories,
              sellerId: sellerIdToUse,
              authorized: selectedCategories.length > 0,
            },
          ],
        },
        update(cache) {
          cache.modify({
            id: cache.identify(seller),
            fields: {
              globalAuth() {
                return selectedCategories.includes(0);
              },
              authorized() {
                return selectedCategories.length > 0;
              },
              categories() {
                return newCategories;
              },
            },
          });
          cache.modify({
            id: `SellerList:${seller.id}`,
            fields: {
              globalAuth() {
                return selectedCategories.includes(0);
              },
              authorized() {
                return selectedCategories.length > 0;
              },
              categories() {
                return newCategories;
              },
            },
          });
        },
      });
    } else if (offers && !enforcement) {
      await setAuthorizedCategoriesMutation({
        variables: {
          sellers: [
            {
              categories: selectedCategories.includes(0) ? [0] : selectedCategories,
              sellerId: sellerIdToUse,
              authorized: selectedCategories.length > 0,
            },
          ],
        },
      });

      for (let i = 0; i < offers.length; i += 1) {
        client.cache.modify({
          id: client.cache.identify(offers[i]),
          fields: {
            sellerAuthorized() {
              return selectedCategories.length > 0;
            },
            globalAuth() {
              return selectedCategories.includes(0);
            },
          },
        });
      }
    } else if (enforcement) {

      await setAuthorizedCategoriesMutation({
        variables: {
          sellers: [
            {
              categories: selectedCategories.includes(0) ? [0] : selectedCategories,
              sellerId: sellerIdToUse,
              authorized: selectedCategories.length > 0,
            },
          ],
        },
      });

      for (let i = 0; i < enforcement.offers.length; i += 1) {
        const o = enforcement.offers[i];
        client.cache.modify({
          id: client.cache.identify(o),
          fields: {
            globalAuth() {
              return selectedCategories.includes(0);
            },
            sellerAuthorized() {
              return selectedCategories.length > 0;
            },
          },
        });
      }
    }

    resetCategorySelections(newCategories, selectedCategories.includes(0));
    if (onAuthorizedChanged) {
      onAuthorizedChanged();
    }
    onClose();
    setSaving(false);
  };

  useEffect(() => {
    setLoading(true);
    if (seller) {
      setCategories(seller.categories);
      resetCategorySelections(seller.categories, seller.globalAuth);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (previousSellerId !== sellerIdToUse && sellerIdToUse && previousSellerId) {
      setLoading(true);
      setPreviousSellerId(sellerIdToUse);
      refetch();
    }
  }, [sellerIdToUse]);
  

  return (
    <>
      {loading &&
        <Box sx={{ height: '92px' }}>
          <Typography
            sx={{
              bgcolor: 'greys.backgroundGrey',
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: '600',
              minWidth: '470px',
              maxMidth: '470px',
              p: '4px 25px',
              color: 'greys.silver',
            }}
          >
            Choose Authorized Categories
          </Typography>
          <Box sx={{ m: 2 }}>
            <Loading small />
          </Box>
        </Box>
      }
      {!loading && !isSellerInAccount && (
        <Box sx={{ height: '92px' }}>
          <Typography
            sx={{
              bgcolor: 'greys.backgroundGrey',
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: '600',
              minWidth: '470px',
              maxMidth: '470px',
              p: '4px 25px',
              color: 'greys.silver',
            }}
          >
            Choose Authorized Categories
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              minWidth: '470px',
              maxMidth: '470px',
              my: '20px',
              p: '0px 25px',
              color: 'greys.silver',
            }}
          >
            Seller has no offers
          </Typography>
        </Box>
      )}
      {!loading && isSellerInAccount && (
        <Box>
          <Typography
            sx={{
              bgcolor: 'greys.backgroundGrey',
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: '600',
              minWidth: '470px',
              maxMidth: '470px',
              p: '4px 25px',
              color: 'greys.silver',
            }}
          >
            Choose Authorized Categories
          </Typography>
          <StyledMenuItem>
            <Select
              disabled={saving}
              displayEmpty
              multiple
              value={selectedCategories}
              fullWidth
              sx={{ minWidth: '15vw' }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography sx={{ color: theme.palette.greys.grey }}>
                      Choose Categories...
                    </Typography>
                  );
                }
                if (selected.includes(0)) {
                  return 'All Categories';
                }
                if (selected.length === 1) {
                  return '1 Category';
                }
                return `${selectedCategories.length} Categories`;
              }}
              onChange={(e) => {
                const { value } = e.target;
                setSelectedCategories(
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value,
                );
              }}
              data-cy="sellers_choose_categories"
            >
              <MenuItem disabled>Categories...</MenuItem>
              <MenuItem key="all" value={0} sx={{ width: '20vw' }} data-cy="seller_all_categories_option">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  placement="left"
                  title="This seller will be authorized for all current categories and any new categories you may create."
                >
                  <Typography>
                    <Checkbox checked={selectedCategories.includes(0)} />
                    All
                  </Typography>
                </Tooltip>
              </MenuItem>
              {categories.map((c) => (
                <MenuItem key={c.id} value={c.id} disabled={selectedCategories.includes(0)}>
                  <Checkbox
                    checked={selectedCategories.includes(c.id) || selectedCategories.includes(0)}
                  />
                  {c.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              disabled={saving}
              sx={{ mx: 2, backgroundColor: 'greys.grey', color: 'white' }}
              onClick={() => {
                resetCategorySelections(categories, globalAuth);
                onClose();
              }}
              data-cy="sellers_authorizied_categories_reset"
            >
              Reset
            </Button>
            <LoadingButton
              loading={saving}
              onClick={() => {
                handlePreferredSellers();
              }}
              sx={{ my: 1 }}
              data-cy="sellers_authorizied_categories_ok"
            >
              OK
            </LoadingButton>
          </StyledMenuItem>
        </Box>
      )}
    </>
  );
};

export default ChangeAuthSeller;
