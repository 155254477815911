import React, { useState, useContext, useEffect } from 'react';
import { RegionDataContext } from 'contexts';
import { Box, IconButton, useTheme } from '@mui/material';
import { Loading } from 'components';
import markerSeller from 'assets/marker-seller.svg'
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { gql, useLazyQuery } from '@apollo/client';
import WidgetHeader from '../WidgetHeader';
import Widget from '../Widget';
import getCoordinateLookup from './coordinates';
import SellersMapDrawer from './SellersMapDrawer';
import MapGoogle from './MapGoogle';

const SellersMap = ({ asinCount }) => {
  const { regionIso } = useContext(RegionDataContext);
  const [loading, setLoading] = useState(true);
  const [sellerProfile, setSellerProfile] = useState(null);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const coordinateLookup = getCoordinateLookup();
  const [showBigMap, setShowBigMap] = useState(false);
  const theme = useTheme();

  const regionCoordinates = {
    lat: coordinateLookup[regionIso]?.latitude,
    lng: coordinateLookup[regionIso]?.longitude,
  };
  const regionZoom = Math.round(coordinateLookup[regionIso]?.zoom);

  const GET_MAP_DATA_QUERY = gql`
    query GetMapData {
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getAsinsForAccount(premiumTiers: true) {
        asin
        categoryId
      }
      getSellersForAccount {
        asins {
          asin
          categoryId
        }
        id
        name
        coordinates
        businessAddress
        currentScore {
          score
          harvestDate
        }
      }
      getCategoryList(premiumTiers: true) {
        id
        name
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const markersArray = [];
      const sellersForAccount = dataToProcess.getSellersForAccount;
      sellersForAccount?.forEach((seller) => {
        const coordinates = seller.coordinates?.split(',');
        if (coordinates?.length > 1) {
          const lat = parseFloat(coordinates[0]);
          const lng = parseFloat(coordinates[1]);
          if (lat && lng) {
            markersArray.push({
              type: 'good',
              icon: markerSeller,
              title: seller.businessAddress,
              name: seller?.name,
              amazonId: seller.id,
              lat,
              lng,
              onClick: () => {
                setSellerProfile({
                  sellerId: seller?.id,
                  sellerName: seller?.name,
                });
              },
            });
          }
        }
      });
      setFilteredMarkers(markersArray);
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_MAP_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  return (
    <Widget>
      <WidgetHeader
        title="Seller Map"
        right={
          <Box
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: 'greys.grey', cursor: 'pointer' }}
            onClick={() => {
              setShowBigMap(true);
            }}
          >
            <Box sx={{ fontSize: '12px' }} data-cy="expand_filter_map">Expand/Filter</Box>
            <IconButton
              size="small"
              sx={{ mr: '-10px', mt: '-5px', mb: '-5px' , ml: '-3px'}}
            >
              <ExpandWindowIcon
                style={{ transform: 'rotate(0deg)' }}
                fill={theme.palette.greys.silver}
              />
            </IconButton>
          </Box>
        }
        showLine
      />
      {loading && (
        <Box
          sx={{
            flexGrow: '1',
            overflow: 'auto',
            minHeight: '100%',
            px: 3,
            pt: 2,
            pb: 3,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '320px',
          }}
        >
          <Loading />
        </Box>
      )}
      {!loading && (
        <MapGoogle
          center={regionCoordinates}
          zoom={regionZoom}
          markers={filteredMarkers}
          height="320px"
        />
      )}
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfile !== null}
        onClose={() => setSellerProfile(null)}
      />
      <SellersMapDrawer
        open={showBigMap}
        onClose={() => setShowBigMap(false)}
      />
    </Widget>
  );
};

export default SellersMap;
